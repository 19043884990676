import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useEffect, useState } from 'react';

export const LottiePlayer = ({
  preloaderStyle,
  lottieUrl,
  isLocked,
  lockedReason,
  isLoop,
}: {
  preloaderStyle: string | null;
  lottieUrl: string;
  isLocked?: boolean;
  lockedReason?: string;
  isLoop?: boolean;
}) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
  }, [lottieUrl]);

  useEffect(() => {
    if (ready) {
      return;
    }

    fetch(lottieUrl)
      .then(() => setReady(true))
      .catch(() => setReady(true));
  }, [ready]);

  if (!ready) {
    return (
      <div
        className={[
          preloaderStyle === 'round' ? 'rounded-full' : 'rounded-xl',
          'w-full aspect-[1/1] bg-slate-700 animate-pulse',
        ].join(' ')}
      />
    );
  }

  return (
    <div className="relative rounded-xl overflow-hidden">
      <DotLottieReact
        src={lottieUrl}
        width={20}
        height={20}
        loop={isLocked ? false : isLoop}
        useFrameInterpolation={false}
        autoplay={!isLocked}
      />

      {isLocked && lottieUrl && (
        <div className="absolute top-0 w-full h-full flex flex-col justify-center items-center bg-[#0F152A]/[.9] p-1">
          <span className="nw-icon-locked text-3xl text-white"></span>
          <div className="absolute bottom-3 text-[10px] font-bold">{lockedReason}</div>
        </div>
      )}
    </div>
  );
};
