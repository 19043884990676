import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LeaderbirdBadge } from "./LeaderbirdBadge";
import { useGameStore, useUserStore } from "../../store";
import { AudioPlayer } from "../AudioPlayer/AudioPlayer";
import { LEVELS } from "../../config";
import { SquadWidget } from "../SquadWidget";
import { OverflowerText } from "../OverflowerText";
import { FightDrawer } from "../FightDrawer";

export const MainControls = ({
  isAllowedToFight,
}: {
  isAllowedToFight: boolean | 'info';
}) => {
  const { gameMode, animating } = useGameStore();
  const { user } = useUserStore();

  const levelPercentage = Math.round(
    ((user.totalExp - LEVELS[user.level]) /
      (LEVELS[user.level + 1] - LEVELS[user.level])) *
    100,
  );

  return (
    <>
      <Container className="webapp-safe-top" $fadeOut={animating}>
        <ContentFlex>
          <ProfilePictureContainer>
            <ProfilePicture src={user?.avatar ?? '/assets/images/avatar.png'} alt="avatar" />
            <ProfilePictureBadge>
              <ProfilePictureBadgeFrame />
              <span>
                {user.level}
              </span>
            </ProfilePictureBadge>
          </ProfilePictureContainer>
          <UserDetails>
            <UserLevelProgress>
              {user.totalExp.toLocaleString()} EXP <span className="text-white/[.6] -mt-1">⚬</span>
              {' '}
              {!isNaN(levelPercentage) ? `${levelPercentage}%` : 'LVL MAX'}
            </UserLevelProgress>
            <SquadWidget />
          </UserDetails>
          <ConditionalContainer $fadeOut={animating || gameMode !== "normal"}>
            <AudioPlayer />
          </ConditionalContainer>
          <ConditionalContainer $fadeOut={animating || gameMode !== "fighting"}>
            {isAllowedToFight && <FightDrawer />}
            {isAllowedToFight === 'info' && (
              <FightModeInfo>
                <span className="nw-icon-arrow" />
                {' '}
                Level up <br /> to fight
              </FightModeInfo>
            )}
        </ConditionalContainer>
        </ContentFlex>
        <ConditionalContainer $fadeOut={animating || gameMode !== "normal"}>
          <LeaderbirdLink to="/leaderbird">
            <LeaderbirdBadge />
          </LeaderbirdLink>
        </ConditionalContainer>
        <Outlet />
      </Container>
      <OverflowerTextContainer>
        <OverflowerText />
      </OverflowerTextContainer>
    </>
  );
};

const ProfilePictureBadgeFrame = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3046 2.76465C13.921 0.816768 17.5056 0.816768 20.122 2.76464L20.5112 3.05441C21.2682 3.61799 22.1262 4.0312 23.0388 4.27166L23.508 4.3953C26.6622 5.2264 28.8972 8.02895 29.0056 11.289L29.0217 11.774C29.0531 12.7172 29.265 13.6457 29.646 14.5091L29.8419 14.9531C31.1587 17.9373 30.3611 21.432 27.8798 23.5494L27.5107 23.8644C26.7928 24.477 26.199 25.2216 25.7615 26.0578L25.5366 26.4877C24.0244 29.3779 20.7948 30.9332 17.5924 30.3134L17.116 30.2212C16.1894 30.0419 15.2371 30.0419 14.3105 30.2212L13.8341 30.3134C10.6317 30.9332 7.40206 29.3779 5.88992 26.4877L5.66497 26.0578C5.22747 25.2216 4.63369 24.477 3.91579 23.8644L3.54668 23.5494C1.06543 21.432 0.267787 17.9373 1.58462 14.9531L1.78052 14.5091C2.16152 13.6457 2.37344 12.7172 2.40479 11.774L2.42092 11.289C2.5293 8.02895 4.76426 5.2264 7.91847 4.3953L8.3877 4.27166C9.3003 4.0312 10.1583 3.61799 10.9153 3.05442L11.3046 2.76465Z"
        fill="url(#paint0_linear_185_4292)"
        stroke="white"
        strokeWidth="0.824721"
      />
      <defs>
        <linearGradient
          id="paint0_linear_185_4292"
          x1="5.5183"
          y1="4.40184"
          x2="23.9278"
          y2="33.0183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7B43" />
          <stop offset="1" stopColor="#FFE04E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const ConditionalContainer = styled.div<{
  $fadeOut: boolean;
}>`
  display: contents;
  opacity: 1.0;
  transition: opacity 0.5s;

  ${({ $fadeOut }) => $fadeOut ? `
    display: none;
    opacity: 0.0;
  ` : ''}
`;

const FightModeInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffffaa;

  span {
    display: inline-flex;
    border: 1px solid #ffffff33;
    border-radius: 50%;
    padding: 0.25rem;
    color: #ffffffaa;
    transform: rotate(180deg);
    margin-right: 0.5rem;
  }
`;

const OverflowerTextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const LeaderbirdLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
  margin-top: 3rem;
`;

const UserLevelProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #ffffff;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
`;

const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
`;

const ProfilePictureBadge = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;

  & span {
    position: absolute;
    top: 0.125rem;
    width: 1.5rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: bold;
    color: #ffffff;
  }
`;

const ProfilePictureContainer = styled.div`
  position: relative;
  flex-grow: 0;
  padding: 0.125rem;
  background: linear-gradient(to bottom, #ff9a46, #f4c9a5);
  border-radius: 50%;
`;

const ContentFlex = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.75rem;
`;

const Container = styled.div<{
  $fadeOut: boolean;
}>`
  position: fixed;
  top: 1.25rem;
  left: 50%;
  transform: translateX(-50%) translateY(0px);
  width: 100dvw;
  filter: blur(0px);
  opacity: 1.0;
  transition: opacity 0.25s, transform 0.25s, filter 0.25s;
  z-index: 1;

  ${({ $fadeOut }) => $fadeOut ? `
    opacity: 0.0;
    filter: blur(8px);
    transform: translateX(-50%) translateY(-50px);
  ` : ''}
`;
