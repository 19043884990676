import toast from 'react-hot-toast';
import React, { useCallback, useEffect } from 'react';
import { useUserStore } from '../store';
import { useWebSocketContext } from '../components/WebSocketContext';

type WebSocketMessage = {
  type?: string;
  id?: number;  // <= can be number OR undefined
  name?: string;
  desc?: string;
};

export const useAchievementListener = () => {
  const achievements = useUserStore((state) => state.user.achievements);
  const setAchievements = useUserStore((state) => state.setAchievements);
  const { lastMessage } = useWebSocketContext();

  const handleUnlockEvent = useCallback(
    (data: WebSocketMessage) => {
      const { id, name, desc } = data;
      if (typeof id !== 'number') return;

      const isAlreadyUnlocked = achievements.includes(id);
      if (!isAlreadyUnlocked) {
        setAchievements([...achievements, id]);
        toast(
          () => (
            <span>
              <span className="text-xs text-white/[.6]">Achievement unlocked</span>
              <br />
              <b>{name}</b>
              <br />
              <span className="text-xs">{desc}</span>
            </span>
          ),
          {
            icon: '🏆',
            style: {
              borderRadius: '30px',
              background: '#090e1de3',
              color: '#fff',
              padding: '1rem 2rem',
            },
          }
        );
      }
    },
    [achievements, setAchievements]
  );

  useEffect(() => {
    if (lastMessage) {
      try {
        const data: WebSocketMessage = JSON.parse(lastMessage.data);
        if (data.type === 'UNLOCK') {
          handleUnlockEvent(data);
        }
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    }
  }, [handleUnlockEvent, lastMessage]);
};
