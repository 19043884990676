import * as THREE from 'three';
import { Object3D } from 'three';
import { useAnimations } from '@react-three/drei';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useUserAction } from '../../../hooks';
import { detectSwipeModelIntersection, getItemPosition, handleAnimation } from '../../../utils';
import { Coords } from './EggModel';
import {useGameStore, useUserStore} from '../../../store';
import { BatchedRenderer } from 'three.quarks';
import { ParticleSystemUtil } from '../particles/ParticleSystemUtil';
import { MODEL_URLS } from '../../../constants';
import { getModelHeight } from '../../../utils/getModelHeight';
import { useGLTF } from '../hooks/useGLTF';
import { getLayerByRenderOrder } from './composition/Layer';
import { useBindObject3DToLayer } from '../hooks/useBindObject3DToLayer';
import { tg_haptic } from "../../../utils/telegramapi";
import { AudioSystemUtil } from '../particles/AudioSystemUtil';

const TOP_MODEL_INTERSECTION_OFFSET = 0.3;
const BOTTOM_MODEL_INTERSECTION_OFFSET = -1.1;
const LEFT_MODEL_INTERSECTION_OFFSET = -0.3;
const RIGHT_MODEL_INTERSECTION_OFFSET = 0.3;

const tempVector = new THREE.Vector3();

export const DummyModel = () => {
  const { scene: mainScene, camera, size } = useThree();
  const { scene, animations } = useGLTF(MODEL_URLS.dummy);
  const dummyRef = useRef<THREE.Object3D | null>(null);
  const bang = useRef<THREE.Object3D>();
  const clawAttack = useRef<THREE.Object3D>();
  const boom = useRef<THREE.Object3D>();
  const energy = useUserStore(( state )=> state.user.energy);
  const batchedRenderer = useRef(new BatchedRenderer());
  const quarksEffect = ParticleSystemUtil.useQuarks(MODEL_URLS.notwise2_effect);
  const { actions } = useAnimations(animations, scene);
  const animating = useGameStore((state) => state.animating);
  const [intersectionStart, setIntersectionStart] = useState(false);
  const [intersectionEnd, setIntersectionEnd] = useState(false);
  const [expAnimationPosition, setExpAnimationPosition] = useState<Coords | null>(null);
  const { currentPointerCoords, setCurrentPointerCoords } = useGameStore();
  const { handleClickAction, handleSwipeAction } = useUserAction('dummy');

  const getClickPosition = useCallback(
    (event: MouseEvent) => {
      if (!dummyRef.current) return null;
      const intersections = ParticleSystemUtil.getIntersections(event.clientX, event.clientY, dummyRef.current, camera);
      if (intersections.length > 0) {
        // Make the effect appear on top of the model
        return intersections[0].point;
      }
      return null;
    },
    [camera],
  );

  const handleClick = (event: MouseEvent) => {
    if (!actions || !dummyRef.current || energy === 0) {
      return;
    }

    event.stopPropagation();

    handleClickAction(event);

    handleAnimation({
      target: dummyRef.current,
      model: 'dummy',
      action: actions['Armature|Take 001|BaseLayer'],
      animationName: Math.random() < 0.7 ? 'damage_v2' : 'damage_v4',
      isLoop: false,
      onComplete: () => {
        handleAnimation({
          target: dummyRef.current,
          model: 'dummy',
          action: actions['Armature|Take 001|BaseLayer'],
          animationName: 'T_pose',
          isLoop: true,
        });
      }
    });
    tg_haptic.impactOccurred('light');

    const clickPosition = getClickPosition(event);

    if (clickPosition) {
      clickPosition.z += 3;
      ParticleSystemUtil.prepareEffect('Bang', bang.current, clickPosition);
      ParticleSystemUtil.playEffect('Bang', mainScene, bang.current, batchedRenderer.current);

      AudioSystemUtil.play('sfx-hit-dummy', false);
    }
  };

  const startIntersectionPoint = useRef<Coords>({ x: 0, y: 0 });

  const handleSwipe = useCallback(
    (point: Coords, model: Object3D) => {
      const isIntersection = detectSwipeModelIntersection({
        model,
        point,
        camera,
        offset: {
          top: TOP_MODEL_INTERSECTION_OFFSET,
          bottom: BOTTOM_MODEL_INTERSECTION_OFFSET,
          left: LEFT_MODEL_INTERSECTION_OFFSET,
          right: RIGHT_MODEL_INTERSECTION_OFFSET,
        },
      });
      if (energy !== null && energy < 5){
        return;
      }
      if (isIntersection && !intersectionStart) {
        setIntersectionStart(true);
        startIntersectionPoint.current.x = point.x;
        startIntersectionPoint.current.y = point.y;
      }
      if (intersectionStart && !isIntersection && !intersectionEnd) {
        setIntersectionEnd(true);
      }
      if (intersectionEnd) {
        setIntersectionStart(false);
        setIntersectionEnd(false);
        setCurrentPointerCoords(null);

        if (expAnimationPosition) {
          const diffX = point.x - startIntersectionPoint.current.x;
          const diffY = point.y - startIntersectionPoint.current.y;
          const rotation = Math.atan2(-diffY, diffX);
          handleSwipeAction(mainScene, clawAttack.current, batchedRenderer.current, expAnimationPosition, rotation);

          AudioSystemUtil.play('sfx-hit-dummy', false);
        }

        handleAnimation({
          target: dummyRef.current,
          model: 'dummy',
          action: actions['Armature|Take 001|BaseLayer'],
          animationName: Math.random() < 0.5 ? 'damage_v3' : 'damage_v1',
          isLoop: false,
          onComplete: () => {
            handleAnimation({
              target: dummyRef.current,
              model: 'dummy',
              action: actions['Armature|Take 001|BaseLayer'],
              animationName: 'T_pose',
              isLoop: true,
            });
          }
        });
      }
    },
    [actions, camera, expAnimationPosition, handleSwipeAction, intersectionEnd, intersectionStart],
  );

  useBindObject3DToLayer(batchedRenderer.current, getLayerByRenderOrder(0));

  useEffect(() => {
    if (!quarksEffect) return;

    bang.current = quarksEffect.getObjectByName('Bang')!;
    clawAttack.current = quarksEffect.getObjectByName('ClawAttack')!;
    boom.current = quarksEffect.getObjectByName('Boom')!;

    ParticleSystemUtil.prepareEffect('ClawAttack', clawAttack.current, new THREE.Vector3(0, -208, 3));
    ParticleSystemUtil.prepareEffect('Boom', boom.current, new THREE.Vector3(0, -208, 0));
  }, [quarksEffect]);

  useEffect(() => {
    if (!dummyRef.current || !currentPointerCoords) {
      return;
    }

    handleSwipe(currentPointerCoords, dummyRef.current);
  }, [currentPointerCoords, handleSwipe, dummyRef.current]);

  useEffect(() => {
    if (!dummyRef.current) {
      return;
    }

    if (animating) {
      return;
    }

    const dummyHeight = getModelHeight(dummyRef.current);
    const dummyPosition = tempVector.setScalar(0.0);
    dummyRef.current.getWorldPosition(dummyPosition);

    const offsetY = dummyHeight / 2;

    const expAnimationPosition = getItemPosition({
      anchorVector: dummyPosition,
      camera,
      offsetY,
      size,
    });
    setExpAnimationPosition(expAnimationPosition);
  }, [animating, camera, size]);

  useFrame((_, delta) => {
    batchedRenderer.current.update(delta);
  });

  return <primitive ref={dummyRef} object={scene} scale={[1, 1, 1]} onClick={handleClick} />;
};
