type PerformanceClass = 'LOW' | 'AVERAGE' | 'HIGH';

export const getPlatformData = (): {
  deviceModel: string;
  androidVersion: string;
  sdkVersion: string;
  performanceClass: PerformanceClass;
} => {
  const userAgent = navigator.userAgent;

  const uaTrim = userAgent.split(/[\(\)]/g);
  uaTrim.shift();
  uaTrim.pop();
  
  const uaSplit = uaTrim.join('')
    .split(';')
    .map((item) => item.trim());

  const [
    deviceModel,
    androidVersion,
    sdkVersion,
    performanceClass,
  ] = uaSplit;

  return {
    deviceModel,
    androidVersion,
    sdkVersion,
    performanceClass: performanceClass as PerformanceClass,
  };
};
