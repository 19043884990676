import {QueryCallback, useMutation} from './useMutation';

export type RegistrationData = {
  initData: string,
  invited_by?: string
};

export const useCreateUser = ({ onSuccess, onError }: QueryCallback) => {
  const { mutate, mutateAsync, ...rest } = useMutation<RegistrationData>({
    url: 'createPlayer',
    method: 'post',
    onSuccess,
    onError,
  });

  return { createPlayer: (input: any) => mutateAsync(input)
    , ...rest };
};
