import * as THREE from 'three';
import { SceneView, Views } from '../views';
import { PropsWithChildren, useEffect, useState } from 'react';
import { imperativeLoad, imperativePrecompile } from '../hooks/useGLTF';
import { useTokenStore } from '../../../store/useTokenStore';
import {useGameStore} from "../../../store";
import { audioTracks } from '../particles/AudioSystemUtil';

THREE.Cache.enabled = true;

/**
 * NOTE Rounds percentage values to 10s, prevents unnatural values like 33%, 66% etc.
 */
const renderSimplePercentage = (value: number): string => {
  return `${Math.round(Math.trunc(value) / 10.0) * 10.0}`;
};

export const useAssetsPreloader = ({
  viewId,
}: PropsWithChildren<{
  viewId: SceneView;
}>) => {
  const progress = useGameStore((state) => state.loadingProgress);
  const setProgress = useGameStore((state) => state.setLoadingProgress);

  const [connected, setConnected] = useState(false);
  const setIsVisible = useGameStore((state) => state.setPreloaderVisible);

  const { preloadAssets } = Views[viewId];

  const verifyConnection = () => {
    const token = useTokenStore.getState();

    if (token) {
      setConnected(true);

      return;
    }

    setConnected(false);

    const verifyInterval = setInterval(() => {
      const token = useTokenStore.getState();

      if (token) {
        setConnected(true);

        clearInterval(verifyInterval);
      }
    }, 1500);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (progress === 100 && connected) {
      const delay = (progress < 100 || !connected ? 0 : 1) * 1000;

      timeout = setTimeout(() => {
        setIsVisible(false);
      }, 1000 + delay);
    }

    return () => clearTimeout(timeout);
  }, [connected, progress]);

  useEffect(() => {
    const audioTrackAssets = Object.values(audioTracks);
    const totalAssets = 
      (preloadAssets.length ?? 0.0) +
      audioTrackAssets.length;
    let currentAssets = 0;

    setProgress(0);

    Promise.all([
      ...preloadAssets.map(async (url) => {
        if (url.endsWith('.glb') || url.endsWith('.gltf')) {
          const gltf = await imperativeLoad(url);

          await imperativePrecompile(gltf.scene);

          currentAssets++;
          const newVal = renderSimplePercentage((currentAssets / totalAssets) * 100.0);
          setProgress(Number(newVal));
        } else if (url.endsWith('.json')) {
          const response = await fetch(url);

          if (response.ok) {
            const json = await response.text();

            THREE.Cache.add(url, json);
          }

          currentAssets++;
          const newVal = renderSimplePercentage((currentAssets / totalAssets) * 100.0);
          setProgress(Number(newVal));
        }
      }),
      ...audioTrackAssets.map((track) => new Promise<void>((resolve) => {
        const handleTrackResolved = () => {
          currentAssets++;
          const newVal = renderSimplePercentage((currentAssets / totalAssets) * 100.0);
          setProgress(Number(newVal));

          track.off('load', handleTrackResolved);
          track.off('loaderror', handleTrackResolved);

          resolve();
        };

        track.once('load', () => handleTrackResolved());
        track.once('loaderror', () => handleTrackResolved());
        track.load();
      })),
    ]).then(() => {
      verifyConnection();
    });
  }, [preloadAssets]);
};
