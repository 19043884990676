import toast from 'react-hot-toast';
import React, { useCallback, useEffect } from 'react';
import { useWebSocketContext } from '../components/WebSocketContext';

type WebSocketMessage = {
  error?: string;
};

export const useErrorListener = () => {
  const { lastMessage } = useWebSocketContext();

  const handleError = useCallback(
    (data: WebSocketMessage) => {
      if (data) {
        toast.error(`Error: ${data.error}`,
          {
            style: {
              borderRadius: '30px',
              background: '#090e1de3',
              color: '#fff',
              padding: '1rem 2rem',

            },
          },
        );
      }
    },
    [],
  );

  useEffect(() => {
    if (lastMessage) {
      try {
        const data: WebSocketMessage = JSON.parse(lastMessage.data);

        if (data.error) {
          handleError(data);
        }
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    }
  }, [handleError, lastMessage]);
};
