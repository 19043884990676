import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Round } from '../../utils/math';
import { tg_account, tg_haptic } from '../../utils/telegramapi';
import { LEVELS, REACT_APP_BACKEND_URL } from '../../config';
import Add from '../../assets/icon-add.svg';
import { SKILL_DESCR } from '../../constants';
import { useGameStore, useUserStore } from '../../store';
import { Inventory, MainNav, Progressbar } from '../../components';
import { IUserinfo } from '../../types/interface';
import Starfield from '../../components/Starfield';
import ACH from './achievements.json';
import AchievementsGrid from '../../components/AchievementsGrid';
import toast from 'react-hot-toast';
import { useTokenStore } from '../../store/useTokenStore';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import { ProfileProgressbar } from '../../components/ProfileProgressbar';
import coinIcon from '../../assets/coin-icon.png';
import { ProfileGear } from '../../components/ProfileGear';
import packageJson from '../../../package.json';
import {Link} from "react-router-dom";

const SKILLS: Array<{ title: string; skill: keyof IUserinfo }> = [
  { title: 'Strength', skill: 'strength' },
  { title: 'Agility', skill: 'agility' },
  { title: 'Wisdom', skill: 'wisdom' },
  { title: 'Endurance', skill: 'endurance' },
];

function ProfileComponent() {
  const userInfo = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const setGameMode = useGameStore((state) => state.setGameMode);
  const activeTab = useGameStore((state) => state.currentProfileTab);
  const setActiveTab = useGameStore((state) => state.setCurrentProfileTab);
  const { gameMode } = useGameStore();

  const [showVersionDebug, setShowVersionDebug] = useState(0);
  const [isAddLoading, setIsAddLoading] = useState(true);
  useTelegramBackButton('/');

  const token = useTokenStore((state) => state.accessToken);

  // Destructure user info for cleaner usage
  const {
    level,
    totalExp,
    rank,
    avatar,
    squad,
    coins,
    skillPoints,
    hunger,
    happiness,
    health,
    endurance,
    achievements,
    username: storeUsername,
  } = userInfo;

  const [username] = useState<string | undefined>(storeUsername || tg_account?.username);

  // Precompute the progress bar width
  const progress = useMemo(() => {
    return (
      ((LEVELS[level] - totalExp) / (LEVELS[level + 1] - LEVELS[level])) * -100
    );
  }, [level, totalExp]);

  const lottieIcons = useMemo(
    () => ({
      hunger: 'https://cdn.notwise.co/stickers/hunger.lottie',
      happiness: 'https://cdn.notwise.co/stickers/happiness.lottie',
      happinessLow: 'https://cdn.notwise.co/stickers/happiness-low.lottie',
      healthLow: 'https://cdn.notwise.co/stickers/hp-low.lottie',
      health: 'https://cdn.notwise.co/stickers/hp.lottie',
    }),
    []
  );

  const upSkill = async (skill: keyof IUserinfo) => {
    setUser({
      ...userInfo,
      skillPoints: skillPoints - 1,
      [skill]: userInfo[skill] + 1,
    });
    try {
      await axios.post(
        `${REACT_APP_BACKEND_URL}/up-skills/`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { skill },
        }
      );
      toast.success(`${skill} increased by 1`, {
        icon: '️🤟',
        style: {
          borderRadius: '30px',
          background: '#090e1de3',
          color: '#fff',
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Stats bars configuration
  const STATS = useMemo(
    () => [
      {
        title: 'Hunger',
        value: hunger,
        maxValue: 20,
        icon: lottieIcons.hunger,
      },
      {
        title: 'Happiness',
        value: happiness,
        maxValue: 20,
        icon: happiness > 10 ? lottieIcons.happiness : lottieIcons.happinessLow,
      },
      {
        title: 'Health',
        value: health,
        maxValue: 20 + endurance * 3,
        icon: health > 10 ? lottieIcons.health : lottieIcons.healthLow,
      },
    ],
    [hunger, happiness, health, endurance, lottieIcons]
  );

  return (
    <div className="block w-full h-full overflow-x-hidden top-0 absolute">
      <Starfield amount={30} />
      <Wrapper className="bg-[#080D1B] overflow-y-auto scrollable no-scrollbar">
        <div className="w-full">
          <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-12 rounded-[50%] z-0"></div>
          <div className="webapp-safe-top flex flex-col backdrop-blur to-transparent border-b border-white/[.1] w-full mb-1 items-center justify-center py-4">
            {/* Top user info */}
            <div className="flex relative w-full justify-between">
              <div className="relative shadow-[0_2px_28px_0_#661A79BA] flex p-0.5 bg-gradient-to-b from-[#FF9A46] to-[#F4C9A5] rounded-full justify-center items-center">
                <img
                  src={avatar || '/assets/images/avatar.png'}
                  className="rounded-full block w-12 h-12"
                  alt="avatar"
                />
                <span className="absolute -bottom-1 left-0">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3046 2.76465C13.921 0.816768 17.5056 0.816768 20.122 2.76464L20.5112 3.05441C21.2682 3.61799 22.1262 4.0312 23.0388 4.27166L23.508 4.3953C26.6622 5.2264 28.8972 8.02895 29.0056 11.289L29.0217 11.774C29.0531 12.7172 29.265 13.6457 29.646 14.5091L29.8419 14.9531C31.1587 17.9373 30.3611 21.432 27.8798 23.5494L27.5107 23.8644C26.7928 24.477 26.199 25.2216 25.7615 26.0578L25.5366 26.4877C24.0244 29.3779 20.7948 30.9332 17.5924 30.3134L17.116 30.2212C16.1894 30.0419 15.2371 30.0419 14.3105 30.2212L13.8341 30.3134C10.6317 30.9332 7.40206 29.3779 5.88992 26.4877L5.66497 26.0578C5.22747 25.2216 4.63369 24.477 3.91579 23.8644L3.54668 23.5494C1.06543 21.432 0.267787 17.9373 1.58462 14.9531L1.78052 14.5091C2.16152 13.6457 2.37344 12.7172 2.40479 11.774L2.42092 11.289C2.5293 8.02895 4.76426 5.2264 7.91847 4.3953L8.3877 4.27166C9.3003 4.0312 10.1583 3.61799 10.9153 3.05442L11.3046 2.76465Z"
                      fill="url(#paint0_linear_185_4292)"
                      stroke="white"
                      strokeWidth="0.824721"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_185_4292"
                        x1="5.5183"
                        y1="4.40184"
                        x2="23.9278"
                        y2="33.0183"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF7B43" />
                        <stop offset="1" stopColor="#FFE04E" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className="absolute text-white font-bold top-0.5 w-6 text-sm text-center">
                    {level}
                  </span>
                </span>
              </div>
              <div className="flex flex-col justify-center items-start ml-3 flex-grow">
                <div className="flex items-center gap-2">
                  {username ? (
                    <h6 className={`${username.length > 7 ? 'text-sm' : 'text-lg'} font-medium text-white`}>
                      {username}
                    </h6>
                  ) : (
                    <h6 className="text-lg font-medium text-white">Anonymous</h6>
                  )}
                </div>
                <p className="text-xs font-normal text-white/[.4]">{squad?.name}</p>
              </div>
              <div
                className="text-lg flex justify-center items-center"
                onClick={() => setShowVersionDebug(v => v + 1)}
              >
                <img alt="img" src={coinIcon} width={22} height={22} />
                <p className="font-bold text-white ml-2">{coins}</p>
              </div>
            </div>

            {/* Progress bar to next level */}
            <div className="flex w-full mt-4">
              <Progressbar
                blurred={false}
                winking={false}
                barcolor="bg-gradient-to-r from-[#FF6F41] to-[#FEFBE9]"
                ht="min-h-3"
                percent="w"
                spark={Round(progress, 1) > 20}
                value={totalExp}
                maxvalue={LEVELS[level + 1]}
                customStyle={{ width: `${Round(progress, 1)}%` }}
              />
            </div>
            <div className="flex w-full items-center mt-4 justify-between">
              <div className="text-sm font-normal text-white w-8/12">
                {totalExp}&nbsp;/&nbsp;<span className="text-white/[.4]">{LEVELS[level + 1]} EXP</span>
              </div>
              <Link onClick={()=> setGameMode('normal')} to={'/leaderbird'}  className="flex text-white items-center">
                <span className="nw-icon-wingl text-2xl text-white/[.6]"></span>
                <span className="mx-2 font-medium opacity-90">#{rank}</span>
                <span className="nw-icon-wingr text-2xl text-white/[.6]"></span>
              </Link>
            </div>
          </div>

          {/* Additional stats bars */}
          {totalExp >= LEVELS[1] && (
            <div className="w-12/12 flex items-center justify-between gap-4 m-auto py-4">
              {STATS.map(({ title, value, maxValue, icon }, i) => (
                <div className="w-4/12 flex items-center" key={title}>
                  <div className="flex flex-col flex-grow">
                    <div className="text-white text-sm flex items-center font-semibold">{title}</div>
                    <div className="text-white ml-5 mt-2">
                      <ProfileProgressbar
                        barcolor="bg-gradient-to-t from-[#723BE0] to-[#635BB8]"
                        iconStyle="items-center justify-center"
                        icon={icon}
                        blurred={false}
                        ht="h-5"
                        spark={false}
                        percent="w"
                        winking={false}
                        value={value}
                        maxvalue={maxValue}
                        customStyle={{
                          width: `${Math.min(100, Round((value / maxValue) * 100, 1))}%`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Gear */}
          <div className="mt-2">
            <h6 className="text-white text-sm font-semibold">Gear</h6>
            <ProfileGear />
          </div>

          {/* Tabs */}
          <Tabs className="mt-3 relative z-10">
            <TabButton
              className="relative pointer-events-auto flex justify-center items-center flex-grow font-medium text-sm"
              active={activeTab === 'skills'}
              onClick={() => setActiveTab('skills')}
            >
              <div>Skills</div>
              {skillPoints > 0 && (
                <span className="relative flex h-2 w-2 left-2">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75" />
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500" />
                </span>
              )}
            </TabButton>
            <TabButton
              className="relative pointer-events-auto flex justify-center items-center flex-grow font-medium text-sm"
              active={activeTab === 'inventory'}
              onClick={() => setActiveTab('inventory')}
            >
              Inventory
            </TabButton>
            <TabButton
              className="relative pointer-events-auto flex justify-center items-center flex-grow font-medium text-sm"
              active={activeTab === 'stickers'}
              onClick={() => setActiveTab('stickers')}
            >
              <span className="nw-icon-telegram text-2xl text-white mr-1"></span> Stickers
            </TabButton>
          </Tabs>

          {/* Skills tab */}
          {activeTab === 'skills' && (
            <div className="w-full text-center gap-3 bg-[#0F152A] backdrop-blur border border-[#202232] rounded-xl rounded-tl-none p-4">
              <div className="flex justify-between">
                {/* Left column */}
                <div className="flex flex-col gap-4">
                  {SKILLS.slice(0, 2).map(({ skill, title }) => (
                    <div className="flex gap-1" key={skill}>
                      <div className="flex items-center justify-center text-lg font-bold text-white mb-0 pb-0">
                        {skillPoints > 0 ? (
                          <button
                            className="pointer-events-auto relative"
                            onClick={() => {
                              tg_haptic.impactOccurred('light');
                              upSkill(skill);
                            }}
                          >
                            <img
                              className="w-7 h-7"
                              src={Add}
                              alt="Add"
                              onLoad={() => setIsAddLoading(false)}
                            />
                            {isAddLoading && (
                              <div className="absolute top-0 left-0 w-7 h-7 rounded-full bg-slate-700 animate-pulse" />
                            )}
                          </button>
                        ) : (
                          <img
                            className="w-5 h-5 rounded-full"
                            src={`https://notwise.b-cdn.net/icons/${title.toLowerCase()}.png`}
                            alt="icon"
                          />
                        )}
                        <span className="min-w-7">{userInfo[skill]}</span>
                      </div>
                      <div className="flex flex-col justify-start text-left">
                        <div className="items-center text-sm font-bold text-white">{title}</div>
                        <div className="items-center text-xs font-normal text-white/[60%]">
                          {SKILL_DESCR[title]}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Right column */}
                <div className="flex flex-col gap-4">
                  {SKILLS.slice(2, 4).map(({ skill, title }) => (
                    <div className="flex gap-1" key={skill}>
                      <div className="flex items-center justify-center text-lg font-bold text-white mb-0 pb-0">
                        {skillPoints > 0 && title.toLowerCase() !== 'wisdom' ? (
                          <button
                            className="pointer-events-auto relative"
                            onClick={() => {
                              tg_haptic.impactOccurred('light');
                              upSkill(skill);
                            }}
                          >
                            <img
                              className="w-7 h-7"
                              src={Add}
                              alt="Add"
                              onLoad={() => setIsAddLoading(false)}
                            />
                            {isAddLoading && (
                              <div className="absolute top-0 left-0 w-7 h-7 rounded-full bg-slate-700 animate-pulse" />
                            )}
                          </button>
                        ) : (
                          <img
                            className="w-5 h-5 rounded-full"
                            src={`https://notwise.b-cdn.net/icons/${title.toLowerCase()}.png`}
                            alt="icon"
                          />
                        )}
                        <span className="min-w-7">{userInfo[skill]}</span>
                      </div>
                      <div className="flex flex-col justify-start text-left">
                        <div className="items-center text-sm font-bold text-white">{title}</div>
                        <div className="items-center text-xs font-normal text-white/[60%]">
                          {SKILL_DESCR[title]}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Inventory tab */}
          {activeTab === 'inventory' && (
            <div className="w-full bg-[#0f152a] border border-[#202232] backdrop-blur rounded-xl p-4 pointer-events-auto mb-24">
              <Inventory />
            </div>
          )}

          {/* Stickers / Achievements tab */}
          {activeTab === 'stickers' && (
            <div className="w-full bg-[#0F152A] border border-[#202232] rounded-tr-none p-4 rounded-xl text-white pointer-events-auto mb-24">
              <AchievementsGrid achievements={ACH} userAchievements={achievements} />
            </div>
          )}
        </div>
      </Wrapper>
      <MainNav isBranch={false} gameMode={gameMode} />
      <VersionDebugView $visible={showVersionDebug >= 5}>
        build {packageJson.version}
      </VersionDebugView>
    </div>
  );
}

const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TabButton = styled.button<{ active: boolean }>`
  padding: 12px 16px;
  background: ${(props) => (props.active ? '#0F152A' : 'transparent')};
  color: #fff;
  cursor: pointer;
  border-radius: 12px 12px 0 0;
  text-align: center;
  border: ${(props) => (props.active ? '1px solid #202232' : '1px solid transparent')};
  position: relative;
  margin-bottom: -1px;
  border-bottom: none;
  &:focus {
    outline: none;
  }
  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border: ${(props) => (props.active ? '1px solid #202232' : '1px solid transparent')};
    border-top: none;
    border-left: none;
    border-radius: 100% 0;
    position: absolute;
    left: -10px;
    bottom: 0;
    box-shadow: 2px 3px 0 ${(props) => (props.active ? '#0F152A' : 'transparent')};
  }
  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border: ${(props) => (props.active ? '1px solid #202232' : '1px solid transparent')};
    border-top: none;
    border-right: none;
    border-radius: 0 100%;
    position: absolute;
    right: -10px;
    bottom: 0;
    box-shadow: -2px 3px 0 ${(props) => (props.active ? '#0F152A' : 'transparent')};
  }
  &:nth-child(1) {
    &:before {
      content: none;
    }
  }
  &:nth-child(3) {
    &:after {
      content: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  width: 100%;
  height: 100%;
`;

const VersionDebugView = styled.div<{
  $visible: boolean;
}>`
  position: fixed;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  color: #ffffff;
  font-size: 12px;
  transition: opacity 0.3s, filter 0.3s;

  ${({ $visible }) => $visible ? `
    opacity: 1.0;
    filter: blur(0px);
  ` : `
    opacity: 0.0;
    filter: blur(8px);
  `}
`;

export const Profile = React.memo(ProfileComponent);
