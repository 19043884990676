import { useUserStore } from '../../store';
import { LeaderDataItem, useGetLeaders } from '../../api/useGetLeaders';
import { Leader } from './Leader';
import Starfield from '../../components/Starfield';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import { LottiePlayer } from '../../components';
import styled from 'styled-components';
import { LeaderBirdPlacement } from './LeaderBirdPlacement';

export const LeaderBird = () => {
  const userInfo = useUserStore((state) => state.user);
  const { data } = useGetLeaders();
  const leaders = data?.slice(0, 3);
  const rest = data?.slice(3);

  useTelegramBackButton('/');

  return (
    <div className="relative flex flex-col justify-between h-dvh w-full bg-[#080D1B] z-5 overflow-y-scroll no-scrollbar pointer-events-auto">
      <Starfield amount={50} />
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-24 rounded-[50%] z-0"></div>
      <div className="flex flex-col items-center px-5 z-10 webapp-safe-top">
        <div className="w-[68px] h-[68px] mt-5">
          <LottiePlayer
            lottieUrl={'https://cdn.notwise.co/lottie/crown.lottie'}
            isLoop={false}
            preloaderStyle={'round'}
          />
        </div>
        <div className="text-2xl font-bold text-white mt-4">Exp Leaderbird</div>
        <div className="text-[#E3EAF699] text-center">The most experienced Owls</div>
      </div>

      <LeaderboardListContainer>
        <LeaderboardListHeaders>
          <div>Name</div>
          <div>Rank</div>
        </LeaderboardListHeaders>
        <LeaderboardListBody $highlight>
          {(
            (leaders ?? Array(3).fill(null).map(() => ({}))) as LeaderDataItem[]
          ).map(({ username, avatar, experience, level, tg_id }, index) => (
            <LeaderBirdPlacement
              key={index}
              username={username}
              rank={index + 1}
              experience={experience}
              level={level ?? 1}
              tg_id={Number(tg_id)}
              avatar={avatar}
              isSkeleton={!tg_id}
            />
          ))}
        </LeaderboardListBody>
        <LeaderboardListBody>
          {(
            (rest ?? Array(12).fill(null).map(() => ({}))) as LeaderDataItem[]
          ).map(({ username, avatar, experience, level, tg_id }, index) => (
            <LeaderBirdPlacement
              key={index}
              username={username}
              rank={index + 4}
              experience={experience}
              level={level ?? 1}
              tg_id={Number(tg_id)}
              avatar={avatar}
              isSkeleton={!tg_id}
            />
          ))}
        </LeaderboardListBody>
        <LeaderboardListPadding />
      </LeaderboardListContainer>

      <div
        className="fixed bottom-8 rounded-[24px] border-[0.6px] border-[rgba(32, 38, 60, 0.50)] p-3 pr-[18px] left-3 right-[18px]"
        style={{
          background: 'linear-gradient(341deg, rgba(35, 49, 86, 0.23) 24.15%, rgba(81, 74, 131, 0.23) 79.96%)',
          backdropFilter: 'blur(28px)',
          zIndex: 10
        }}
      >
        <Leader
          showArrow={false}
          name={'You'}
          rank={userInfo.rank}
          experience={userInfo.totalExp}
          tgId={Number(userInfo.tg_id)}
          avatar={userInfo.avatar ?? '/assets/images/avatar.png'}
          level={userInfo.level}
        />
      </div>
    </div>
  );
};

const LeaderboardListBody = styled.div<{
  $highlight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 10px;

  ${({ $highlight }) => $highlight ? `
    border-radius: 1rem;
    background: #aaaaff33;
  ` : ''}
`;

const LeaderboardListHeaders = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #E3EAF699;
  margin-bottom: 8px;
`;

const LeaderboardListContainer = styled.div`
  position: relative;
  display: block;
  padding: 15px;
  padding-bottom: 6rem;
  min-height: 100%;
`;

const LeaderboardListPadding = styled.div`
  height: 6rem;
`;

