import { useCallback, useEffect, useRef } from 'react';
import { useUserStore } from '../store';
import { MAX_ENERGY_BY_LEVEL } from '../config';

const MAX_UPDATE_INTERVAL = 45000;
const UPDATE_INTERVAL_DELTA = 1500;

export const useEnergy = () => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const happiness = useUserStore((state) => state.user.happiness);

  const energyUpdateInterval = MAX_UPDATE_INTERVAL - happiness * UPDATE_INTERVAL_DELTA;

  const lastEnergyUsed = useUserStore((state) => state.user.lastEnergyUsed);
  const energy = useUserStore((state) => state.user.energy);
  const level = useUserStore((state) => state.user.level);
  const maxEnergy = MAX_ENERGY_BY_LEVEL[level];
  const addEnergy = useUserStore((state) => state.addEnergy);

  const isMaxEnergy = energy === maxEnergy;
  const isEnergyFetched = energy !== null;

  const updateEnergy = useCallback(() => {
    const energyToAdd = 1;
    addEnergy(energyToAdd);
  }, [addEnergy]);

  const clearCurrentInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (isMaxEnergy || !isEnergyFetched) {
      clearCurrentInterval();
      return;
    }

    const fetchedLastEnergyUsedDate = lastEnergyUsed ? new Date(lastEnergyUsed) : null;

    const energyPassedRemainder = fetchedLastEnergyUsedDate
      ? (Date.now() - fetchedLastEnergyUsedDate.getTime()) % energyUpdateInterval
      : null;

    const delay = energyPassedRemainder === null ? null : energyUpdateInterval - energyPassedRemainder;

    const startInterval = () => {
      clearCurrentInterval();

      intervalRef.current = setInterval(updateEnergy, energyUpdateInterval);
    };

    if (!timeoutRef.current && delay !== null) {
      timeoutRef.current = setTimeout(() => {
        updateEnergy();
        startInterval();
      }, delay);
    } else {
      startInterval();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [lastEnergyUsed, updateEnergy, level, isMaxEnergy, isEnergyFetched, energyUpdateInterval]);
};
