import { Drawer } from 'vaul';
import IconClose from '../../assets/icon-close-drawer.svg';
import { MainButton } from '../MainButton';
import tgStar from '../../assets/icon-tg-star.svg';
import { useRefillEnergy } from '../../api/useRefillEnergy';
import toast from 'react-hot-toast';
import { useUserStore } from '../../store';
import {MAX_ENERGY_BY_LEVEL} from "../../config";
import EnergyIcon from "../../assets/low-battery.png";
import {useState} from "react";
import { ENERGY_UPDATE_INTERVAL } from '../../constants';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const getEnergyRefillDurationInMinutesByHappiness = (happiness: number): number => {
  const refillIntervalMs = ENERGY_UPDATE_INTERVAL[happiness];

  if (!refillIntervalMs) {
    return 15;
  }

  const timeToRefillEnergy = refillIntervalMs * 100;

  return timeToRefillEnergy / 60 / 1000;
};

const getHappinessLabel = (happiness: number): string => {
  if (happiness >= 20) {
    return 'very happy';
  }

  if (happiness >= 15) {
    return 'happy';
  }

  if (happiness >= 10) {
    return 'slightly unhappy';
  }

  return 'unhappy';
};

export const FuelDrawer = ({ isOpen, setIsOpen }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const tg = window.Telegram.WebApp;
  const { mutateAsync: refillEnergy } = useRefillEnergy();
  const energy = useUserStore((state) => state.user.energy);
  const level = useUserStore((state) => state.user.level);
  const happiness = useUserStore((state) => state.user.happiness);
  const addEnergy = useUserStore((state) => state.addEnergy);

  const fuelUp = async () => {
    if(isLoading) return;
    setIsLoading(true);
    try {
      const link = await refillEnergy({});
      if (link) {
        setIsLoading(false);
        tg.openInvoice(link, (status: any) => {
          if (status === 'paid') {
            energy !== null && addEnergy(MAX_ENERGY_BY_LEVEL[level] - energy);
            toast('Energy has been refilled', {
              icon: '👏',
              style: {
                borderRadius: '30px',
                background: '#090e1de3',
                color: '#fff',
              },
            });
          } else if (status === 'failed') {
            toast.error('Payment failed!', {
              style: {
                borderRadius: '30px',
                background: '#090e1de3',
                color: '#fff',
              },
            });
          }
        });
      }
    } catch (err) {
      console.error('Refill error:', err);
      toast.error('Refill failed', {
        style: {
          borderRadius: '30px',
          background: '#090e1de3',
          color: '#fff',
        },
      });
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <Drawer.Root dismissible={false} open={isOpen} onOpenChange={setIsOpen}>
      <Drawer.Portal>
        <Drawer.Title className="sr-only">Refill your energy</Drawer.Title>
        <Drawer.Overlay className="fixed inset-0 bg-black/40" />
        <Drawer.Content className="bg-[#13161B] z-10 flex flex-col rounded-t-[10px] h-[56%] mt-12 mb-5 fixed bottom-0 left-0 right-0">
          <div className="flex flex-col p-4 rounded-t-2xl h-full">
            <div className="w-full flex justify-end">
              <button className="rounded-full" onClick={() => setIsOpen(false)}>
                <img src={IconClose} alt={''} />
              </button>
            </div>
            <div className="flex flex-col justify-center flex-1 items-center">
              <div className="flex flex-col flex-1 justify-center items-center relative">
                <div className="w-20 h-20">
                  <img className="w-full h-auto" src={EnergyIcon} alt="nrg" />
                </div>
                <div className="text-[26px] mt-[5px] text-white font-semibold text-center">
                  Out of Energy, Fren
                </div>
                <div className="text-[16px] text-[#ffffff80] mt-3 text-center">
                  It&apos;ll refill soon, but we’ve got a quick boost for you!
                </div>
                <RefillTimeNote>
                  Your owl is
                  <OwlHappinessHighlight $happiness={happiness}>
                    {' '}{getHappinessLabel(happiness)}
                  </OwlHappinessHighlight>.
                  Energy will refill fully in
                  <EnergyRefillTimeHighlight> {getEnergyRefillDurationInMinutesByHappiness(happiness)} </EnergyRefillTimeHighlight>
                  minutes.
                </RefillTimeNote>
              </div>

              <MainButton
                isLoading={isLoading}
                btnText={
                  <div className="flex space-x-2.5 justify-center items-center">
                    <div>Fuel up</div>
                    <div
                      className="flex items-center space-x-1 py-[2px] px-[7px] rounded-[14px]"
                      style={{
                        background: 'linear-gradient(151deg, #FDCB81 -19.69%, #C55900 103.74%)',
                        backdropFilter: 'blur(12px)',
                      }}
                    >
                      <img src={tgStar} alt="tg-star" />
                      <div>{30 + level * 10}</div>
                    </div>
                  </div>
                }
                onClick={fuelUp}
              />
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

const OwlHappinessHighlight = styled.span<{
  $happiness: number;
}>`
  color: ${({ $happiness }) => {
    if ($happiness >= 20) {
      return '#93ff3b';
    }

    if ($happiness >= 15) {
      return '#e8ff14';
    }

    if ($happiness >= 10) {
      return '#ffc71f';
    }

    return '#ff6d24';
  }};
`;

const EnergyRefillTimeHighlight = styled.span`
  color: #ffffff;
`;

const RefillTimeNote = styled.div`
  font-size: 16px;
  color: #ffffff80;
  text-align: center;
  padding: 8px;
  margin-top: 24px;
  border: solid 1px #ffffff50;
  border-radius: 8px;
`;
