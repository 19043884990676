import styled from "styled-components";
import { useUserStore } from "../../store";

export const LeaderbirdBadge = () => {
  const { user } = useUserStore();

  return (
    <Container>
      <BadgeWings className="wings-left" />
      <Label>#{user.rank}</Label>
      <BadgeWings $flip className="wings-right" />
    </Container>
  );
};

const Label = styled.div`
  font-weight: 500;
  line-height: 1.0;
  color: #ffffff;
  opacity: 0.9;
`;

const BadgeWings = styled.div<{
  $flip?: boolean;
}>`
  @keyframes LeaderbirdBadgeWingsAppear${({ $flip }) => $flip ? 'Right' : 'Left'} {
    0% {
      opacity: 0.0;
    }

    30% {
      opacity: 0.0;
      transform: ${({ $flip }) => [
        $flip ? 'scaleX(-1.0)' : 'scaleX(1.0)',
        'translateX(12px)',
      ].join(' ')};
    }

    100% {
      opacity: 0.6;
      transform: ${({ $flip }) => [
        $flip ? 'scaleX(-1.0)' : 'scaleX(1.0)',
        'translateX(0px)',
      ].join(' ')};
    }
  }

  font-family: 'notwiseicons' !important;
  font-size: 1.875rem;
  line-height: 1.0;
  color: #ffffff;
  opacity: 0.6;
  transform: ${({ $flip }) => $flip ? 'scaleX(-1.0)' : 'scaleX(1.0)'};
  animation: LeaderbirdBadgeWingsAppear${({ $flip }) => $flip ? 'Right' : 'Left'} 1.0s ease-out 1 alternate both;

  &:before {
    content: '\\e903';
  }
`;

const Container = styled.div`
  @keyframes LeaderbirdBadgeContainerAppear {
    0% {
      opacity: 0.0;
      transform: translateY(10px);

      .wings-left, .wings-right {
        opacity: 0.0;
      }
    }

    30% {
      opacity: 1.0;
      transform: translateY(0px);
    }

    100% {
      opacity: 1.0;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  animation: LeaderbirdBadgeContainerAppear 1.0s ease-out 1 alternate both;
`;
