import { create } from 'zustand';
import { UserInfo } from '../types/interface';
import toast from 'react-hot-toast';
import { LEVELS, MAX_ENERGY_BY_LEVEL } from '../config';
import { AudioSystemUtil } from '../components/GameSceneNew/particles/AudioSystemUtil';
import { devtools } from 'zustand/middleware';

export const useUserStore = create<UserInfo>()(
  devtools(
    (set) => ({
      user: {
        cola_used: false,
        avatar: null,
        achievements: [],
        totalExp: 0,
        temperature: null,
        energy: null,
        skillPoints: 0,
        level: 0,
        coins: 0,
        strength: 0,
        agility: 0,
        wisdom: 0,
        endurance: 0,
        hunger: 0,
        tg_id: '',
        username: null,
        isPremium: false,
        lang: null,
        inventory: [],
        equipped: [],
        rank: 0,
        health: 0,
        happiness: 0,
        squad: null,
        current_battle: null,
        lastHeating: null,
        lastEnergyUsed: null,
        lastFeeding: null,
        lastSwiping: null,
        lastHealing: null,
      },
      isJoiningSquad: false,
      intermediate: {
        levelUps: 0,
      },
      foreigns: null,
      setUser: (payload) =>
        set((state) => ({
          ...state,
          user: {
            ...payload,
          },
        })),
      reduceCoins: (amount) =>
        set((state) => {
          return {
            user: {
              ...state.user,
              coins: state.user.coins - amount
            }
          }
        }),
      equipItem: (id: number) => {
        set((state) => {
          const { inventory, equipped } = state.user;

          const inventoryList = inventory ?? [];
          const equippedList = equipped ?? [];

          const itemToMove = inventoryList.find((item) => item.good.id === id);

          if (itemToMove) {
            return {
              user: {
                ...state.user,
                inventory: inventoryList.filter((item) => item.good.id !== id),
                equipped: [...equippedList, itemToMove],
              },
            };
          }
          return state;
        });
      },
      unEquipItem: (id: number) => {
        set((state) => {
          const { inventory, equipped } = state.user;

          const inventoryList = inventory ?? [];
          const equippedList = equipped ?? [];

          const itemToMove = equippedList.find((item) => item.good.id === id);

          if (itemToMove) {
            return {
              user: {
                ...state.user,
                equipped: equippedList.filter((item) => item.good.id !== id),
                inventory: [...inventoryList, itemToMove],
              },
            };
          }
          return state;
        });
      },
      setIsJoiningSquad: (isJoiningSquad: boolean) => set((state) => ({ ...state, isJoiningSquad })),
      setAchievements: (achievements: Array<number>) =>
        set((state) => ({ ...state, user: { ...state.user, achievements } })),
      addExperienceAndReduceEnergy: (experienceToAdd: number, energyToReduce: number) =>
        set((state) => {
          if (state.user.energy === null) {
            return state;
          }

          if (energyToReduce > state.user.energy) {
            return state;
          }

          const updatedExperience = state.user.totalExp + experienceToAdd;
          const updatedEnergy = Math.max(0.0, state.user.energy - energyToReduce);

          let updatedLevel = state.user.level;
          let deltaLevels = 0;

          if (updatedExperience >= LEVELS[updatedLevel + 1]) {
            while (updatedExperience >= LEVELS[updatedLevel + 1]) {
              updatedLevel += 1;
              deltaLevels += 1;
            }
          }

          if (deltaLevels > 0) {
            toast.success(`Level UP, bro. LVL ${updatedLevel}`, {
              icon: '👏',
              style: {
                borderRadius: '30px',
                background: '#090e1de3',
                color: '#fff',
              },
            });

            AudioSystemUtil.play('sfx-level-up', false);
          }

          return {
            ...state,
            user: {
              ...state.user,
              totalExp: updatedExperience,
              energy: updatedEnergy,
              level: updatedLevel,
            },
            intermediate: {
              ...state.intermediate,
              levelUps: deltaLevels,
            },
          };
        }),
      addHappinessAndReduceEnergy: (happinessToAdd: number, energyToReduce: number) =>
        set((state) => {
          if (state.user.energy === null) {
            return state;
          }

          if (energyToReduce > state.user.energy) {
            return state;
          }

          const newEnergy = Math.max(0.0, state.user.energy - energyToReduce);

          return {
            ...state,
            user: {
              ...state.user,
              happiness: state.user.happiness + happinessToAdd,
              energy: newEnergy,
            },
          };
        }),
      addTemperatureAndReduceEnergy: (temperatureToAdd: number, energyToReduce: number) =>
        set((state) => {
          if (state.user.temperature === null || state.user.energy === null) {
            return state;
          }

          if (energyToReduce > state.user.energy) {
            return state;
          }

          const newEnergy = Math.max(0.0, state.user.energy - energyToReduce);

          return {
            ...state,
            user: {
              ...state.user,
              temperature: state.user.temperature + temperatureToAdd,
              energy: newEnergy,
            },
          };
        }),
      decreaseTemperature: (temperature: number) =>
        set((state) => {
          if (state.user.temperature === null) {
            return state;
          }

          return {
            ...state,
            user: {
              ...state.user,
              temperature: Math.max(0, state.user.temperature - temperature),
            },
          };
        }),
      decreaseHappiness: (happiness: number) =>
        set((state) => {
          if (state.user.happiness === null) {
            return state;
          }

          return {
            ...state,
            user: {
              ...state.user,
              happiness: Math.max(0, state.user.happiness - happiness),
            },
          };
        }),
      increaseHealth: (health: number) =>
        set((state) => {
          if (state.user.health === null) {
            return state;
          }

          return {
            ...state,
            user: {
              ...state.user,
              health: Math.max(0, state.user.health + health),
            },
          };
        }),
      decreaseHunger: (hunger: number) =>
        set((state) => {
          if (state.user.hunger === null) {
            return state;
          }

          return {
            ...state,
            user: {
              ...state.user,
              hunger: Math.max(0, state.user.hunger - hunger),
            },
          };
        }),
      addEnergy: (energy: number) =>
        set((state) => {
          if (state.user.energy === null) {
            return state;
          }

          return {
            ...state,
            user: {
              ...state.user,
              energy: Math.min(MAX_ENERGY_BY_LEVEL[state.user.level], state.user.energy + energy),
            },
          };
        }),
    }),
    {
      name: 'UserStore',
    },
  ),
);
