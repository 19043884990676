import { Link } from 'react-router-dom';
import { useUserStore } from '../../store';
import { LottiePlayer, MainNav } from '../../components';
import Starfield from '../../components/Starfield';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import coinIcon from '../../assets/coin-icon.png';

export const Earn = () => {
  const user = useUserStore((state) => state.user);
  useTelegramBackButton('/');

  return (
    <div className="relative flex flex-col justify-between h-dvh w-full bg-[#080D1B] z-5 overflow-y-auto no-scrollbar pointer-events-auto">
      <Starfield amount={40} />
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-12 rounded-[50%] z-0"></div>
      <div className="flex flex-col webapp-safe-top items-center z-10 h-full px-5">
        <div className="w-[68px] h-[68px] mt-5">
          <LottiePlayer lottieUrl={'https://cdn.notwise.co/lottie/diamond.lottie'} isLoop={false} preloaderStyle={'round'} />
        </div>
        <div className="flex flex-col items-center justify-center w-full mb-1 mt-4">
          <h6 className="text-2xl font-bold text-white">
            <span className="empires">Earn</span> more coins
          </h6>
        </div>
        <div className="flex flex-col mt-6">
          <Link
            to="/frens"
            className="relative flex justify-between items-center rounded-xl backdrop-blur border border-[#202232] w-full px-4 py-4 mb-5"
          >
            <div className="flex justify-start items-center">
              <img
                alt="img"
                src="https://notwise.b-cdn.net/images/goods/food/bee.png"
                className="icon bg-[#D9D9D930] w-2/12 h-auto rounded-xl"
              />
              <div className="flex flex-col ml-3">
                <div className="text-white/[.8] font-medium text-sm">Invite Bonus</div>
                <div className="text-white font-medium text-sm">
                  up to{' '}
                  <b>
                    <img
                      alt="img"
                      className="inline-flex"
                      src={coinIcon}
                      width={16}
                      height={16}
                    />{' '}
                    21,100
                  </b>{' '}
                  per fren
                </div>
              </div>
            </div>
            <span className="nw-icon-arrow -rotate-90 text-white/[.6]"></span>
          </Link>
        </div>
        <h3 className="text-white font-bold">Onboarding</h3>
        <div className="flex flex-col mt-3">
          <Link
            to="/stories"
            className="relative flex justify-between items-center rounded-xl backdrop-blur border border-[#202232] w-full px-4 py-4 mb-5"
          >
            <div className="flex justify-start items-center">
              <img
                alt="img"
                src="https://notwise.b-cdn.net/images/goods/food/bee.png"
                className="icon bg-[#D9D9D930] w-2/12 h-auto rounded-xl"
              />
              <div className="flex flex-col ml-3">
                <div className="text-white/[.8] font-medium text-sm">NOT Wise</div>
                <div className="text-white font-medium text-sm">
                  get <b>knowledge</b>
                </div>
              </div>
            </div>
            <span className="nw-icon-arrow -rotate-90 text-white/[.6]"></span>
          </Link>
        </div>

        {/* <div className="max-h-64 overflow-y-auto scroll-mr-6">
          {
            FRENS_ARRAY.map((item, index) => (
              <div className="grid grid-cols-12 items-center gap-2 mb-2" key={index}>
                <div className="col-span-1 text-[10px] font-bold text-white">
                  <Link to={`/squad/${index+1}`} >#{index+1}</Link>
                </div>
                <div className="col-span-5">
                  <div className="flex items-center gap-2">
                    <div className="flex justify-center items-center w-3 h-3 rounded-full bg-[#D9D9D9]">
                    </div>
                    <h6 className="text-xs font-normal text-white opacity-60">{item.username}</h6>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex items-center gap-1 text-xs font-bold text-white">
                    <div className="flex justify-center items-center w-2 h-2 rounded-full bg-[#FFB800]">
                      <div className="coin__front rounded-full w-1 h-1 bg-[#FF9900]"></div>
                    </div>
                    {item.earn}
                  </div>
                </div>
                <div className="col-span-3">
                  <button className="flex justify-center items-center gap-1 w-full text-[8px] text-white font-bold rounded-md bg-[#D9D9D950] py-1">
                    <img alt="img" src={Branch} alt="branch"/>branch
                  </button>
                </div>
              </div>
            ))
          }
        </div>
        */}
      </div>
      <MainNav isBranch={false} gameMode="normal" />
    </div>
  );
};
