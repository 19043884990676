import React from 'react';
import {useGameStore} from "../../store";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";

type TBlockedReason = 403 | 404 | 418 | null | undefined;

export const BlockingOverlay = ({ children }: any) => {
  const blockedReason = useGameStore((state)=> state.blockedReason ) as TBlockedReason;
  const texts: Record<
    403 | 404 | 418,
    { title: string; descr: string; sticker: string }
  > = {
    403: {
      title: "A bit late, browl",
      descr: "NOT Wise open beta is now limited to 1000 participants.",
      sticker: "24.lottie"},
    404: {
      title: "Not in game",
      descr: "Consider hitting /start in the bot to register.",
      sticker: "14.lottie"},
    418: {
      title: "Maintenance Mode",
      descr: "Please try later. We're upgrading your experience.",
      sticker: "6.lottie"}
  }
  if (!blockedReason || !Object.prototype.hasOwnProperty.call(texts, blockedReason)) {
    return <>{children}</>;
  }

  const content = texts[blockedReason];
  return (
    <div className="flex h-dvh w-full bg-black text-white items-center justify-center absolute top-0 left-0">
      <div className="flex flex-col items-center justify-center w-10/12">
        <div className="w-32 h-32">
          <DotLottieReact src={`https://cdn.notwise.co/stickers/${content.sticker}`} loop autoplay useFrameInterpolation={false} />
        </div>
        <div className="text-center mt-5">
          <h2 className="text-2xl font-bold uppercase mb-2">{content.title}</h2>
          {content.descr}
        </div>
      </div>
    </div>
  );
};
