import React, { useEffect, useRef, useMemo } from 'react';
import { gsap } from 'gsap';

interface StarfieldProps {
  amount: number;
}

const StarfieldComponent: React.FC<StarfieldProps> = ({ amount }) => {
  const mainRef = useRef<HTMLDivElement>(null);

  const numStars = amount;

  const textures = useMemo(
    () => [
      'https://cdn.notwise.co/star1.png',
      'https://cdn.notwise.co/star2.png',
      'https://cdn.notwise.co/star3.png',
      'https://cdn.notwise.co/star4.png',
    ],
    []
  );

  // Simple randomizer function
  const random = (min: number, max?: number) => {
    if (max == null) {
      max = min;
      min = 0;
    }
    if (min > max) [min, max] = [max, min];
    return min + (max - min) * Math.random();
  };

  useEffect(() => {
    if (!mainRef.current) return;

    const vw = window.innerWidth;
    const vh = window.innerHeight - 100;

    const createStar = () => {
      const star = document.createElement('img');
      star.src = textures[Math.floor(random(textures.length))];
      star.style.position = 'absolute';
      star.style.pointerEvents = 'none'; // Less event overhead

      gsap.set(star, {
        xPercent: -50,
        yPercent: -50,
        x: random(vw),
        y: random(vh),
        scale: 0,
        autoAlpha: 0,
      });

      mainRef.current?.appendChild(star);

      // Animate: fade+scale in, then out (yoyo for reverse)
      gsap.to(star, {
        duration: random(0.5, 1.5),
        autoAlpha: random(0.5, 1),
        scale: random(0.1, 0.4),
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
        delay: random(0, 2),
      });
    };

    // Create desired number of stars
    for (let i = 0; i < numStars; i++) {
      createStar();
    }

    // Cleanup: remove stars on unmount
    return () => {
      if (!mainRef.current) return;
      while (mainRef.current.firstChild) {
        mainRef.current.removeChild(mainRef.current.firstChild);
      }
    };
  }, [numStars, textures]);

  return <div ref={mainRef} className="starfield" />;
};

// Wrap the component in React.memo to avoid unnecessary re-renders
const Starfield = React.memo(StarfieldComponent);

export default Starfield;
