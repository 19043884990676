import { Link } from "react-router-dom";
import { Fren as FrenType } from '../../api';
import CoinIcon from '../../assets/icon-coin.svg';
import PlaceholderAvatar from '../../assets/coin-icon.png';
import styled, { keyframes } from "styled-components";
import { useGameStore } from "../../store";

export const Fren = ({
  isSkeleton,
  avatar,
  username,
  frens_count,
  earned_for_referrer,
  tg_id,
}: {
  isSkeleton?: boolean;
} & Partial<FrenType>) => {
  const { setGameMode } = useGameStore();

  return (
    <Container>
      <FrenDetails>
        <Avatar>
          <img src={avatar ?? PlaceholderAvatar} alt={`${username}'s Avatar`} />
        </Avatar>

        <FrenInfo>
          {tg_id && (
            <>
              <Link to={`/freninfo/${tg_id}`} onClick={() => {
                setGameMode('normal');
              }}>
                <FrenName>
                  {username ?? `id${tg_id}`}
                  <FrenNameLink className="nw-icon-arrow" />
                </FrenName>
              </Link>
              <SmallLabel>
                + {frens_count ?? 0} fren{frens_count === 1 ? '' : 's'}
              </SmallLabel>
            </>
          )}

          {(!tg_id || isSkeleton) && (
            <>
              <PendingText width={128} />
              <SmallLabel>
                <PendingText width={48} />
              </SmallLabel>
            </>
          )}
        </FrenInfo>
      </FrenDetails>

      <FrenReferral>
        <img
          src={CoinIcon}
          alt="Earned referral coins"
          className="w-4 h-auto rounded-[50%]"
        />
        <FrenEarnedCoins>
          {(tg_id && !isSkeleton) ? (
            earned_for_referrer ?? 0
          ) : (
            <PendingText width={24} />
          )}
        </FrenEarnedCoins>
      </FrenReferral>
    </Container>
  );
};

const SmallLabel = styled.div`
  color: #E3EAF699;
  font-size: 0.75rem;
  line-height: 1rem;
`;

const FrenInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FrenEarnedCoins = styled.div`
  font-weight: bold;
  min-width: 24px;
`;

const FrenNameLink = styled.div`
  position: relative;
  display: inline-block;
  color: #ffffff33;
  margin-left: 8px;
  transform: rotate(-90deg);
`;

const FrenName = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
`;

const PendingFrenInfo = keyframes`
  0%, 100% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
`;

const PendingText = styled.span<{
  width: number;
}>`
  display: inline-block;
  position: relative;
  width: ${({ width }) => width}px;
  height: 1em;
  background:rgba(51, 65, 85, 0.53);
  border-radius: 2px;
  overflow: hidden;
  animation: ${PendingFrenInfo} 1.5s infinite;
  z-index: -1;
`;

const Avatar = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;

  & > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #33415588;
    animation: ${PendingFrenInfo} 1.5s infinite;
    z-index: -1;
  }
`;

const FrenReferral = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FrenDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
