import { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';
import toast from 'react-hot-toast';

import { useGameStore, useShopStore, useUserStore } from '../../store';
import { StoreGoodType } from '../../types/interface';
import {LottiePlayer, MainButton} from '../../components';
import { SuccessfulPurchaseScreen } from './SuccessfulPurchaseScreen';
import { useBuyGoods, useGetInventory, useGetInvoiceLink } from '../../api';
import { StoreItemType } from '../Store/StoreItemType';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const potentialLoot = [
  {
    img: 'https://staging.masonsplay.com/assets/images/egg1.webp',
    isRare: false,
  },
  {
    img: 'https://staging.masonsplay.com/assets/images/egg2.webp',
    isRare: false,
  },
  {
    img: 'https://staging.masonsplay.com/assets/images/egg3.webp',
    isRare: true,
  },
  {
    img: 'https://staging.masonsplay.com/assets/images/egg4.webp',
    isRare: true,
  },
];

const itemNoteMapping: Partial<
  Record<
    StoreGoodType,
    { prefix: string; suffix: string; icon: string }
  >
> = {
  food: {
    prefix: 'Restores your',
    icon: 'https://cdn.notwise.co/stickers/hunger.lottie',
    suffix: 'Hunger',
  },
  medicine: {
    prefix: 'Restores your',
    icon: 'https://cdn.notwise.co/stickers/hp.lottie',
    suffix: 'Health',
  },
};

export const StoreItemDetails = () => {
  const tg = window.Telegram.WebApp;
  const navigate = useNavigate();
  const { id } = useParams();

  useTelegramBackButton('/store');

  const { setCurrentProfileTab: setActiveTab } = useGameStore(
    (state) => ({
      setCurrentProfileTab: state.setCurrentProfileTab,
    })
  );

  const { userInfo, setUserInfo } = useUserStore((state) => ({
    userInfo: state.user,
    setUserInfo: state.setUser,
  }));
  const reduceCoins = useUserStore((state) => state.reduceCoins);
  const storeItem = useShopStore((state) =>
    state.storeGoods?.find((item) => item.id.toString() === id)
  );

  const [count, setCount] = useState<number>(1);
  const [isSuccessScreenShown, setIsSuccessScreenShown] = useState(false);

  const { getInvoiceLink } = useGetInvoiceLink({
    storeItemId: Number(id),
  });
  const { buyGoods } = useBuyGoods();
  const { getInventory } = useGetInventory();

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    gsap.fromTo(
      containerRef.current,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: 'power3.out',
      }
    );
  }, []);

  if (!storeItem) {
    return null;
  }

  const isEggType = storeItem.type === 'egg';
  const starsPrice = storeItem.stars_price != null;
  const itemPrice = storeItem.price ?? storeItem.stars_price ?? 0;

  const increase = () => {
    if (storeItem.price !== undefined) {
      if (isEggType) {
        setCount((prev) => prev + 1);
      } else {
        if (userInfo.coins >= storeItem.price * (count + 1)) {
          setCount((prev) => prev + 1);
        }
      }
    }
  };

  const decrease = () => {
    if (count > 1) setCount((prev) => prev - 1);
  };

  const onBuySuccess = () => {
    getInventory(
      {},
      {
        onSuccess: (data) => {
          toast('Item bought successfully', {
            icon: '👏',
            style: {
              borderRadius: '30px',
              background: '#090e1de3',
              color: '#fff',
            },
          });
          setUserInfo({
            ...userInfo,
            inventory: data.inventory,
          });
          if (!isEggType) {
            setActiveTab('inventory');
            reduceCoins( itemPrice * count);
            navigate('/profile');
          }
        },
      }
    );
  };

  const buyItem = () => {
    if (isEggType) {
      getInvoiceLink(
        { params: { amount: count } },
        {
          onSuccess: (link) => {
            tg.openInvoice(link, (status: any) => {
              if (status === 'paid') {
                setIsSuccessScreenShown(true);
                onBuySuccess();
              }
              if (status === 'failed') {
                toast.error('Payment failed!', {
                  style: {
                    borderRadius: '30px',
                    background: '#090e1de3',
                    color: '#fff',
                  },
                });
              }
            });
          },
        }
      );
    } else {
      buyGoods(
        { data: { good_id: storeItem.id, good_count: count } },
        {
          onSuccess: onBuySuccess,
          onError: () => {
            toast.error('Item purchase failed', {
              style: {
                borderRadius: '30px',
                background: '#090e1de3',
                color: '#fff',
              },
            });
          },
        }
      );
    }
  };

  if (isSuccessScreenShown) {
    return (
      <SuccessfulPurchaseScreen
        onCloseClick={() => setIsSuccessScreenShown(false)}
        purchasedGood={storeItem}
      />
    );
  }

  const noteMappingItem = itemNoteMapping[storeItem.type];
  const totalPrice = itemPrice * count;
  const balanceAfterPurchase =
    storeItem.price !== undefined
      ? userInfo.coins - storeItem.price * count
      : null;

  return (
    <div className="relative h-dvh w-full overflow-hidden bg-[#080D1B]">
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 webapp-safe-top rounded-[50%] z-0"></div>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 bottom-0 rounded-[50%] z-0"></div>

      <div
        ref={containerRef}
        className="px-5 flex flex-col flex-wrap h-full overflow-hidden justify-between"
      >
          <div className="webapp-safe-top relative flex flex-col items-center gap-6 px-2">
            <div className={`relative mt-5 bg-center bg-cover bg-no-repeat ${
              isEggType ? 'max-w-[180px]' : 'max-w-[256px]'
            }`}>
              {!isEggType && (
                <div className="absolute right-0 top-2 w-fit m-auto">
                  <StoreItemType type={storeItem.type} />
                </div>
              )}
              <img
                className={"w-full aspect-square rounded-3xl"}
                src={storeItem.img}
                alt="item"
              />
            </div>

            <h4 className="text-2xl font-bold text-white text-center">
              {storeItem.name}
            </h4>
            <p className="text-sm font-normal text-white text-center">
              {storeItem.description}
            </p>

            {noteMappingItem && (
              <div className="flex text-white items-center">
                <span className="mr-1">{noteMappingItem.prefix}</span>
                <div className="w-6 h-6 mr-1">
                  <LottiePlayer lottieUrl={noteMappingItem.icon} isLoop={true} preloaderStyle={'round'} />
                </div>
                <span className="font-medium">{noteMappingItem.suffix}</span>
              </div>
            )}
          </div>

          {isEggType && (
            <div className="z-10 flex flex-col items-center space-y-4">
              <div className="text-white font-bold">Potential loot</div>
              <div className="flex space-x-2">
                {potentialLoot.map(({ img, isRare }, idx) => (
                  <div className="relative" key={idx}>
                    {isRare && (
                      <div
                        className="absolute rounded-[15px] px-1.5 py-1 text-xs text-center text-white font-semibold top-[-8px] right-[-5px]"
                        style={{
                          background: 'rgba(217, 217, 217, 0.56)',
                          backdropFilter: 'blur(11px)',
                        }}
                      >
                        Rare
                      </div>
                    )}
                    <img
                      className="rounded-2xl w-[68px] h-auto"
                      src={img}
                      alt="potential-loot"
                    />
                  </div>
                ))}
              </div>
              <div className="text-xs text-white opacity-60">
                1-3 Items drop guaranteed
              </div>
            </div>
          )}

          <div className="relative z-10 webapp-safe-bottom">
            <div
              className={`flex justify-between items-center ${
                isEggType ? 'mb-5' : 'mb-6'
              }`}
            >
              <div className="flex items-center gap-2">
                <img
                  className="w-5 h-5"
                  alt="img"
                  src={
                    starsPrice
                      ? 'https://notwise.b-cdn.net/icons/stars.svg'
                      : 'https://notwise.b-cdn.net/icons/coin-icon.png'
                  }
                />
                <p className="text-2xl font-bold text-white">
                  {itemPrice}
                </p>
              </div>

              <div
                className="flex items-center rounded-lg h-8"
                style={{ background: 'rgba(245, 246, 245, 0.22)' }}
              >
                <button
                  className="w-8 h-full text-xl text-white"
                  onClick={decrease}
                >
                  -
                </button>
                <p className="w-8 text-center text-sm font-bold text-white leading-8">
                  {count}
                </p>
                <button
                  className="w-8 h-full text-xl text-white"
                  onClick={increase}
                >
                  +
                </button>
              </div>
            </div>

            {!starsPrice && (
              <div className="flex justify-center items-center gap-2 mb-4">
                <span className="text-sm font-medium text-white">
                  Balance after purchase:
                </span>
                <img
                  alt="coin"
                  src="https://notwise.b-cdn.net/icons/coin-icon.png"
                  width={18}
                  height={18}
                />
                <p className="text-sm font-bold text-white">
                  {balanceAfterPurchase !== null ? balanceAfterPurchase : 'N/A'}
                </p>
              </div>
            )}

            <div>
              {!starsPrice && balanceAfterPurchase !== null
                ? balanceAfterPurchase >= 0
                  ? (
                    <MainButton
                      isLoading={false}
                      btnText={`Buy ${count} for ${totalPrice} ${
                        starsPrice ? 'stars' : 'coins'
                      }`}
                      onClick={buyItem}
                    />
                  )
                  : (
                    <button
                      disabled
                      type="button"
                      className="w-full text-white bg-gradient-to-tl from-orange-800 bg-amber-600 opacity-50 font-medium rounded-lg text-sm px-5 py-3 text-center"
                    >
                      Insufficient balance
                    </button>
                  )
                : (
                  <MainButton
                    isLoading={false}
                    btnText={`Buy ${count} for ${totalPrice} stars`}
                    onClick={buyItem}
                  />
                )}
            </div>
          </div>
      </div>
    </div>
  );
};
