import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { tg_init } from '../utils/telegramapi';
import { useGameStore } from '../store';
import { useCreateUser } from '../api/';

export const useRegister = () => {
  const [searchParams] = useSearchParams();
  const setErrorCode = useGameStore((state) => state.setBlockedReason);
  const referrerId = searchParams.get('tgWebAppStartParam');

  const [isRegistering, setIsRegistering] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);

  const { createPlayer } = useCreateUser({
    onError: (error: any) => {
      if(error?.response?.status !== 409) {
        setErrorCode(error?.response?.status);
        console.error(error);
      }
    },
  });

  const registerUser = useCallback(async (): Promise<void> => {
    setIsRegistering(true);

    try {
      if(referrerId) {
        await createPlayer({
          data: {
            invited_by: referrerId,
            initData: tg_init,
          },
        });
      } else {
        await createPlayer({
          data: {
            initData: tg_init,
          },
        });
      }
      setHasRegistered(true);
    } finally {
      setIsRegistering(false);
    }
  }, [referrerId, createPlayer]);

  return {
    registerUser,
    isRegistering,
    hasRegistered,
    referrerId,
  };
};
