import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../store';
import { InventoryInfo } from '../../types/interface';

export const Inventory: React.FC = () => {
  const navigate = useNavigate();
  const { inventory } = useUserStore((state) => state.user);
  const tg = window.Telegram.WebApp;

  const [isLoading, setIsLoading] = useState(true);

  // Reverse the inventory array once and memoize it to avoid repeated reversing.
  const reversedInventory = useMemo(() => {
    if (!inventory || inventory.length === 0) return [];
    return [...inventory].reverse();
  }, [inventory]);

  const handleItemClick = useCallback(
    (item: InventoryInfo) => {
      // Safeguard: if user has no items of this type, do nothing.
      const categories = inventory?.filter((one) => one.good.type === item.good.type);
      if (!categories || categories.length === 0) return;

      tg.BackButton.show();
      navigate(`/inventory/details/${item.good.id}`);
    },
    [inventory, navigate, tg.BackButton]
  );

  if (!inventory || inventory.length === 0) {
    return <span className="text-white opacity-60 flex text-sm">No items yet</span>;
  }

  return (
    <div className="grid gap-3 grid-cols-4">
      {reversedInventory.map((item, index) => {
        const isEquipped = item.isEquipped;
        return (
          <div key={index} className="relative rounded-2xl">
            <button
              onClick={() => handleItemClick(item)}
              className={`relative z-[1] rounded-2xl bg-transparent w-full h-auto pointer-events-auto ${
                isEquipped ? 'opacity-100' : ''
              }`}
            >
              <img
                src={item.good.img}
                alt="item"
                className="rounded-2xl w-full h-full"
                onLoad={() => setIsLoading(false)}
              />
              {isLoading && (
                <div className="absolute top-0 left-0 w-full h-full bg-slate-700 animate-pulse rounded-2xl" />
              )}
            </button>
            {item.amount > 1 && (
              <span className="absolute z-[2] left-1 top-1 bg-gray-800/[.6] rounded-lg font-bold text-xs text-white px-2">
                x{item.amount}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
