import { useQuery } from './useQuery';

export interface LeaderDataItem {
  username: string;
  experience: number;
  coins: number;
  avatar: string | null;
  level: number;
  rank: number | null;
  tg_id: string;
}

export const useGetLeaders = () => {
  const { data, ...rest } = useQuery<Array<LeaderDataItem>>({
    url: 'leaderboard/',
    method: 'get',
  });

  return { data, ...rest };
};
