import { useEffect, useState } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import axios from "axios";

export const BattleLottiePlayer = ({
  preloaderStyle,
  lottieUrl,
  isLocked,
  lockedReason,
  isLoop,
}: {
  preloaderStyle: string | null;
  lottieUrl: string | null;
  isLocked?: boolean;
  lockedReason?: string;
  isLoop?: boolean;
}) => {
  const [animationData, setAnimationData] = useState<boolean>(false);

  useEffect(() => {
    if (lottieUrl) {
      axios.get(lottieUrl)
        .then((response: any) => {
          if (response.status === 200) setAnimationData(true);
        })
        .catch((error: any) => console.error('Error loading Lottie animation:', error));
    }
  }, [lottieUrl]);

  if (!animationData) {
    return (
      <div
        className={`${
          preloaderStyle === 'round' ? 'rounded-full' : 'rounded-xl'
        } w-full aspect-[1/1] bg-slate-700 animate-pulse`}
      />
    ); // Show a loading state while the animation is being fetched
  }

  return (
    <div className="relative rounded-xl overflow-hidden" style={{
      width: 80,
      height: 80,
    }}>
      <DotLottieReact
        src={lottieUrl as string}
        loop={isLoop}
        autoplay
        renderConfig={{
          autoResize: true
        }}
      />
      {!animationData && <div className="w-full aspect-[1/1] bg-slate-700 animate-pulse" />}

      {isLocked && animationData && (
        <div className="absolute top-0 w-full h-full flex flex-col justify-center items-center bg-[#0F152A]/[.8] backdrop-blur p-1">
          <span className="nw-icon-locked text-3xl text-white"></span>
          <div className="absolute bottom-3 text-[10px] font-bold">{lockedReason}</div>
        </div>
      )}
    </div>
  );
};
