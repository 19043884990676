import { useEffect, useState } from 'react';
import { Round } from '../../utils/math';
import { useGameStore, useUserStore } from '../../store';
import {
  InventoryWidget,
  LottiePlayer,
  MainControls,
  MainNav,
  Progressbar,
} from '../../components';
import { FuelDrawer } from '../../components/FuelDrawer/FuelDrawer';
import { MAX_ENERGY_BY_LEVEL } from '../../config';
import tgStar from '../../assets/icon-tg-star-gold.svg';
import { FightAnnouncement } from '../../components/FightWidgets/FightAnnouncement';
import { WebsocketIndicator } from '../../components/WebSocketContext/WebsocketIndicator';
import EnergyIcon from "../../assets/low-battery.png";

export const SESSION_STORAGE_DRAWER_KEY = 'isFuelDrawerShown';

export const Stage3 = () => {
  const userInfo = useUserStore((state) => state.user);
  const gameMode = useGameStore((state) => state.gameMode);
  const incomingBattleState = useGameStore((state) => state.incomingBattleState);
  const [isInventoryWidgetShown, setIsInventoryWidgetShown] = useState(false);
  const [isFuelDrawerOpen, setIsFuelDrawerOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (userInfo.energy === null) {
      return;
    }

    const isShown = localStorage.getItem(SESSION_STORAGE_DRAWER_KEY);

    if (userInfo.energy > 0 && isShown) {
      localStorage.removeItem(SESSION_STORAGE_DRAWER_KEY);
    }

    if (userInfo.energy <= 1) {
      if (isShown) {
        return;
      }

      setIsFuelDrawerOpen(true);
      localStorage.setItem(SESSION_STORAGE_DRAWER_KEY, 'true');
    }
  }, [userInfo.energy]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const fuelUp = () => {
    setIsFuelDrawerOpen(true);
  };

  if (userInfo.energy === null) {
    return null;
  }

  return (
    <>
      <div className="w-full flex flex-col items-center">
        {isInventoryWidgetShown && <InventoryWidget need="food" setIsShown={setIsInventoryWidgetShown} />}

        <div className="w-full bottom-28 absolute flex flex-col items-center">
          {userInfo.energy <= 50 && (
            <button
              className="mb-4 rounded-2xl py-3 px-4 space-x-2.5 flex w-fit bg-[#00000059] active:bg-[#0000008c]"
              onClick={fuelUp}
            >
              <div className="text-white text-[16px] flex font-semibold">⚡️ Fuel up</div>
              <div
                className="flex items-center space-x-1 py-[2px] px-[7px] rounded-[12px]"
                style={{
                  background:
                    'var(linear-gradient(130deg, rgba(253, 210, 26, 0.20) -21.52%, rgba(228, 123, 3, 0.20) 88.07%))',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <img src={tgStar} alt="tg-star" />
                <div
                  className="text-[12px] font-semibold"
                  style={{
                    color: '#FDD21A',
                  }}
                >
                  {30 + (userInfo.level*10)}
                </div>
              </div>
            </button>
          )}

          <div className="w-full flex items-center justify-between rounded-xl px-5">
            <div className="w-4/12 flex items-center">
              {userInfo.energy === 0 ? (
                <div className="w-8 h-8">
                  <img src={EnergyIcon} alt="nrg" />
                </div>
              ) : (
                <div className="w-8 h-8">
                  <LottiePlayer
                    lottieUrl="https://cdn.notwise.co/lottie/nrg.lottie"
                    isLoop={false}
                    preloaderStyle="round"
                  />
                </div>
              )}
              <span className="text-white font-medium ml-1 text-sm leading-3">
                <span className="text-xl font-bold">{userInfo.energy}</span>
                <br />
                <span className="opacity-70">/ {MAX_ENERGY_BY_LEVEL[userInfo.level]}</span>
              </span>
            </div>
            <Progressbar
              blurred
              winking={false}
              barcolor="bg-gradient-to-r from-[#FF6F41] via-[#FFAD42] to-[#FFE34F]"
              ht="min-h-3"
              percent="w"
              spark={false}
              value={userInfo.energy}
              maxvalue={MAX_ENERGY_BY_LEVEL[userInfo.level]}
              customStyle={{ width: `${Round(userInfo.energy / (MAX_ENERGY_BY_LEVEL[userInfo.level]/100), 1)}%` }}
            />
          </div>
        </div>

        <MainNav isBranch gameMode={gameMode} />
      </div>

      <MainControls isAllowedToFight />
      <FuelDrawer isOpen={isFuelDrawerOpen} setIsOpen={setIsFuelDrawerOpen} />
      {incomingBattleState && <FightAnnouncement />}
      <WebsocketIndicator />
    </>
  );
};
