import { useNavigate, useLocation } from 'react-router-dom';
import { tg_haptic } from '../../utils/telegramapi';
import {useGameStore, useUserStore} from '../../store/';

interface NavProps {
  isBranch: boolean;
  gameMode: any;
  isShop?: boolean;
}

export const MainNav = (props: NavProps) => {
  const userExp = useUserStore((state) => state.user.totalExp);
  const skillPoints = useUserStore((state) => state.user.skillPoints);
  const level = useUserStore((state) => state.user.level);
  const { isBranch } = props;
  const navigate = useNavigate();

  const { gameMode, setGameMode, gameStage, animating, setAnimating } = useGameStore((state) => ({
    gameMode: state.gameMode,
    setGameMode: state.setGameMode,
    gameStage: state.gameStage,
    animating: state.animating,
    setAnimating: state.setAnimating,
  }));

  const handleClick = (type: string) => {
    tg_haptic.impactOccurred('light');
    type === 'home' ? navigate('/') : navigate(`/${type}`);
  };

  const renderActionButton = () => {
    if (animating) {
      return <ActionButton level={level} icon="nw-icon-locked" label="flying" disabled />;
    }

    let destination: 'branch' | 'training' | 'fighting' = 'branch';
    let smoothTransition = false;

    if (isBranch) {
      smoothTransition = true;

      if (gameMode === 'training' || gameMode === 'fighting') {
        destination = 'branch';
      } else if (gameStage === 1) {
        destination = 'training';
      } else if (gameStage === 2) {
        destination = 'training';
      } else {
        destination = 'fighting';
      }
    } else {
      smoothTransition = false;

      if (gameMode === 'training') {
        destination = 'training';
      } else if (gameMode === 'fighting') {
        destination = 'fighting';
      } else {
        destination = 'branch';
      }
    }

    const onClick = () => {
      if (destination === 'branch') {
        setGameMode('normal');
        handleClick('home');
      } else if (destination === 'training') {
        setGameMode('training');
        handleClick('home');
      } else {
        setGameMode('fighting');
        handleClick('home');
      }

      if (smoothTransition) {
        setAnimating(true);
      }

      tg_haptic.impactOccurred('light');
    };
    const buttonIcon = {
      branch: 'nw-icon-branch',
      training: 'nw-icon-fight',
      fighting: 'nw-icon-fight',
    };
    const buttonLabel = {
      branch: 'branch',
      training: 'train',
      fighting: 'fight',
    };

    return (
      <ActionButton
        level={level}
        icon={buttonIcon[destination] ?? 'nw-icon-branch'}
        label={buttonLabel[destination] ?? 'branch'}
        onClick={onClick}
        disabled={gameStage === 0}
      />
    );
  };

  const navClass = `fixed bottom-8 left-1/2 -translate-x-1/2 z-10 flex w-11/12 items-center justify-between ${
    isBranch ? 'bg-[#D9D9D960]' : 'bg-white/[.08]'
  } backdrop-blur rounded-xl py-1`;

  return (
    <>
    {userExp >= 2000 ?
      <div className={navClass}>
        <NavItem level={level} path="/frens" icon="nw-icon-frens" label="frens" onClick={() => handleClick('frens')}/>
        <NavItem level={level} path="/earn" icon="nw-icon-gift" label="earn" onClick={() => handleClick('earn')}/>
        {renderActionButton()}
        <NavItem level={level} path="/store" icon="nw-icon-cart" label="shop" onClick={() => handleClick('store')}/>
        <NavItem level={level} path="/profile" icon={skillPoints > 0 && level > 0 ? "nw-icon-plus-filled" : "nw-icon-eyes"} label={skillPoints > 0 && level > 0 ? "new lvl" : "my owl"} onClick={() => handleClick('profile')}
                 showPing={skillPoints > 0}/>
      </div>
      :
      <div className={navClass}>
        <NavItem level={level} path="/frens" icon="nw-icon-locked" label="frens"/>
        <NavItem level={level} path="/earn" icon="nw-icon-locked" label="earn"/>
        {renderActionButton()}
        <NavItem level={level} path="/store" icon="nw-icon-locked" label="shop"/>
        <NavItem level={level} path="/profile" icon={skillPoints > 0 && level > 0 ? "nw-icon-plus-filled" : "nw-icon-eyes"} label={skillPoints > 0  && level > 0 ? "new lvl" : "my owl"} onClick={() => handleClick('profile')}
                 showPing={skillPoints > 0}/>
      </div>
    }
    </>
  );
};

interface NavItemProps {
  path?: string;
  icon: string;
  label: string;
  level: number;
  onClick?: () => void;
  disabled?: boolean;
  showPing?: boolean;
}

const NavItem = ({ path, icon, label, onClick, level, disabled = false, showPing = false }: NavItemProps) => {
  const location = useLocation();
  const isActive = path && location.pathname === path;

  return (
    <span
      className={`flex w-1/5 ${disabled ? 'text-white/[60%]' : 'text-white'}`}
      onClick={onClick}
    >
      <span className={`flex flex-col items-center w-11/12 py-2 rounded-xl ${isActive ? 'bg-white/[.05]' : 'bg-transparent'}`}>
      <span className={`${icon} text-[1.7rem] ${showPing && level > 0 && "text-[#F9C24F]"}`}></span>
      <p className={`text-xs font-normal ${showPing && level > 0  && "text-[#F9C24F]" }`}>{label}</p>
      {showPing && (
        <span className="absolute flex h-3 w-3 right-4">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75" />
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
        </span>
      )}
      </span>
    </span>
  );

};

const ActionButton = ({  icon, level, label, onClick, disabled = false }: NavItemProps) => (
  <NavItem level={level} icon={icon} label={label} onClick={onClick} disabled={disabled} />
);
