import { Round } from '../../utils/math';
import {
  LottiePlayer,
  MainControls,
  MainNav,
  Progressbar,
  Thermometer
} from '../../components';
import { useGameStore, useUserStore } from '../../store';
import { FuelDrawer } from '../../components/FuelDrawer/FuelDrawer';
import tgStar from '../../assets/icon-tg-star-gold.svg';
import { useEffect, useState } from 'react';
import { SESSION_STORAGE_DRAWER_KEY } from './Stage3';
import { FightAnnouncement } from '../../components/FightWidgets/FightAnnouncement';
import { WebsocketIndicator } from "../../components/WebSocketContext/WebsocketIndicator";
import EnergyIcon from '../../assets/low-battery.png';

export const Stage1 = () => {
  const userInfo = useUserStore((state) => state.user);
  const { incomingBattleState } = useGameStore();

  const [isFuelDrawerOpen, setIsFuelDrawerOpen] = useState(false);

  const fuelUp = () => {
    setIsFuelDrawerOpen(true);
  };

  useEffect(() => {
    if (userInfo.energy === null) {
      return;
    }

    const isShown = localStorage.getItem(SESSION_STORAGE_DRAWER_KEY);

    if (userInfo.energy > 0 && isShown) {
      localStorage.removeItem(SESSION_STORAGE_DRAWER_KEY);
    }

    if (userInfo.energy <= 1) {
      if (isShown) {
        return;
      }

      setIsFuelDrawerOpen(true);
      localStorage.setItem(SESSION_STORAGE_DRAWER_KEY, 'true');
    }
  }, [userInfo.energy]);

  if (userInfo.energy === null || userInfo.temperature === null) {
    return null;
  }

  return (
    <>
      <div className="absolute top-[50%] -translate-y-1/2 left-5 rounded-full backdrop-blur p-2 pb-4">
        <Thermometer
          barcolor="bg-gradient-to-t from-[#FF5741FF] to-[#ff0000]"
          ht="h-48"
          percent="h"
          spark={false}
          value={userInfo?.temperature}
          maxvalue={20}
          customStyle={{
            height: `${Math.min(100, Round((userInfo.temperature / 20) * 100, 1))}%`,
          }}
        />
      </div>

      <div className="w-full absolute z-5 bottom-28 flex items-center flex-col justify-between z-5">
        {userInfo.energy <= 50 && (
          <button
            className="mb-4 rounded-2xl py-3 px-4 space-x-2.5 flex w-fit bg-[#00000059] active:bg-[#0000008c]"
            onClick={fuelUp}
          >
            <div className="text-white text-[16px] flex font-semibold">⚡️ Fuel up</div>
            <div
              className="flex items-center space-x-1 py-[2px] px-[7px] rounded-[12px]"
              style={{
                background:
                  'var(linear-gradient(130deg, rgba(253, 210, 26, 0.20) -21.52%, rgba(228, 123, 3, 0.20) 88.07%))',
                backdropFilter: 'blur(10px)',
              }}
            >
              <img src={tgStar} alt="tg-star" />
              <div
                className="text-[12px] font-semibold"
                style={{
                  color: '#FDD21A',
                }}
              >
                30
              </div>
            </div>
          </button>
        )}

        <div className="w-full flex items-center justify-between rounded-xl px-5">
          <div className="w-4/12 flex items-center">
            {userInfo.energy === 0 ? (
              <div className="w-6 h-6">
                <img src={EnergyIcon} alt="nrg" />
              </div>
            ) : (
              <div className="w-8 h-8">
                <LottiePlayer
                  lottieUrl="https://cdn.notwise.co/lottie/nrg.lottie"
                  isLoop={false}
                  preloaderStyle="round"
                />
              </div>
            )}
            <span className="text-white font-medium ml-1 text-sm leading-3">
              <span className="text-xl font-bold">{userInfo.energy}</span>
              <br />
              <span className="opacity-70">/ 500</span>
            </span>
          </div>
          <Progressbar
            blurred
            winking={false}
            barcolor="bg-gradient-to-r from-[#FF6F41] via-[#FFAD42] to-[#FFE34F]"
            ht="min-h-3"
            percent="w"
            spark={false}
            value={userInfo.energy}
            maxvalue={500}
            customStyle={{ width: `${Round(userInfo.energy / 5, 1)}%` }}
          />
        </div>
      </div>

      <MainControls isAllowedToFight={false} />
      <MainNav isBranch gameMode="normal" />
      <FuelDrawer isOpen={isFuelDrawerOpen} setIsOpen={setIsFuelDrawerOpen} />

      {incomingBattleState && <FightAnnouncement />}
      <WebsocketIndicator />
    </>
  );
};
