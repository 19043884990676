import React, { MouseEventHandler, useState } from 'react';
import { AudioSystemUtil } from '../GameSceneNew/particles/AudioSystemUtil';

export const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(!AudioSystemUtil.isMuted());

  const handlePlayPause: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (isPlaying) {
      AudioSystemUtil.mute();
    } else {
      AudioSystemUtil.unmute();
    }

    setIsPlaying(!isPlaying);
  };

  return (
    <>
      <button
        className="flex items-center px-3 py-2 bg-white/[.2] backdrop-blur text-sm font-semibold rounded-xl pointer-events-auto text-white"
        onClick={handlePlayPause}
      >
        {isPlaying ? (
          <span className={`nw-icon-sound text-[1.7rem]`} />
        ) : (
          <span className={`nw-icon-no-sound text-[1.7rem]`} />
        )}
      </button>
    </>
  );
};
