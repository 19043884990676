import { Html } from "@react-three/drei";
import { useRef } from "react";
import styled from "styled-components";
import { Group, Object3D } from "three";
import { useFrame } from "@react-three/fiber";
import { useBindObject3DToLayer } from "../useBindObject3DToLayer";
import { getLayerByRenderOrder } from "../../components/composition/Layer";
import { useUserStore } from "../../../../store";
import { LottiePlayer } from "../../../LottiePlayer/LottiePlayer";

const slotPositions = {
  top: {
      x: 0.013737616182020443,
      y: 7.730283503571247,
      z: 0.794672936098209
  },
  center: {
      x: 0.01061561404146547,
      y: 3.69929941545071,
      z: -1.0487308823633223
  },
  bottom: {
      x: 0.015203963544450815,
      y: 0.34195108839907196,
      z: -0.13563345530521698
  }
};

export const useOwlNeedsControls = (enabled: boolean = false, {
  owl,
}: {
  owl: Object3D | null;
}) => {
  const userInfo = useUserStore((state) => state.user);
  const primaryIndicatorRef = useRef<Group>(null);
  const secondaryIndicatorRef = useRef<Group>(null);

  const need = (() => {
    const { hunger, happiness, health } = userInfo;

    if (hunger < 1) {
      return 'food';
    } else if (health < 1) {
      return 'health';
    } else if (happiness < 1) {
      return 'happiness';
    }

    return null;
  })();
  const needIcon = (() => {
    if (!need) {
      return null;
    }

    if (need === 'food') {
      return 'https://cdn.notwise.co/lottie/nrg.lottie';
    } else if (need === 'health') {
      return 'https://cdn.notwise.co/lottie/nrg.lottie';
    } else if (need === 'happiness') {
      return 'https://cdn.notwise.co/lottie/nrg.lottie';
    }

    return null;
  })();

  const onNeedClick = (need: string) => {
    // TODO Handle fast-usage
  };

  useFrame(() => {
    if (
      !primaryIndicatorRef.current ||
      !secondaryIndicatorRef.current ||
      !slotPositions.top
    ) {
      return;
    }

    primaryIndicatorRef.current.position.copy(slotPositions.top);
    secondaryIndicatorRef.current.position.copy(slotPositions.top);

    primaryIndicatorRef.current.position.x += 3.0;
    primaryIndicatorRef.current.position.y += 4.0;

    secondaryIndicatorRef.current.position.x += 2.1;
    secondaryIndicatorRef.current.position.y += 2.2;
  });

  useBindObject3DToLayer(primaryIndicatorRef.current, getLayerByRenderOrder(1));
  useBindObject3DToLayer(secondaryIndicatorRef.current, getLayerByRenderOrder(1));

  if (!need || !needIcon || !enabled) {
    return {};
  }

  return {
    children: (
      <>
        <group ref={secondaryIndicatorRef}>
          <Html>
            <Container $size={0.4} $delayMs={300} />
          </Html>
        </group>
        <group ref={primaryIndicatorRef}>
          <Html onClick={() => {
            onNeedClick(need);
          }}>
            <Container $size={1.0}>
              <div>
                <LottiePlayer
                  lottieUrl={needIcon}
                  isLoop
                  preloaderStyle="round"
                />
              </div>
            </Container>
          </Html>
        </group>
      </>
    )
  };
};

const Container = styled.div<{
  $size?: number;
  $delayMs?: number;
}>`
  @keyframes indicatorAnimation {
    0% {
      transform: translate(-50%, -50%) scale(1.0);
    }

    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }

    100% {
      transform: translate(-50%, -50%) scale(1.0);
    }
  }

  position: relative;
  width: calc(50px * ${({ $size }) => $size});
  height: calc(50px * ${({ $size }) => $size});
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 100;
  animation: indicatorAnimation 2s infinite ease-in-out alternate both ${({ $delayMs }) => $delayMs || 0}ms;

  & > div {
    pointer-events: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    aspect-ratio: 1/1;
  }
`;
