import { tg_haptic } from '../../utils/telegramapi';
import { useUserStore } from '../../store';

export const SquadWidget = () => {
  const { user, setIsJoiningSquad } = useUserStore();
  const { squad } = user;

  const handleJoin = () => {
    setIsJoiningSquad(true);

    const newWindow = window.open('https://t.me/notgames_bot/squads', '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  };

  return squad ? (
    <div
      onClick={() => {
        tg_haptic.impactOccurred('light');
        handleJoin();
      }}
      className="relative flex justify-start items-center pointer-events-auto"
    >
      <img alt="img" src={`${squad.logo}`} className="w-6 rounded-full" />
      <div className="ml-1 flex items-center">
        <span className="flex items-center text-xs font-semibold text-white w-auto">
          <span className="inline-flex max-w-40">{squad.name}</span>
          <span className={'inline-flex ml-1 text-white/[.2] nw-icon-arrow -rotate-90'}></span>
        </span>
      </div>
    </div>
  ) : (
    <div
      onClick={() => {
        tg_haptic.impactOccurred('light');
        handleJoin();
      }}
      className="relative flex justify-start items-center pointer-events-auto"
    >
      <div className="flex items-center">
        <span className="flex items-center text-xs font-semibold text-white"><span className={`nw-icon-notcoin text-lg mr-1 -ml-1`}></span>Join Squad
          <span className={'inline-flex ml-1 text-white/[.2] nw-icon-arrow -rotate-90'}></span>
        </span>
      </div>

    </div>
  );
};
