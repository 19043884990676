import Frame1Arrow from '../../assets/frame-arrow-1.svg';
import Frame2Arrow from '../../assets/frame-arrow-2.svg';
import Frame3Arrow from '../../assets/frame-arrow-3.svg';
import CoinIcon from "../../assets/coin-icon.png";

export const ReferralScheme = () =>{
  return (
    <div className="w-full h-full">
      <div className="w-80 relative m-auto">
        <div className="left-0 mt-[45px] relative items-start flex justify-between">
          <div className="w-12 flex-col justify-start items-start gap-3 inline-flex">
            <div className="h-12 flex-col justify-start items-start gap-1.5 flex">
              <div className="self-stretch text-white text-2xl font-bold font-['Montserrat']">🤩</div>
              <div className="self-stretch pl-0.5 justify-center items-center gap-2.5 inline-flex">
                <div className="grow shrink basis-0 text-white text-xs font-bold font-['Montserrat']">You</div>
              </div>
            </div>
            <div className="self-stretch h-4 flex-col justify-center items-center gap-2.5 flex">
              <div className="self-stretch text-slate-200/60 text-sm font-normal font-['Montserrat']">Invite</div>
            </div>
            <div className="self-stretch h-11 flex-col justify-center items-center gap-2.5 flex">
              <div className="self-stretch text-white text-sm font-bold font-['Montserrat']">Lvl 1</div>
              <div className="self-stretch text-white text-sm font-bold font-['Montserrat']">Lvl 21</div>
            </div>
          </div>
          <div className="w-14 flex-col justify-start items-start gap-3 inline-flex">
            <div className="self-stretch h-12 flex-col justify-start items-start gap-1.5 flex">
              <div className="self-stretch text-white text-2xl font-bold font-['Montserrat']">🤤</div>
              <div className="self-stretch pl-0.5 justify-start items-center gap-2.5 inline-flex">
                <div className="text-white text-xs font-bold font-['Montserrat']">Fren</div>
              </div>
            </div>
            <div className="self-stretch h-20 flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-start items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">100</div>
              </div>
              <div className="justify-start items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">1,000</div>
              </div>
              <div className="justify-start items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">21,000</div>
              </div>
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-3 inline-flex">
            <div className="self-stretch h-12 flex-col justify-start items-start gap-1.5 flex">
              <div className="self-stretch text-white text-2xl font-bold font-['Montserrat']">🤤</div>
              <div className="self-stretch pl-0.5 justify-start items-center gap-2.5 inline-flex">
                <div className="text-white text-xs font-bold font-['Montserrat']">His fren</div>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-3 flex">
              <div className="justify-center items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">20</div>
              </div>
              <div className="justify-center items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">250</div>
              </div>
              <div className="justify-center items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">5,000</div>
              </div>
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-3 inline-flex">
            <div className="self-stretch h-12 flex-col justify-start items-start gap-1.5 flex">
              <div className="self-stretch text-white text-2xl font-bold font-['Montserrat']">😃</div>
              <div className="self-stretch pl-0.5 justify-start items-center gap-2.5 inline-flex">
                <div className="text-white text-xs font-bold font-['Montserrat']">His fren</div>
              </div>
            </div>
            <div className="flex-col justify-start items-start gap-3 flex">
              <div className="justify-start items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">10</div>
              </div>
              <div className="justify-start items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">100</div>
              </div>
              <div className="justify-start items-center gap-1.5 inline-flex">
                <div className="w-4 h-4 relative rounded-2xl">
                  <img src={CoinIcon} alt={''}/>
                </div>
                <div className="text-white text-sm font-bold font-['Montserrat']">1,000</div>
              </div>
            </div>
          </div>
        </div>
        <img src={Frame1Arrow} className="w-12 h-1 left-[205.55px] top-[16.50px] absolute" alt={''} />
        <img src={Frame1Arrow} className="w-12 h-1 left-[110.55px] top-[16.50px] absolute" alt={''} />
        <img src={Frame1Arrow} className="w-12 h-1 left-[30.55px] top-[16.50px] absolute" alt={''} />
        <img src={Frame2Arrow} className="w-60 h-auto left-[25px] top-[-40px] absolute" alt={''} />
        <img src={Frame3Arrow} className="w-36 h-auto left-[35px] top-[-20px] absolute" alt={''} />
      </div>
    </div>
  )
}
