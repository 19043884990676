import { useCallback, useEffect, useRef, useState } from 'react';
import { useGameStore, useUserStore } from '../../../../store';
import { detectSwipeModelIntersection, handleAnimation } from '../../../../utils';
import { ParticleSystemUtil } from '../../particles/ParticleSystemUtil';
import { Coords } from '../../components';
import { AnimationAction, Object3D } from 'three';
import { useThree } from '@react-three/fiber';
import { useUserAction } from '../../../../hooks';
import { BatchedRenderer, ConstantValue, ParticleEmitter, ParticleSystem } from 'three.quarks';
import {tg_haptic} from "../../../../utils/telegramapi";
import { AudioSystemUtil } from '../../particles/AudioSystemUtil';
import { useDayNightCycle } from '../useDayNightCycle';

const TOP_MODEL_INTERSECTION_OFFSET = -1;
const BOTTOM_MODEL_INTERSECTION_OFFSET = -0.2;
const LEFT_MODEL_INTERSECTION_OFFSET = -0.1;
const RIGHT_MODEL_INTERSECTION_OFFSET = 0.1;

export const getOwlIdleAnimation = (owlInfo: {
  health: number;
  happiness: number;
  isForeign: boolean;
  isSleeping: boolean;
}) => {
  if (owlInfo.isSleeping) {
    return 'sleep';
  }

  if (owlInfo.health <= 1 || owlInfo.happiness <= 1) {
    return Math.random() < 0.5 ? 'owl_is_ill_v1' : 'owl_is_ill_v2';
  }

  return owlInfo.isForeign ? 'idle_new' : 'idle';
};

export const useIdleOwlControls = (
  enabled: boolean = false,
  {
    owl,
    actions,
    batchedRenderer,
    particleEffects,
    colaUsed,
    isForeignOwl,
  }: {
    owl: Object3D | null;
    actions: Record<string, AnimationAction | null> | null;
    batchedRenderer: BatchedRenderer;
    particleEffects: Record<string, Object3D | undefined>;
    colaUsed: boolean;
    isForeignOwl: boolean;
  },
) => {
  const energy = useUserStore((state) => state.user.energy);
  const [intersectionStart, setIntersectionStart] = useState(false);
  const [intersectionEnd, setIntersectionEnd] = useState(false);
  const { scene: mainScene, camera } = useThree();
  const { handleClickAction, handleSwipeAction } = useUserAction('owl');
  const { health, happiness } = useUserStore((state) => state.user);
  const { intermediate } = useUserStore();
  const {
    currentPointerCoords,
    setCurrentPointerCoords
  } = useGameStore((state) => ({
    currentPointerCoords: state.currentPointerCoords,
    setCurrentPointerCoords: state.setCurrentPointerCoords,
  }));
  const startIntersectionPoint = useRef<Coords>({ x: 0, y: 0 });

  const animationProps = {
    target: owl,
    model: 'owl',
    action: actions?.['OwlGroup|Take 001|BaseLayer'],
    isLoop: false,
  };

  const isMaxHappiness = !isForeignOwl && happiness === 20;
  const [isSleeping, setIsSleeping] = useState(false);

  useDayNightCycle(({ daynightCycle }) => {
    const isNight = ((daynightCycle + 1.0) / 2.0) >= 0.5;

    if (isSleeping && isNight) {
      setIsSleeping(false);
    } else if (!isSleeping && !isNight) {
      setIsSleeping(true);
    }
  });

  const onClick = async (event: MouseEvent) => {
    if (
      !actions ||
      !owl ||
      !particleEffects.questionsMark ||
      !energy ||
      energy <= 0
    ) {
      return;
    }

    event.stopPropagation();

    handleClickAction(event);

    if (actions['OwlGroup|Take 001|BaseLayer']) {
      if (!isSleeping) {
        handleAnimation({
          ...animationProps,
          animationName: Math.random() < 0.5 ? 'look_with_interest_v1' : 'look_with_interest_v2',
          onComplete: () => {
            handleAnimation({
              ...animationProps,
              isLoop: true,
              animationName: getOwlIdleAnimation({
                health: isForeignOwl ? 100 : health,
                happiness: isForeignOwl ? 100 : happiness,
                isForeign: isForeignOwl,
                isSleeping,
              }),
            });
          },
        });
      } else {
        handleAnimation({
          ...animationProps,
          animationName: Math.random() < 0.5 ? 'wakes_up_v1' : 'wakes_up_v2',
          onComplete: () => {
            handleAnimation({
              ...animationProps,
              animationName: Math.random() < 0.5 ? 'falls_asleep_v1' : 'falls_asleep_v2',
              onComplete: () => {
                handleAnimation({
                  ...animationProps,
                  isLoop: true,
                  animationName: getOwlIdleAnimation({
                    health: isForeignOwl ? 100 : health,
                    happiness: isForeignOwl ? 100 : happiness,
                    isForeign: isForeignOwl,
                    isSleeping,
                  }),
                });
              }
            });
          }
        });
      }
    }
    tg_haptic.impactOccurred('light');
    ParticleSystemUtil.playEffect('QuestionsMark', mainScene, particleEffects.questionsMark, batchedRenderer);

    // @ts-ignore
    AudioSystemUtil.play(`sfx-owl-happy-${Math.floor(Math.random() * 3.0) + 1.0}`, false, 0.5, 0.5);
  };

  const onSwipe = useCallback(
    (point: Coords, model: Object3D) => {
      if (energy !== null) {
        if (!actions || !owl || isMaxHappiness || energy < 10) {
          return;
        }
      }

      const isIntersection = detectSwipeModelIntersection({
        model,
        point,
        camera,
        offset: {
          top: TOP_MODEL_INTERSECTION_OFFSET,
          bottom: BOTTOM_MODEL_INTERSECTION_OFFSET,
          left: LEFT_MODEL_INTERSECTION_OFFSET,
          right: RIGHT_MODEL_INTERSECTION_OFFSET,
        },
      });

      if (isIntersection && !intersectionStart) {
        setIntersectionStart(true);
        startIntersectionPoint.current = point;
      }

      if (intersectionStart && !isIntersection && !intersectionEnd) {
        setIntersectionEnd(true);
      }

      if (intersectionEnd) {
        setIntersectionStart(false);
        setIntersectionEnd(false);
        setCurrentPointerCoords(null);

        handleSwipeAction(mainScene, particleEffects.heartPoof, batchedRenderer);

        // @ts-ignore
        AudioSystemUtil.play('sfx-owl-happy-4', false, 0.5, 0.5);

        if (!isSleeping) {
          handleAnimation({
            ...animationProps,
            animationName: Math.random() < 0.5 ? 'satisfied_v1' : 'satisfied_v2',
            onComplete: () => {
              handleAnimation({
                ...animationProps,
                isLoop: true,
                animationName: getOwlIdleAnimation({
                  health: isForeignOwl ? 100 : health,
                  happiness: isForeignOwl ? 100 : happiness,
                  isForeign: isForeignOwl,
                  isSleeping,
                }),
              });
            },
          });
        } else {
          handleAnimation({
            ...animationProps,
            animationName: Math.random() < 0.5 ? 'wakes_up_v1' : 'wakes_up_v2',
            onComplete: () => {
              handleAnimation({
                ...animationProps,
                animationName: Math.random() < 0.5 ? 'falls_asleep_v1' : 'falls_asleep_v2',
                onComplete: () => {
                  handleAnimation({
                    ...animationProps,
                    isLoop: true,
                    animationName: getOwlIdleAnimation({
                      health: isForeignOwl ? 100 : health,
                      happiness: isForeignOwl ? 100 : happiness,
                      isForeign: isForeignOwl,
                      isSleeping,
                    }),
                  });
                }
              });
            }
          });
        }
      }
    },
    [
      actions,
      camera,
      handleSwipeAction,
      intersectionEnd,
      intersectionStart,
      mainScene,
      setCurrentPointerCoords,
      particleEffects.heartPoof,
      isSleeping,
    ],
  );

  useEffect(() => {
    if (!enabled || !owl || !currentPointerCoords) {
      return;
    }

    onSwipe(currentPointerCoords, owl);
  }, [enabled, owl, currentPointerCoords, onSwipe]);

  useEffect(() => {
    if (!enabled || !actions) {
      return;
    }

    handleAnimation({
      target: owl,
      model: 'owl',
      action: actions['OwlGroup|Take 001|BaseLayer'],
      animationName: getOwlIdleAnimation({
        health: isForeignOwl ? 100 : health,
        happiness: isForeignOwl ? 100 : happiness,
        isForeign: isForeignOwl,
        isSleeping,
      }),
      isLoop: true,
    });
  }, [actions, owl]);

  useEffect(() => {
    if (!particleEffects.levelUp || intermediate.levelUps === 0) {
      return;
    }

    const timeouts: NodeJS.Timeout[] = [];

    for (let i = 0; i < intermediate.levelUps; i++) {
      timeouts.push(
        setTimeout(() => {
          ParticleSystemUtil.playEffect('LevelUp', mainScene, particleEffects.levelUp, batchedRenderer);

          if (i === intermediate.levelUps - 1) {
            useUserStore.setState((state) => ({
              ...state,
              intermediate: {
                ...state.intermediate,
                levelUps: 0
              }
            }));
          }
        }, i * 300)
      );
    }

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [intermediate.levelUps, particleEffects?.levelUp]);

  useEffect(() => {
    if (particleEffects["colaProtection"]) {
      if (colaUsed) {
        particleEffects["colaProtection"]!.traverse((obj: Object3D) => {
          if (obj instanceof ParticleEmitter && (obj.system as ParticleSystem).onlyUsedByOther === false) {
            (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(20);
          }
        });
      } else {
        particleEffects["colaProtection"]!.traverse((obj: Object3D) => {
          if (obj instanceof ParticleEmitter && (obj.system as ParticleSystem).onlyUsedByOther === false) {
            (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(0);
          }
        });
      }
    }
  }, [colaUsed, particleEffects["colaProtection"]]);

  return enabled
    ? {
        onClick,
      }
    : {};
};
