import { Drawer } from 'vaul';
import { BattleIcon } from './BattleIcon';
import { useWebSocketContext } from '../WebSocketContext';
import { useNavigate } from 'react-router';
import { useGameStore, useUserStore } from '../../store';
import { startOwlMatchmaking } from '../GameSceneNew/hooks/useOwlBattleService';
import { useEffect, useState } from 'react';
import { getMissingStatOrNullByUserInfo } from './shared';
import styled from 'styled-components';
import toast from 'react-hot-toast';

const Loader = () => {
  return (
    <LoaderContainer>
      <div className="flex justify-center">
        <LoaderSpinner>
          <BattleIcon />
        </LoaderSpinner>
      </div>
      <Drawer.Title className="mt-4 mb-2 font-semibold text-center max-w-60 mx-auto text-white">
        Looking for an opponent
      </Drawer.Title>
    </LoaderContainer>
  );
};

const MATCHMAKING_MAX_RETRIES = 4;

export const Matchmaking = ({ onCloseDrawer }: { onCloseDrawer: () => void }) => {
  const webSocketContext = useWebSocketContext();
  const navigate = useNavigate();
  const { setGameMode, battleError, battleState, setBattleState, setBattleSpoils, setBattleError } = useGameStore();
  const userInfo = useUserStore((state) => state.user);
  const missingStat = getMissingStatOrNullByUserInfo(userInfo);
  const [matchmakingRetries, setMatchmakingRetries] = useState<number | null>(null);
  const [matchmakingDifficulty, setMatchmakingDifficulty] = useState<'easy' | 'medium' | 'hard' | null>(null);
  const displayMatchmakingError = battleError && matchmakingRetries && matchmakingRetries >= MATCHMAKING_MAX_RETRIES;

  const onStartFight = ({ difficulty }: { difficulty: 'easy' | 'medium' | 'hard' }) => {
    if (matchmakingRetries !== null && matchmakingRetries !== MATCHMAKING_MAX_RETRIES) {
      return;
    }

    setBattleState(null);
    setBattleSpoils(null);
    setBattleError(null);
    setGameMode('fighting');

    setMatchmakingRetries(0);
    setMatchmakingDifficulty(difficulty);

    setTimeout(() => {
      startOwlMatchmaking(webSocketContext, userInfo, difficulty ?? 'easy');
    }, 100);
  };

  useEffect(() => {
    if (!(battleError || battleState?.uuid)) {
      return;
    }

    if (battleState?.uuid) {
      setMatchmakingRetries(null);
      setMatchmakingDifficulty(null);

      setGameMode('battle');
      navigate('/fight');
    }

    if (matchmakingRetries !== null) {
      if (matchmakingRetries < MATCHMAKING_MAX_RETRIES) {
        setMatchmakingRetries(matchmakingRetries + 1);

        setTimeout(() => {
          startOwlMatchmaking(webSocketContext, userInfo, matchmakingDifficulty ?? 'easy');
        }, 3000);
      } else {
        setMatchmakingRetries(MATCHMAKING_MAX_RETRIES);
      }
    }
  }, [battleState, battleError]);

  useEffect(() => {
    return () => {
      setMatchmakingRetries(null);
      setMatchmakingDifficulty(null);
    };
  }, []);

  if (missingStat) {
    return null;
  }

  if (matchmakingRetries !== null && matchmakingRetries < MATCHMAKING_MAX_RETRIES) {
    return <Loader />;
  }

  return (
    <>
      <div className="flex justify-center">
        <BattleIcon />
      </div>
      <Drawer.Title className="mb-2 font-semibold text-xl text-center max-w-60 mx-auto text-white">
        {displayMatchmakingError ? <>No opponents found</> : <>Find your battle</>}
      </Drawer.Title>
      <span className={'px-10 text-white/[.5] text-center flex'}>
        We’ll match you with an opponent based on difficulty.
      </span>
      <div className="flex flex-col justify-between relative">
        <div className={`flex w-full items-center justify-between mb-6 m-auto`}>
          <div className="w-4/12 flex  items-center justify-center">
            <button
              className="w-11/12 text-white bg-white/[0.05] py-2 font-medium rounded-xl"
              onClick={() => {
                onStartFight({
                  difficulty: 'easy',
                });
              }}
            >
              Easy
            </button>
          </div>
          <div className="w-4/12 flex items-center justify-center">
            <button
              onClick={() => {
                onStartFight({
                  difficulty: 'medium',
                });
              }}
              className="w-11/12 text-white bg-white/[0.05] py-2 font-medium  rounded-xl"
            >
              Medium
            </button>
          </div>
          <div className="w-4/12 flex items-center justify-center">
            <button
              onClick={() => {
                onStartFight({
                  difficulty: 'hard',
                });
              }}
              className="w-11/12 text-white bg-white/[0.05] py-2 font-medium  rounded-xl"
            >
              Hard
            </button>
          </div>
        </div>
        <span
          onClick={() => {
            setGameMode('battle');
            navigate('/fight');
          }}
          className={'w-full text-white/[.5] flex justify-center text-sm mb-3'}
        >
          Or challenge your fren
        </span>
        <button
          onClick={() => {
            onCloseDrawer();
            toast.success('NOT Time yet', {
              icon: '🤷‍♂️',
              style: {
                borderRadius: '30px',
                background: '#090e1de3',
                color: '#fff',
              },
            });
          }}
          className="pointer-events-auto w-full text-white bg-[#007AFF] font-medium rounded-lg text-sm px-5 py-4 mb-14 text-center"
        >
          Invite via Telegram
        </button>
      </div>
    </>
  );
};

const LoaderSpinner = styled.div`
  @keyframes LoaderSpinner {
    0% {
      transform: rotate(0deg);
      filter: blur(0);
    }

    2.5% {
      transform: rotate(-5deg);
    }

    25% {
      filter: blur(2px);
    }

    50% {
      transform: rotate(365deg) scale(1.3);
    }

    55% {
      transform: rotate(360deg) scale(1.3);
      filter: blur(0);
    }

    75% {
      transform: rotate(360deg) scale(1);
    }

    100% {
      transform: rotate(360deg) scale(1);
    }
  }

  animation: LoaderSpinner 1s infinite ease;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  transform: translate(-50%, -50%);
`;
