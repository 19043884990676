import {useEffect, useRef, useState} from 'react';
import { gsap } from 'gsap';
import { Round } from '../../utils/math';
import { Progressbar } from '../Progressbar';
import {LottiePlayer} from "../LottiePlayer/LottiePlayer";
import {useGameStore, useUserStore} from "../../store";
import {userInfo} from "os";


const UpdateIcons: { [key: string]: string } = {
  happiness: 'https://cdn.notwise.co/stickers/happiness-low.lottie',
  hunger: 'https://cdn.notwise.co/stickers/hunger.lottie',
  health: 'https://cdn.notwise.co/stickers/hp-low.lottie',
};

export const UpdateBarWidget = () => {
  const [newValue, setNewValue] = useState<any>(null);
  const [isShown, setIsShown] = useState<any>(false);
  const endurance = useUserStore((state) => state.user.endurance);
  const gameMode = useGameStore((state) => state.gameMode);
  const hunger = useUserStore((state) => state.user.hunger);
  const happiness = useUserStore((state) => state.user.happiness);
  const health = useUserStore((state) => state.user.health);
  const widgetRef = useRef<HTMLDivElement | null>(null);
  const updateItemImg = newValue?.modifier ? UpdateIcons[newValue?.modifier] : '';
  const maxHp = 20 + endurance * 3;
  const prevValues = useRef({
    hunger: hunger,
    happiness: happiness,
    health: health,
  });
  useEffect(() => {
    if (gameMode !== 'normal')  {
      const changedValues = [];
      if (hunger > prevValues.current.hunger) {
        setNewValue({"modifier": "hunger", "amount": hunger})
        changedValues.push({key: "hunger", oldValue: prevValues.current.hunger, newValue: hunger});
      }
      if (happiness > prevValues.current.happiness) {
        setNewValue({"modifier": "happiness", "amount": happiness})
        changedValues.push({key: "happiness", oldValue: prevValues.current.happiness, newValue: happiness});
      }
      if (health > prevValues.current.health) {
        setNewValue({"modifier": "health", "amount": health})
        changedValues.push({key: "health", oldValue: prevValues.current.health, newValue: health});
      }

      // Log the changed values
      if (changedValues.length > 0) {
        console.log("Changed values:", changedValues);
      }
      const isHealthInit = prevValues.current.health === 0;
      prevValues.current = {hunger, happiness, health};
      if (changedValues.length === 1 && !isHealthInit) {
        gsap.set(widgetRef.current, {opacity: 1, y: 0});
        const timer = setTimeout(() => {
          if (widgetRef.current) {
            gsap.to(widgetRef.current, {
              opacity: 0,
              y: -20,
              duration: 0.3,
            });
          }
        }, 3000);
        return () => clearTimeout(timer);
      }
    }
  }, [hunger, happiness, health, gameMode]);

  return (
    <>
      <div
        ref={widgetRef}
        className="absolute flex w-6/12 webapp-safe-top top-40 left-1/2 -translate-x-1/2 items-center justify-between bg-white/[.3] p-1 px-3 rounded-full opacity-0 gap-2"
      >
        {updateItemImg && <div className="w-[26px] h-[26px] mt-1">
          <LottiePlayer lottieUrl={updateItemImg} isLoop={true} preloaderStyle={'round'} />
        </div>}
        <Progressbar
          winking={false}
          barcolor="bg-white"
          blurred={false}
          ht="min-h-3"
          percent="w"
          spark={false}
          value={newValue?.amount}
          maxvalue={newValue?.modifier === "health" ? maxHp : 20}
          customStyle={{ width: `${newValue?.modifier === "health" ? Round((newValue?.amount / maxHp) * 100, 1) : Round((newValue?.amount / 20) * 100, 1)}%` }}
        />
      </div>
    </>
  );
};
