import React, { useMemo } from 'react';
import { LottiePlayer } from '../../components';

type Achievement = {
  id: number;
  name: string;
  img: string;
  short_descr: string;
};

type AchievementsGridProps = {
  achievements: Achievement[];
  userAchievements: Array<number>;
};

const AchievementsGrid: React.FC<AchievementsGridProps> = React.memo(
    ({ achievements, userAchievements }) => {
      // Create a set for O(1) lookups
      const userAchievementsSet = useMemo(
          () => new Set(userAchievements),
          [userAchievements]
      );

      // Map over achievements, checking membership in the set
      const renderedAchievements = useMemo(() => {
        return achievements.map(({ id, name, img, short_descr }) => {
          const isBlocked = !userAchievementsSet.has(id);
          return (
              <div key={id} className="achievement-item">
                <LottiePlayer
                    preloaderStyle="rounded"
                    lottieUrl={img}
                    isLocked={isBlocked}
                    isLoop={true}
                    lockedReason={short_descr}
                />
              </div>
          );
        });
      }, [achievements, userAchievementsSet]);

      return <div className="grid grid-cols-3 gap-2">{renderedAchievements}</div>;
    }
);

export default AchievementsGrid;
