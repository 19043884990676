import styled from 'styled-components';
import React, { Suspense, useState } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useWalletStore } from '../../store';
import {MainButton} from "../../components";
import {useNavigate} from "react-router-dom";
import {useTelegramBackButton} from "../../hooks/useTelegramBackButton";

const StoriesLazy = React.lazy(() => import('react-insta-stories'));
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100dvh;
`;

function Story1({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#055bc6] to-[#070300]">
      <div className="mx-3 webapp-safe-top text-3xl uppercase font-bold text-white text-center"><div className={"mt-10"}>Grow & pet your pet</div></div>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/1.png" />
      </div>
    </div>
  );
}
function Story2({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#2e0707] to-[#070300]">
      <div className="mx-3 webapp-safe-top text-3xl uppercase font-bold text-white text-center"><div className={"mt-10"}>Fight or hide</div></div>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/2.png" />
      </div>
    </div>
  );
}
function Story3({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#ffbc1a] to-[#070300]">
      <div className="mx-3 webapp-safe-top text-3xl uppercase font-bold text-white text-center"><div className={"mt-10"}>Participate in contests</div></div>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/3.png" />
      </div>
    </div>
  );
}
function Story4({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#265f14] to-[#070300]">
      <div className="mx-3 webapp-safe-top text-3xl uppercase font-bold text-white text-center"><div className={"mt-10"}>Invite Frens</div></div>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/4.png" />
      </div>
    </div>
  );
}
function Story5({ action, isPaused }: { action: any; isPaused: boolean }) {
  return (
    <div className="h-full w-full flex flex-col justify-between bg-gradient-to-t from-[#f99f1e] to-[#070300]">
      <div className="mx-3 webapp-safe-top text-3xl uppercase font-bold text-white text-center"><div className={"mt-10"}>Earn Notcoin</div></div>
      <div className="pb-20">
        <img alt="pet" src="https://notwise.b-cdn.net/images/stories/5.png" />
      </div>
    </div>
  );
}

const stories2 = [
  {
    content: Story1,
  },
  {
    content: Story2,
  },
  {
    content: Story3,
  },
  {
    content: Story4,
  },
  {
    content: Story5,
  },
];

export const Stories = () => {
  useTelegramBackButton('/earn');
  const navigate = useNavigate();
  const wallet = useWalletStore((state) => state.wallet);
  const [tonConnectUI] = useTonConnectUI();
  const [notInstallTelegram] = useState<boolean>(false);

  const handleWallet = async () => {
    await (wallet.connected ? tonConnectUI.disconnect() : tonConnectUI.openModal());
  };

  return (
    <Wrapper className={'bg-[#080D1B]'}>
      <Suspense>
        <StoriesLazy
          preloadCount={3}
          loop
          keyboardNavigation
          defaultInterval={8000}
          stories={stories2}
          width="100%"
          height="100%"
          onStoryEnd={(s: any, st: any) => console.log('story ended', s, st)}
          onAllStoriesEnd={(s: any, st: any) => console.log('all stories ended', s, st)}
          onStoryStart={(s: any, st: any) => console.log('story started', s, st)}
          onNext={() => console.log('next button pressed')}
          onPrevious={() => console.log('previous button pressed')}
          storyContainerStyles={{ overflow: 'hidden', pointerEvents: 'auto',  }}
          progressWrapperStyles={{marginTop: 'calc(var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top))'}}
        />
      </Suspense>
      <div
        className="flex flex-col justify-center items-center my-10 w-full absolute bottom-0 px-6"
        style={{ zIndex: 1000 }}
      >
        <MainButton isLoading={false} btnText={'Ok, go back'} onClick={()=>{navigate('/')}}></MainButton>
      </div>
    </Wrapper>
  );
};
