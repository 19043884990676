import { useQuery } from './useQuery';

export type EnemyInfo = {
  username: string;
  avatar: string;
  skin: {
    id: number;
    type: string;
    name: string;
    price: number;
    description: string;
    decoration_type?: string;
    img: string;
    traits?: unknown;
    is_visible: boolean;
  };
  max_hp: number;
  current_hp: number;
}

type Input = {
  id: string;
};

export const useGetEnemyInfo = ({ id }: Input) => {
  const { data, ...rest } = useQuery<EnemyInfo>({
    url: `getEnemyInfo/${id}/`,
    method: 'get',
  });

  return { userInfo: data, ...rest };
};
