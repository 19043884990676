import * as THREE from 'three';
import { getPlatformData } from './getPlatformData';
import { convertMaterialType } from './convertMaterialType';

export const optimiseMaterial = (material: THREE.Material): THREE.Material  => {
  const { performanceClass } = getPlatformData();

  if (performanceClass === 'AVERAGE') {
    return convertMaterialType({
      material,
      type: 'standard',
    });
  }

  if (performanceClass === 'LOW') {
    return convertMaterialType({
      material,
      type: 'lambert',
    });
  }

  return material;
};
