import { Navigate, Route, Routes } from 'react-router-dom';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import toast, { Toaster } from 'react-hot-toast';
import {GameSceneNew, LoadingProvider, TonConnectProvider, UpdateBarWidget} from './components';
import {
  Earn,
  Fight,
  FrenInfo,
  Frens,
  Home,
  InventoryItemDetails,
  LeaderBird,
  LeaderBoard,
  Profile,
  Squad,
  SquadList,
  Store,
  StoreItemDetails,
  Stories,
  Tree,
} from './pages';
import { WebSocketProvider } from './components/WebSocketContext';
import { BlockingOverlay } from './components/BlockingOverlay';
import { useCharacterParams } from './hooks/useCharacterParams';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './fonts.css';

const App = () => {
  useCharacterParams();
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: () => {
        toast.error('Something went wrong!', {
          style: {
            borderRadius: '30px',
            background: '#090e1de3',
            color: '#fff',

          },
        });
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <TonConnectUIProvider
        manifestUrl="https://node1.irys.xyz/6jjdY6x_PUMEouHDDquPBalqKNU__bpecDp-erCKy5k"
        uiPreferences={{ theme: THEME.DARK }}
      >
        <div className="App w-screen h-screen select-none">
          <TonConnectProvider>
            <BlockingOverlay>
            <LoadingProvider>
              <WebSocketProvider>
                <GameSceneNew />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/stories" element={<Stories />} />
                  <Route path="/squad" element={<Squad />} />
                  <Route path="/squad-list" element={<SquadList />} />
                  <Route path="/squad/:id" element={<LeaderBoard />} />
                  <Route path="/leaderbird" element={<LeaderBird />} />
                  <Route path="/fight" element={<Fight />} />
                  <Route path="/fight-tree" element={<Tree />} />
                  <Route path="/store" element={<Store />} />
                  <Route path="/store/:id" element={<StoreItemDetails />} />
                  <Route path="/frens" element={<Frens />} />
                  <Route path="/earn" element={<Earn />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/inventory/details/:id" element={<InventoryItemDetails />} />
                  <Route path="/freninfo/:id" element={<FrenInfo />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </WebSocketProvider>
            </LoadingProvider>
            </BlockingOverlay>
          </TonConnectProvider>
          <UpdateBarWidget/>
          <Toaster containerClassName={'webapp-safe-top'} />
        </div>
      </TonConnectUIProvider>
    </QueryClientProvider>
  );
};

export default App;
