import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';

interface Block {
  id: number;
  x: number;
  y: number;
  amount: number;
}

export const OverflowerText = () => {
  const expIncRef = useRef<HTMLDivElement>(null);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleAddExp = (e: any) => {
    const newBlock = {
      id: Date.now(),
      x: e.detail?.x,
      y: e.detail?.y,
      amount: e.detail?.amount,
    };
    setBlocks([...blocks, newBlock]);
  };

  useEffect(() => {
    document.addEventListener('addExp', handleAddExp);
    return () => {
      document.removeEventListener('addExp', handleAddExp);
    };
  }, [blocks]); // добавил зависимость для корректного поведения useEffect

  /* GSAP */
  const animExp = () => {
    if (expIncRef.current == null) return;
    const lastChild = expIncRef.current?.lastChild as HTMLElement;
    if (lastChild) {
      gsap.to(lastChild, { y: -200, opacity: 0, duration: 1.5 }); // заменил alpha на opacity
    }
  };

  useEffect(() => {
    if (blocks.length === 0) return;
    animExp();
  }, [blocks]);

  useEffect(() => {
    if (blocks.length === 0) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setBlocks([]);
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [blocks]);

  return (
    <div ref={expIncRef}>
      {blocks.map((item, index) => (
        <div
          className="absolute w-max text-xl text-white font-bold break-keep"
          style={{ left: item.x - 30, top: item.y - 50 }}
          key={index}
        >
          {item.amount > 0 ? `+${item.amount}` : 0} EXP
        </div>
      ))}
    </div>
  );
};
