import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useUserStore } from '../../store';
import toast from 'react-hot-toast';
import { Tabs } from './Tabs';
import { Fren } from './Fren';
import { useGetFrens } from '../../api';
import Starfield from '../../components/Starfield';
import { LottiePlayer } from '../../components';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import { ReferralScheme } from './ReferralScheme';

const INVITATION_MESSAGE = `Hey, Fren 👀
Let's play NOT Wise together!`;

export const Frens = () => {
  const webApp = window.Telegram.WebApp;
  const userInfo = useUserStore((state) => state.user);
  const refUrl = `https://t.me/stop_testing_on_production_bot/nwtest?startapp=${userInfo.tg_id}`;
  const { data, isLoading } = useGetFrens({ staleTime: Infinity });
  const [ activeTabIdx, setActiveTabIdx ] = useState(0);

  useTelegramBackButton('/');

  const copyReferalLink = () => {
    navigator.clipboard.writeText(refUrl);
    toast.success('Link has been copied', {
      icon: '🔗',
      style: {
        borderRadius: '30px',
        background: '#090e1de3',
        color: '#fff',
      },
    });
  };

  const inviteFrens = () => {
    const redirectUrl = `https://t.me/share/url?url=${refUrl}&text=${INVITATION_MESSAGE}`;

    webApp.openTelegramLink(redirectUrl)
  };

  const hasFrens = userInfo.hasFrens;
  const frens = data?.frens;
  const friendsList = useMemo(() => (isLoading || !frens) ? (
    [1, 2, 3].map((_, idx) => <Fren key={idx} isSkeleton />)
  ) : (
    frens?.map((fren, idx) => <Fren key={fren.tg_id} {...fren} />)
  ), [frens, isLoading]);

  const tabs: Array<{
    title: string;
    isActive: boolean;
    component: JSX.Element;
  }> = [
    {
      title: `${frens?.length ?? 'No'} Frens`,
      isActive: activeTabIdx === 0,
      component: (
        <>
          <FrensListHeaders>
            <div>Name</div>
            <div>Earned</div>
          </FrensListHeaders>
          <FrensListBody>
            {friendsList}
          </FrensListBody>
        </>
      )
    },
    {
      title: 'How it works',
      isActive: activeTabIdx === 1,
      component: (
        <ReferralScheme />
      )
    },
  ];

  return (
    <Container>
      <Starfield amount={40} />
      <Content className="webapp-safe-top">
        <HeadingIcon>
          <LottiePlayer
            lottieUrl="https://cdn.notwise.co/stickers/Coin.lottie"
            isLoop={false}
            preloaderStyle="round"
          />
        </HeadingIcon>
        <HeadingLabelBig>
          Invite frens 
        </HeadingLabelBig>
        <HeadingLabelSmall>
          <div>
            Earn more Wise Coins
          </div>
          <div>
            when your referrals level up
          </div>
        </HeadingLabelSmall>
        {!hasFrens && tabs[1].component}
        {hasFrens && (
          <TabsContainer>
            <Tabs
              items={tabs}
              onSelect={setActiveTabIdx}
            />
            <TabsOutlet>
              {tabs[activeTabIdx].component}
            </TabsOutlet>
          </TabsContainer>
        )}
      </Content>

      <Buttons>
        <Button
          $primary
          onClick={copyReferalLink}
        >
          Copy referral link
        </Button>
        <Button
          onClick={inviteFrens}
        >
          Invite frens
        </Button>
      </Buttons>
    </Container>
  );
};

const Button = styled.button<{ $primary?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.875rem 1.25rem;
  color: ${({ $primary }) => ($primary ? '#ffffff' : '#000000')};
  font-weight: 600;
  text-align: center;
  background: ${({ $primary }) => ($primary ? '#33333388' : '#ffffff')};
  backdrop-filter: blur(10px);
  pointer-events: auto;

  &:active {
    background: #a2a2a2${({ $primary }) => ($primary ? '14' : 'ff')};
  }
`;

const Buttons = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 0;
  bottom: 3.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  width: 100%;
  z-index: 8;
  pointer-events: none;
`;

const FrensListBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
  padding-bottom: 12rem;
`;

const FrensListHeaders = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #E3EAF699;
  margin-bottom: 20px;
`;

const TabsOutlet = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  margin-top: 24px;
  overflow: hidden;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 1;
  padding: 1.25rem;
  padding-top: 0;
  padding-bottom: 1.25rem;
  overflow: hidden;
`;

const HeadingLabelSmall = styled.div`
  text-align: center;
  margin-top: 4px;
  margin-bottom: 24px;
  color: #E3EAF699;
`;

const HeadingLabelBig = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 12px;
`;

const HeadingIcon = styled.div`
  position: relative;
  margin: 0 auto;
  width: 68px;
  height: 68px;
  margin-top: 24px;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 48px;
  color: #ffffff;
  z-index: 7;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  pointer-events: auto;
  scrollbar-width: none;
  background: #080D1B;
  overflow: hidden;
  z-index: 5;

  &::after {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 252px;
    height: 204px;
    background: #513ba738;
    border-radius: 50%;
    filter: blur(60px);
    pointer-events: none;
    content: '';
    z-index: 6;
  }
`;
