import { REACT_APP_BACKEND_URL } from '../config';
import axios from 'axios';
import { useUserStore } from '../store';
import { User } from '@sentry/react';

export type ActionType = 'click' | 'hold' | 'swipe';

export type ActionNumber = Partial<Record<'clicks' | 'holds' | 'swipes', number>>;

export type Mode = 'egg' | 'owl' | 'dummy';

export type Action = {
  temperature: number | null;
  start: string;
  end: string;
} & ActionNumber;

type Input = {
  type: ActionType;
  mode: Mode;
  item_id?: number;
  token: string;
  actions: Array<Action>;
  callback?: () => void;
};

export const performAction = ({ token, callback, ...data }: Input) => {
  try {
    axios({
      method: 'post',
      url: `${REACT_APP_BACKEND_URL}/userAction/`,
      data,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(
        (response: {
          // FIXME Inconsistent naming between BE & FE
          data: User & {
            exp: number;
            last_holding: string;
            last_feeding: string;
          };
        }) => {
          const { data } = response;

          if (data) {
            useUserStore.setState((state) => ({
              ...state,
              user: {
                ...state.user,
                totalExp: data.exp,
                energy: data.energy,
                coins: data.coins,
                hunger: data.hunger,
                happiness: data.happiness,
                health: data.health,
                temperature: data.temperature,
                lastHeating: data.last_holding,
                lastEnergyUsed: data.last_energy_used,
                lastFeeding: data.last_feeding,
                lastSwiping: data.last_swiping,
                lastHealing: data.last_hp_used,
              },
            }));
          }
        },
      )
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  } catch (error) {
    console.error(error);
  }
};
