import { STAGES } from '../config';
import { useGameStore } from '../store';
import { useCallback } from 'react';

export const useNavigateByExp = () => {
  const { setGameStage, foreignOwl, battleState } = useGameStore();

  return useCallback(
    (experience: number) => {
      if (foreignOwl) {
        // NOTE Avoid auto-navigation when viewing friendly owls
        return;
      }

      if (battleState?.uuid) {
        // NOTE Prevent auto-navigation when jumping to the battle view
        return;
      }

      if (experience >= STAGES[3]) {
        if (setGameStage) {
          setGameStage(3);
        }
      } else if (experience >= STAGES[2]) {
        if (setGameStage) {
          setGameStage(2);
        }
      } else if (experience >= 0) {
        if (setGameStage) {
          setGameStage(1);
        }
      }
    },
    [setGameStage, foreignOwl, battleState?.uuid],
  );
};
