import styled from "styled-components";
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGameStore } from "../../../store";
import { OwlStats } from "./OwlStats";
import { FightResult } from "../FightResult";
import { ANIMATIONS_GRACE_PERIOD_MS, ROUND_DURATION_MS } from "../../GameSceneNew/hooks/useOwlBattleService";

export const FightOverlay = () => {
  const { battleState, battleSpoils } = useGameStore();
  const [ showFightResult, setShowFightResult ] = useState(false);
  const [roundStart, setRoundStart] = useState<boolean>(false);
  const roundLabelIdRef = useRef(0);
  const [roundLabel, setRoundLabel] = useState<JSX.Element | string | null>(null);

  const roundDeadline = useMemo(() => {
    if (!battleState || !battleState?.uuid) {
      return 0.0;
    }

    const countdown = Math.abs(battleState?.deadline - Date.now());

    if (countdown > ROUND_DURATION_MS) {
      setRoundStart(false);

      setTimeout(() => {
        if (battleState.player1.health <= 0 || battleState.player2.health <= 0) {
          return;
        }

        setRoundStart(true);
      }, countdown - ROUND_DURATION_MS);

      return ROUND_DURATION_MS;
    } else {
      return ROUND_DURATION_MS;
    }
  }, [battleState?.uuid, battleState?.round]);

  useEffect(() => {
    if (!battleState?.uuid || !battleState) {
      setRoundLabel(null);
      roundLabelIdRef.current++;

      return;
    }


    if (battleState.player1.health <= 0 && battleState.player2.health <= 0) {
      setTimeout(() => {
      setRoundLabel('Draw!');
      roundLabelIdRef.current++;
      }, 3000);
      return;
    } else if (battleState.player1.health <= 0) {
      setTimeout(() => {
        setRoundLabel('Defeat!');
        roundLabelIdRef.current++;
      }, 3000);

      return;
    } else if (battleState.player2.health <= 0) {
      setTimeout(() => {
      setRoundLabel('Victory!');
      roundLabelIdRef.current++;
      }, 3000);
      return;
    }

    if (battleState.round === 0) {
      setRoundLabel('Get Ready!');
      roundLabelIdRef.current++;
    } else {
      if (battleState?.player2.damage > 0) {
        setRoundLabel(<><FriendlyHighlight>Your attack</FriendlyHighlight> pierces enemy defense!</>);
      } else {
        setRoundLabel(<><FriendlyHighlight>Your attack</FriendlyHighlight> is blocked!</>);
      }

      roundLabelIdRef.current++;

      setTimeout(() => {
        if (battleState?.player1.damage > 0) {
          setRoundLabel(<><EnemyHighlight>Enemy attack</EnemyHighlight> pierces your defense!</>);
        } else {
          setRoundLabel(<><EnemyHighlight>Enemy attack</EnemyHighlight> is blocked!</>);
        }

        roundLabelIdRef.current++;

        setTimeout(() => {
          setRoundLabel(`Round ${battleState.round + 1}`);

          roundLabelIdRef.current++;
        }, ANIMATIONS_GRACE_PERIOD_MS / 3.0);
      }, ANIMATIONS_GRACE_PERIOD_MS / 3.0);
    }
  }, [battleState?.uuid, battleState?.round, roundDeadline]);

  useEffect(() => {
    if (battleSpoils && !showFightResult) {
      setShowFightResult(true);
    }
  }, [battleSpoils]);

  if (!battleState?.uuid || !battleState) {
    return null;
  }

  if (showFightResult) {
    return <FightResult />;
  }

  return (
    <Container>
      <FightBarContainer>
        <OwlStats owl={battleState?.player1 ?? null} />
        <FightBarDivider $pending={!battleState?.player1 || !battleState?.player2} />
        <OwlStats owl={battleState?.player2 ?? null} />
      </FightBarContainer>

      {!roundStart && roundLabel && (
        <RoundLabelContainer key={roundLabelIdRef.current}>
          <RoundLabel>
            {roundLabel}
          </RoundLabel>
        </RoundLabelContainer>
      )}
      {roundStart && (
        <TimerContainer>
          <TimerDescription>
            Make your attack<br/>
            and defense moves!
          </TimerDescription>
          <TimerProgress
            key={battleState?.round}
            $timeMs={roundDeadline}
          >
            <TimerHighlight $visible />
          </TimerProgress>
          <TimerLabel>
            {(roundDeadline / 1000.0).toFixed(0)}s
          </TimerLabel>
        </TimerContainer>
      )}
    </Container>
  );
};

const RoundLabel = styled.div`
  font-size: 1.25rem;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
`;

const FriendlyHighlight = styled.span`
  color: #00cc00;
  text-shadow: -1px 0px 1px #000,
    0px -1px 1px #000,
    1px 0px 1px #000,
    0px 1px 1px #000;
`;

const EnemyHighlight = styled.span`
  color: #cc0000;

  text-shadow: -1px 0px 1px #000,
    0px -1px 1px #000,
    1px 0px 1px #000,
    0px 1px 1px #000;
`;

const FightBarDivider = styled.div<{
  $pending: boolean;
}>`
  flex-shrink: 1;
  width: 32px;
  height: 32px;
  background-image: url(./assets/images/fight-vs.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: filter 0.3s, opacity 0.3s;

  ${({ $pending }) => $pending ? `
    filter: grayscale(100%);
    opacity: 0.5;
  ` : `
    filter: grayscale(0%);
    opacity: 1.0;
  `}
`;

const FightBarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px;
  margin-top: calc(var(--tg-safe-area-inset-top) + var(--tg-content-safe-area-inset-top));
`;

const RoundLabelContainer = styled.div<{
  $labelDelayMs?: number;
}>`
  @keyframes RoundLabelPopIn {
    0% {
      transform: translateX(-50%) translateY(-50%) scale(0);
      opacity: 0;
    }

    90% {
      transform: translateX(-50%) translateY(-50%) scale(1.2);
      opacity: 1;
    }

    100% {
      transform: translateX(-50%) translateY(-50%) scale(1);
      opacity: 1;
    }
  }

  position: absolute;
  bottom: 150px;
  left: 50%;
  min-width: 300px;
  text-align: center;
  white-space: nowrap;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  animation: RoundLabelPopIn .3s ${({ $labelDelayMs }) => $labelDelayMs ? `${($labelDelayMs / 1000.0).toFixed(2)}s` : ''} ease both alternate 1;
`;

const TimerDescription = styled.div`
  position: absolute;
  width: 400px;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 1.25rem;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
`;

const TimerLabel = styled.div`
  position: absolute;
  top: 50%;
  right: -36px;
  font-weight: 500;
  transform: translate(0, -50%);
  color: #ffffff;
  font-size: 14px;
`;

const TimerHighlight = styled.div<{
  $visible: boolean;
}>`
  @keyframes TimerHighlightFlicker {
    0% { opacity: 1.0; }
    10% { opacity: 0.82; }
    20% { opacity: 0.87; }
    30% { opacity: 0.85; }
    40% { opacity: 0.89; }
    50% { opacity: 0.81; }
    60% { opacity: 0.84; }
    70% { opacity: 0.81; }
    80% { opacity: 0.88; }
    90% { opacity: 0.82; }
  }

  position: absolute;
  top: 50%;
  right: -2px;
  transform: translate(0, -50%);
  width: 11px;
  height: 11px;
  background: #ffffff;
  border-radius: 50%;
  filter: blur(5px);
  z-index: 2;
  animation: TimerHighlightFlicker .6s infinite steps(2);
`;

const TimerProgress = styled.div<{
  $timeMs: number;
}>`
  @keyframes TimerProgress {
    from {
      width: 100%;
    }

    to {
      width: 0;
    }
  }

  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  animation: TimerProgress ${({ $timeMs }) => $timeMs / 1000.0}s linear both alternate 1;
`;

const TimerContainer = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 8px;
  border-radius: 4px;
  background-color: #ffffff44;
  color: #ffffff;
  z-index: 1;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;
