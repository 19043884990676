import { Howler, Howl } from 'howler';

Howler.autoUnlock = true;
Howler.volume(0.0);

const createAudioTrack = (
  name: string,
  preloadLongFiles: boolean = false
) => {
  const track = new Howl({
    src: [
      `/assets/sounds/${name}.webm`,
      `/assets/sounds/${name}.mp3`
    ],
    format: [
      'webm',
      'mp3'
    ],
    autoplay: false,
    preload: true,
    html5: false,
    loop: true,
    pool: 1,
  });

  track.once('loaderror', () => {
    track.unload();
  });

  track.once('playerror', () => {
    track.unload();
  });

  return track;
};

export const audioTracks = {
  'ambient-battle': createAudioTrack('ambient-battle', true),
  'ambient-birds-chirp': createAudioTrack('ambient-birds-chirp', true),
  'ambient-theme': createAudioTrack('ambient-theme', true),
  'sfx-victory': createAudioTrack('sfx-victory'),
  'sfx-defeat': createAudioTrack('sfx-defeat'),
  'sfx-draw': createAudioTrack('sfx-draw'),
  'sfx-evade': createAudioTrack('sfx-evade'),
  'sfx-heating': createAudioTrack('sfx-heating'),
  'sfx-hit-1': createAudioTrack('sfx-hit-1'),
  'sfx-hit-2': createAudioTrack('sfx-hit-2'),
  'sfx-hit-3': createAudioTrack('sfx-hit-3'),
  'sfx-hit-4': createAudioTrack('sfx-hit-4'),
  'sfx-hit-dummy': createAudioTrack('sfx-hit-dummy'),
  'sfx-level-up': createAudioTrack('sfx-level-up'),
  'sfx-owl-happy-1': createAudioTrack('sfx-owl-happy-1'),
  'sfx-owl-happy-2': createAudioTrack('sfx-owl-happy-2'),
  'sfx-owl-happy-3': createAudioTrack('sfx-owl-happy-3'),
  'sfx-owl-happy-4': createAudioTrack('sfx-owl-happy-4'),
};

Howler.html5PoolSize = Object.keys(audioTracks).length * 2;

const latestStoppedTracks: Array<[ string, boolean ]> = [];

export const AudioSystemUtil = {
  mute: ()=> {
    Howler.volume(0.0);
  },
  unmute: () => {
    Howler.volume(0.5);
  },
  isMuted: () => {
    return Howler.volume() === 0.0;
  },
  play: (
    trackName: keyof typeof audioTracks,
    loop: boolean = false,
    volume: number = 0.5,
    rate: number = 1.0
  ) => {
    const track = audioTracks[trackName];

    track.once('playerror', (error) => {
      track.once('unlock', () => {
        track.play();
      });
    });

    track.loop(loop);
    track.volume(volume);
    track.rate(rate);

    if (loop) {
      track.fade(track.volume(), 0.5, 1000);
    } else {
      track.volume(volume);
    }

    if (
      !loop ||
      (loop && !track.playing())
    ) {
      track.play();
    }
  },
  stop: (
    trackName: keyof typeof audioTracks
  ) => {
    const track = audioTracks[trackName];

    track.fade(track.volume(), 0.0, 1000);
  },
  stopSome: async ({
    exclude
  }: {
    exclude: Array<keyof typeof audioTracks>
  }): Promise<void> => {
    const pendingFades: Promise<void>[] = [
      new Promise(resolve => setTimeout(resolve, 1000))
    ];

    for (const [trackName, track] of Object.entries(audioTracks)) {
      if (exclude.includes(trackName as keyof typeof audioTracks)) {
        continue;
      }

      if (track.playing() && track.volume() > 0.0) {
        pendingFades.push(new Promise(resolve => {
          track.once('fade', () => resolve());
        }));

        const trackName = Object.entries(audioTracks).find(
          ([_, value]) => {
            return value === track;
          }
        )?.[0] as keyof typeof audioTracks;

        if (trackName) {
          latestStoppedTracks.push([trackName, track.loop()]);
        }

        track.fade(track.volume(), 0.0, 100);
      }
    }

    if (!pendingFades.length) {
      return Promise.resolve();
    }

    // @ts-ignore
    return Promise.allSettled(pendingFades);
  }
};
