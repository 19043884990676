import { useCallback, useEffect, useRef } from 'react';
import { useUserStore } from '../store';

const UPDATE_INTERVAL = 60000;

export const useHappiness = () => {
  const lastSwiping = useUserStore((state) => state.user.lastSwiping);
  const happiness = useUserStore((state) => state.user.happiness);
  const isZeroHappiness = happiness === 0;
  const isHappinessFetched = happiness !== null;

  const decreaseHappiness = useUserStore((state) => state.decreaseHappiness);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const updateHappiness = useCallback(() => {
    const happinessToDecrease = 1;
    decreaseHappiness(happinessToDecrease);
  }, [decreaseHappiness]);

  const clearCurrentInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (isZeroHappiness || !isHappinessFetched) {
      clearCurrentInterval();
      return;
    }

    const fetchedLastSwipingDate = lastSwiping ? new Date(lastSwiping) : null;

    const remainder = fetchedLastSwipingDate ? (Date.now() - fetchedLastSwipingDate.getTime()) % UPDATE_INTERVAL : null;

    const delay = remainder === null ? null : UPDATE_INTERVAL - remainder;

    const startInterval = () => {
      clearCurrentInterval();

      intervalRef.current = setInterval(updateHappiness, UPDATE_INTERVAL);
    };

    if (!timeoutRef.current && delay !== null) {
      timeoutRef.current = setTimeout(() => {
        updateHappiness();
        startInterval();
      }, delay);
    } else {
      startInterval();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [updateHappiness, lastSwiping, isHappinessFetched, isZeroHappiness]);
};
