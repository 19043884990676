import { useGetUser } from '../api';
import { useGameStore, useUserStore } from '../store';
import { useEffect, useRef } from 'react';
import { useGetInventoryQuery } from '../api/useGetInventoryQuery';
import { useNavigate } from 'react-router';
import { useAuth } from './useAuth';
import { STAGES } from '../config';

export const useUserInitializer = () => {
  const navigate = useNavigate();

  const isJoiningSquad = useUserStore((state) => state.isJoiningSquad);
  const setIsJoiningSquad = useUserStore((state) => state.setIsJoiningSquad);

  const setUserInfo = useUserStore((state) => state.setUser);
  const user = useUserStore((state) => state.user);

  const { userInfo, refetch: refetchUser } = useGetUser();
  const { inventory, equipped } = useGetInventoryQuery();
  const { battleSpoils } = useGameStore();
  const setGameMode = useGameStore((state) => state.setGameMode);
  const setGameStage = useGameStore((state) => state.setGameStage);
  const currentBattleLockRef = useRef(false);

  useAuth();
  const getCurrentStage = (exp: number): number => {
    let currentStage = 1;

    for (const stage in STAGES) {
      if (exp >= STAGES[stage]) {
        currentStage = parseInt(stage, 10);
      } else {
        break;
      }
    }

    return currentStage;
  };

  useEffect(() => {
    if (!userInfo || !inventory) {
      return;
    }

    const updatedUserInfo = {
      cola_used: userInfo.cola_used,
      achievements: userInfo.achievements as number[],
      totalExp: userInfo.experience,
      avatar: userInfo.avatar,
      temperature: userInfo.temperature,
      energy: userInfo.energy,
      level: userInfo.level,
      skillPoints: userInfo.skill_points,
      coins: userInfo.coins,
      strength: userInfo.strength,
      agility: userInfo.agility,
      wisdom: userInfo.wisdom,
      endurance: userInfo.endurance,
      hunger: userInfo.hunger,
      tg_id: userInfo.tg_id,
      username: userInfo.username ? userInfo.username : null,
      isPremium: userInfo.is_premium,
      lang: userInfo.lang,
      inventory: inventory?.map((item: any) => ({
        good: item.good,
        amount: item.amount,
        isEquipped: item.is_equipped,
      })),
      equipped: equipped?.map((item: any) => ({
        good: item.good,
        amount: item.amount,
        isEquipped: item.is_equipped,
      })),
      rank: userInfo.rank,
      health: userInfo.health,
      happiness: userInfo.happiness,
      lastHeating: userInfo.last_holding,
      lastFeeding: userInfo.last_feeding,
      lastHealing: userInfo.last_hp_used,
      lastSwiping: userInfo.last_swiping,
      lastEnergyUsed: userInfo.last_energy_used,
      squad: userInfo.squad,
      current_battle: userInfo.current_battle ?? null,
      hasFrens: userInfo.has_frens,
    };

    if (JSON.stringify(user) === JSON.stringify(updatedUserInfo)) {
      return;
    }

    setUserInfo(updatedUserInfo);
    const stage = getCurrentStage(updatedUserInfo.totalExp);
    if (updatedUserInfo.current_battle && !currentBattleLockRef.current) {
      currentBattleLockRef.current = true;
      setGameStage(stage);

      setGameMode('battle');
      navigate('/fight');
    } else {
      if (setGameStage) {
        setGameStage(stage);
      }
    }
  }, [inventory, setUserInfo, userInfo]);

  useEffect(() => {
    if (!battleSpoils) {
      return;
    }

    refetchUser();
  }, [battleSpoils]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!user.tg_id) {
        return;
      }

      if (document.visibilityState === 'visible') {
        refetchUser();
      }

      setIsJoiningSquad(false);
    };

    window.addEventListener('focus', () => {
      if (isJoiningSquad) {
        handleVisibilityChange();
      }
    });

    return () => {
      document.removeEventListener('focus', handleVisibilityChange);
    };
  }, [isJoiningSquad, refetchUser, setIsJoiningSquad, user.tg_id]);
};
