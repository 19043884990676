import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import { useGameStore, useUserStore } from '../../store';
import { useNavigateByExp } from '../../hooks';
import { Canvas, RootState } from '@react-three/fiber';
import { STAGES } from '../../config';
import { Scene } from './components/Scene';
import {useAssetsPreloader} from './hooks/useAssetsPreloader';
import { usePrecompileRenderer } from './hooks/useGLTF';
import { getViewByGameMode } from './views';
import { useOwlBattleService } from './hooks/useOwlBattleService';
import { Layer } from './components/composition/Layer';
import { useWebSocketContext } from '../WebSocketContext';
import { useNavigate } from 'react-router';
import { useAchievementListener } from '../../hooks/useAchievementListener';
import {useErrorListener} from "../../hooks/useErrorListener";
import { getPlatformData } from './utils/getPlatformData';

export const GameSceneNew = () => {
  const navigate = useNavigate();
  const { setCurrentPointerCoords, gameMode, setGameMode, setGameStage } = useGameStore();
  const stage = useGameStore((state) => state.gameStage);
  const activeView = getViewByGameMode(gameMode);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const webSocketContext = useWebSocketContext();
  const { performanceClass } = getPlatformData();

  useAssetsPreloader({viewId: activeView});
  useNavigateByExp();
  usePrecompileRenderer(rendererRef.current);
  useOwlBattleService();
  useAchievementListener();
  useErrorListener();

  const totalExp = useUserStore((state) => state.user.totalExp);

  useEffect(() => {
    if (totalExp >= STAGES[stage + 1]) {
      setGameStage(STAGES[stage + 1]);
    }
  }, [totalExp]);

  useEffect(() => {
    if (!webSocketContext.lastMessage) {
      return;
    }

    const message = JSON.parse(webSocketContext.lastMessage.data);

    if (message.type === 'FIGHT_UPDATE' && gameMode !== 'battle') {
      setGameMode('battle');
      navigate('/fight');
    }
  }, [webSocketContext.lastMessage]);

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setCurrentPointerCoords({ x: e.touches[0].clientX, y: e.touches[0].clientY });
  };

  const onCreated = (state: RootState) => {
    rendererRef.current = state.gl;
  };

  return (
    <div className="h-dvh w-full z-0 absolute">
      <Canvas
        shadows={false}
        gl={{
          antialias: false,
          powerPreference: ['LOW', 'AVERAGE'].includes(performanceClass) ? 'low-power' : 'high-performance',
        }}
        onCreated={onCreated}
        onTouchMove={onTouchMove}
      >
        <Layer renderOrder={0}>
          <Scene />
          {/* <DebugScene /> */}
        </Layer>
        <Layer renderOrder={1} />
      </Canvas>
    </div>
  );
};
