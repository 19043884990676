import { StoreGoodType } from '../../types/interface';
import styled from 'styled-components';

export const storeGoodTypeTabMapping: Record<
  StoreGoodType | 'all',
  {
    imgSrc?: string;
    title: string;
  }
> = {
  all: {
    title: 'All',
  },
  food: {
    imgSrc: 'https://notwise.b-cdn.net/icons/criossant.png',
    title: 'Food',
  },
  decoration: {
    imgSrc: 'https://notwise.b-cdn.net/icons/ny.png',
    title: 'Decoration',
  },
  medicine: {
    imgSrc: 'https://notwise.b-cdn.net/icons/pill.png',
    title: 'Medicine',
  },
  skin: {
    imgSrc: 'https://notwise.b-cdn.net/icons/pill.png',
    title: 'Skin',
  },
  egg: {
    imgSrc: 'https://notwise.b-cdn.net/icons/egg.png',
    title: 'Eggs',
  },
};

const ItemType = styled.div<{
  $active: boolean
}>`
  padding: 6px 16px;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(9px);
  color: #fff;
  border: 1px solid ${(props) => (props.$active ? '#FFFFFF' : 'transparent')};
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  &:focus {
    outline: none;
  }
`;

export const StoreItemType = ({
  type,
  isActive,
  ...rest
}: {
  type: StoreGoodType | 'all';
  isActive?: boolean;
  [key: string]: any;
}) => {
  const item = storeGoodTypeTabMapping[type];
  const imgSrc = item?.imgSrc; // Check if imgSrc exists before using
  const title = item?.title || 'Unknown'; // Provide a fallback title if needed

  return (
    <ItemType
      key={type}
      className="pointer-events-auto flex items-center text-sm"
      $active={isActive ?? false}
      {...rest}
    >
      {imgSrc && (
        <img
          alt={title}
          src={imgSrc}
          className="w-4 h-auto mr-2"
        />
      )}
      {title}
    </ItemType>
  );
};
