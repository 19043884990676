import React from 'react';
import styled from 'styled-components';
import { useWebSocketContext } from './';
import { ReadyState } from 'react-use-websocket';

export const WebsocketIndicator: React.FC = () => {
  const { readyState } = useWebSocketContext();

  const getStatusLabel = (): string => {
    switch (readyState) {
      case ReadyState.CONNECTING:
        return 'Connecting...';
      case ReadyState.OPEN:
        return 'Connected';
      case ReadyState.CLOSING:
        return 'Closing...';
      case ReadyState.CLOSED:
        return 'Disconnected';
      default:
        return 'Unknown';
    }
  };

  const getStatusColor = (): string => {
    switch (readyState) {
      case ReadyState.CONNECTING:
        return '#0095ff';
      case ReadyState.OPEN:
        return '#5ee32c';
      case ReadyState.CLOSING:
        return '#ffae00';
      case ReadyState.CLOSED:
        return '#ff003b';
      default:
        return 'gray';
    }
  };

  return (
    <Container>
      <Indicator $color={getStatusColor()} />
      <Label>
        {getStatusLabel()}
      </Label>
    </Container>
  );
};

const Label = styled.div`
  display: inline-block;
  min-width: 120px;
  font-size: 10px;
  color: white;
  opacity: 0.3;
  white-space: nowrap;
`;

const Indicator = styled.div<{
  $color: string;
}>`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  opacity: 0.5;
`;

const Container = styled.div`
  position: absolute;
  bottom: 50%;
  right: 0;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  transform: translateX(50%) rotate(-90deg) translateY(-50%);
`;
