import { InventoryInfo } from '../types/interface';
import { useMutation } from './useMutation';

// 1. Define the new interface for the API response
interface InventoryResponse {
  inventory: InventoryInfo[];
  equipped: InventoryInfo[];
}

export const useGetInventory = () => {
  // 2. Update the generic type argument to reflect the new structure
  const { mutate, ...rest } = useMutation<InventoryResponse>({
    url: 'inventory/',
    method: 'get',
  });

  // 3. Return the mutate function (aliased as getInventory) and the rest of the mutation state
  return {
    getInventory: mutate,
    ...rest,
  };
};
