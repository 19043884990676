import { useEffect, useRef } from "react";
import { useWebSocketContext, WebSocketContext } from "../../WebSocketContext";
import { useGameStore, useUserStore, WebSocketBattleState } from "../../../store";
import { IUserinfo } from "../../../types/interface";
import { MarkerPosition } from "./owlControls/useBattleOwlControls";
import { REACT_APP_BACKEND_URL } from "../../../config";

export const ROUND_DURATION_MS = 3000;
export const ANIMATIONS_GRACE_PERIOD_MS = 5000;

type AdditionalUserBattleInfoResponse = {
  username: string;
  avatar: string | null;
  skin: {
    img: string;
  } | null;
  max_hp: number;
  current_hp: number;
  happiness: number;
};

export const startOwlMatchmaking = (
  context: WebSocketContext,
  userInfo: IUserinfo,
  difficulty?: 'easy' | 'medium' | 'hard'
) => {
  context.sendMessage(JSON.stringify({
    type: 'START_FIGHT',
    user_tgid: userInfo.tg_id,
    fight_type: difficulty ?? 'easy'
  }));
};

export const makeBattleChoice = (
  context: WebSocketContext,
  userInfo: IUserinfo,
  attack: MarkerPosition | null,
  defence: MarkerPosition | null
) => {
  context.sendMessage(JSON.stringify({
    type: 'FIGHT_CHOICE',
    user_tgid: userInfo.tg_id,
    attack,
    defence,
  }));
};

export const useOwlBattleService = () => {
  const { lastMessage } = useWebSocketContext();
  const {
    battleError,
    setBattleState,
    setBattleError,
    setIncomingBattleState,
    setBattleSpoils,
    setBattlePlayer1,
    setBattlePlayer2,
  } = useGameStore();
  const { user } = useUserStore();
  const useCurrentBattleAsSourceRef = useRef(true);

  const fetchAdditionalUsersInfo = (source: WebSocketBattleState) => {
    if (user?.current_battle) {
      // NOTE battlePlayer1 and battlePlayer2 can be read directly from current_battle
      return;
    }

    if (source.round !== 0) {
      return;
    }

    setBattlePlayer1(null);
    setBattlePlayer2(null);

    fetch(`${REACT_APP_BACKEND_URL}/getEnemyInfo/${source.player1.tgId}/`)
      .then(res => res.json())
      .then((data: AdditionalUserBattleInfoResponse) => {
        setBattlePlayer1({
          username: data.username ?? 'Anonymous',
          maxHp: data.max_hp,
          skin: data.skin ?? null,
          avatar: data.avatar ?? null,
          happiness: data.happiness ?? null,
        });
      })
      .catch(() => {});

    fetch(`${REACT_APP_BACKEND_URL}/getEnemyInfo/${source.player2.tgId}/`)
      .then(res => res.json())
      .then((data: AdditionalUserBattleInfoResponse) => {
        setBattlePlayer2({
          username: data.username ?? 'Anonymous',
          maxHp: data.max_hp,
          skin: data.skin ?? null,
          avatar: data.avatar ?? null,
          happiness: data.happiness ?? null,
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!useCurrentBattleAsSourceRef.current) {
      return;
    }

    setBattleState(user?.current_battle);
    setBattlePlayer1({
      username: user?.current_battle?.player1.username,
      maxHp: user?.current_battle?.player1.max_hp,
      skin: user?.current_battle?.player1.skin ?? null,
      avatar: user?.current_battle?.player1.avatar ?? null,
      happiness: user?.current_battle?.player1.happiness ?? null,
    });
    setBattlePlayer2({
      username: user?.current_battle?.player2.username,
      maxHp: user?.current_battle?.player2.max_hp,
      skin: user?.current_battle?.player2.skin ?? null,
      avatar: user?.current_battle?.player2.avatar ?? null,
      happiness: user?.current_battle?.player2.happiness ?? null,
    });
  }, [user?.current_battle]);

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

      const message = JSON.parse(lastMessage.data);

      if (message.error) {
        setBattleState(null);
        setBattleSpoils(null);
        setBattleError(message.error);

        return;
      } else if (battleError) {
        setBattleError(null);
      }

      if ([
        'FIGHT_UPDATE',
        'FIGHT_END',
      ].includes(message.type)) {
        setBattleState(message.state);
        fetchAdditionalUsersInfo(message.state);

        if (message.state.rewards) {
          setBattleSpoils({
            exp: message.state.rewards.exp,
            coins: message.state.rewards.coins,
          });
        }
      }

      if (message.type === 'WILL_START') {
        setIncomingBattleState(message.state);
        fetchAdditionalUsersInfo(message.state);

        setTimeout(() => {
          setBattleState(message.state);
          setIncomingBattleState(null);
        }, 3000);
      }
  }, [lastMessage]);
};
