import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { REACT_APP_BACKEND_URL } from '../../config';
import { InventoryInfo } from '../../types/interface';
import { useUserStore } from '../../store';
import { MainButton } from '../../components';
import { StoreItemType } from '../Store/StoreItemType';
import toast from 'react-hot-toast';
import { useTokenStore } from '../../store/useTokenStore';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const InventoryItemDetails = () => {
  const navigate = useNavigate();

  useTelegramBackButton('/profile');

  const params = useParams();
  const actionByType: { [key: string]: string } = {
    food: 'Use',
    medicine: 'Use',
    egg: 'Open',
    decoration: 'Equip',
    decoration_equipped: 'Equip',
    skin: 'Equip',
    skin_equipped: 'Equip',
    jetton: 'Use',
    unknown: 'Use',
    other: 'Use',
    beverages: 'Drink',
  };
  const userInfo = useUserStore((state) => state.user);
  const equipItem = useUserStore((state) => state.equipItem);
  const unEquipItem = useUserStore((state) => state.unEquipItem);
  const setUserInfo = useUserStore((state) => state.setUser);

  const token = useTokenStore((state) => state.accessToken);
  const [item, setItem] = useState<InventoryInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const isEggType = item?.good.type === "egg";
  const init = () => {
    const id = Number.parseInt(params.id!);
    const found = userInfo.inventory?.find((item: InventoryInfo) => item.good.id === id);
    if (found === undefined || found === null) return;

    setItem(found);
  };

  const buttonText = item && actionByType[`${item.good.type}${item.isEquipped ? '_equipped' : ''}`];
  const buttonDisabled = (
    !item ||
    (item.good.type === 'medicine' && userInfo.health === 100) ||
    (item.good.type === 'food' && userInfo.hunger === 100)
  );

  const handleUse = async (itemType: any, isEquipped: any) => {
    setIsLoading(true);
    try {
      if (item === null) return;
      const currentItem = userInfo.equipped?.find(
        (one: InventoryInfo) => one.good.type === item.good.type,
      );

      if (itemType === 'egg') {
        const res = await axios({
          method: 'post',
          url: `${REACT_APP_BACKEND_URL}/inventory/useitem/${item.good.id}`,
          headers: { Authorization: `Bearer ${token}` },
        });
        return;
      }

      if (itemType === 'food' || itemType === 'medicine' || itemType === 'beverage') {
        if (userInfo.health === 100 && itemType === 'medicine') {
          setIsLoading(false);
          return;
        }

        if (userInfo.hunger === 100 && itemType === 'food') {
          setIsLoading(false);
          return;
        }

        const { data } = await axios({
          method: 'post',
          url: `${REACT_APP_BACKEND_URL}/inventory/useitem/${item.good.id}`,
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success(`Item successfully used`, {
          icon: '👏',
          style: {
            borderRadius: '30px',
            background: '#090e1de3',
            color: '#fff',
          },
        });

        if (item) {
          const newItem: InventoryInfo = {
            good: item.good,
            amount: item.amount - 1,
            isEquipped: item.isEquipped,
          };
          if (item.amount > 1) {
            setUserInfo({
              ...userInfo,
              inventory: [
                ...userInfo.inventory!.filter((inventory: InventoryInfo) => inventory.good.id !== item.good.id),
                newItem,
              ],
              hunger: data.hunger,
              health: data.health,
            });
          } else {
            setUserInfo({
              ...userInfo,
              inventory: [
                ...userInfo.inventory!.filter((inventory: InventoryInfo) => inventory.good.id !== item.good.id),
              ],
              hunger: data.hunger,
              health: data.health,
            });
          }
        }
        navigate('/profile');
        setIsLoading(false);
        return;
      }

      try {
        await axios({
          method: 'post',
          url: `${REACT_APP_BACKEND_URL}/inventory/${item.good.id}`,
          headers: {Authorization: `Bearer ${token}`},
        });
        setIsLoading(false);
        if(currentItem) {
          unEquipItem(currentItem.good.id);
        }
        equipItem(item.good.id);
        setItem(null);
        navigate('/profile');
      }  catch (error) {
        toast.error("Error during item equip", {
          style: {
            borderRadius: '30px',
            background: '#090e1de3',
            color: '#fff',

          },
        });
        console.error('Error equipping item:', error);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => { if (userInfo?.inventory) init(); }, [userInfo]);

  return (
    <Wrapper className="bg-[#080D1B] absolute px-5">
      <div className="w-full">
        <div className="flex flex-col justify-center items-center gap-6 px-2 webapp-safe-top">
          <div className="flex justify-center">
            {item ? (
              <div className={`relative mt-5 bg-center bg-cover bg-no-repeat ${
                isEggType ? 'max-w-[180px]' : 'max-w-[256px]'
              }`}>
                {!isEggType && (
                  <div className="absolute right-0 top-2 w-fit m-auto">
                    <StoreItemType type={item.good.type} />
                  </div>
                )}
                <img
                  className={"w-full aspect-square rounded-3xl"}
                  src={item.good.img}
                  alt="item"
                />
              </div>
            ) : (
              <div className="aspect-square w-full h-full rounded-[36px]" />
            )}
          </div>
          <div className="w-full text-center">
            <h4 className="text-2xl font-bold text-white mb-1">{item ? item.good.name : 'Loading...'}</h4>
            <div className="text-sm font-normal text-white">{item ? item.good.description : 'Loading...'}</div>
            <div className="w-full mt-2">
              {item &&
                item.good.traits &&
                Object.entries(item.good.traits).map(([key, value]) => (
                  <div className="flex flex-col items-center text-white/[60%] text-sm" key={key}>
                    <div className="flex">
                      {key.charAt(0).toUpperCase() + key.slice(1)}:&nbsp;
                      <span className="font-medium text-white">{value as string}</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full webapp-safe-bottom">
        <MainButton
          onClick={() => handleUse(item?.good.type, item?.isEquipped)}
          isLoading={isLoading}
          btnText={buttonText}
          disabled={buttonDisabled}
        />
      </div>
    </Wrapper>
  );
};
