import * as THREE from 'three';
import { useEffect } from 'react';
import { mapTreeSkinItemIdToSkinId, treeBodyElements, treeDecorations, treeStageItems } from '../constants';
import { CDN_GATEWAY_BASE, LEVELS } from '../config';
import { optimiseMaterial } from '../components/GameSceneNew/utils/optimiseMaterial';

const textureLoader = new THREE.TextureLoader();
const environmentMap = textureLoader.load(`${CDN_GATEWAY_BASE}/images/map.png`);
environmentMap.mapping = THREE.EquirectangularReflectionMapping;

const onTextureLoaded = (texture: THREE.Texture) => {
  texture.colorSpace = "srgb";

  texture.flipY = false;
  texture.needsUpdate = true;
};

export const useTreeSkins = (
  scene: THREE.Object3D,
  equippedSkins: any,
  currentExp: number
) => {
  const hideSkins = (scene: THREE.Object3D) => {
    scene.traverse((object: any) => {
      const isStageItem = treeStageItems.includes(object.name);
      const isDecoration = treeDecorations.includes(object.name);

      object.visible = !(isDecoration || isStageItem);
    });
  };

  const applyStageItems = (object: THREE.Object3D) => {
    if (!object) {
      return;
    }

    // NOTE Nest
    if (currentExp < LEVELS[1] && object.name === 'Nest_low') {
      object.visible = true;
    }
  };

  const applyLightsColorMaterial = (
    object: THREE.Mesh,
    color: number | 'auto'
  ) => {
    if (!object.material) {
      return;
    }

    if (typeof color === 'number') {
      const bulbsMaterial = new THREE.MeshPhysicalMaterial({
        color: color,
        roughness: 1.0,
        metalness: 0.0,
        clearcoat: 1.0,
        clearcoatRoughness: 0.0,
      });

      bulbsMaterial.color.convertSRGBToLinear();
      object.material = bulbsMaterial;
      object.material = optimiseMaterial(object.material);
    } else {
      const childOrder = object.parent?.children.indexOf(object) ?? 0;
      const autoColor = [
        0xff0000,
        0x00ff00,
        0x0000ff,
        0xffff66,
      ][childOrder % 4];

      const bulbsMaterial = new THREE.MeshPhysicalMaterial({
        color: autoColor,
        roughness: 1.0,
        metalness: 0.0,
        clearcoat: 1.0,
        clearcoatRoughness: 0.0,
      });

      bulbsMaterial.color.convertSRGBToLinear();
      object.material = bulbsMaterial;
      object.material = optimiseMaterial(object.material);
    }
  };

  const applyDecorationSkinById = (object: THREE.Object3D, equippedItemIds: number[]) => {
    if (equippedItemIds.includes(18)) {
      // NOTE Empire-state building

      [
        'Pipes_Low',
        'Railing_Low',
        'Roof_low',
      ].includes(object.name) && (object.visible = true);

      [
        'Tree_low',
        'Leav_1_low',
        'Leav_2_low',
        'Leav_3_low',
      ].includes(object.name) && (object.visible = false);
    } else {
      // NOTE Tree

      [
        'Tree_low',
        'Leav_1_low',
        'Leav_2_low',
        'Leav_3_low',
      ].includes(object.name) && (object.visible = true);

      if (
        equippedItemIds.includes(12) &&
        [
          'Garaland_low',
          'Lights_low',
          'Lights_red_low',
          'Lights_green_low',
          'Lights_blue_low',
          'Lights_yellow_low',
        ].includes(object.name)
      ) {
        object.visible = true;

        if (object.name.startsWith('Lights')) {
          applyLightsColorMaterial(object as THREE.Mesh, 0xffff66);
        }
      }

      if (
        equippedItemIds.includes(11) && [
          'Garaland_low',
          'Lights_low',
          'Lights_red_low',
          'Lights_green_low',
          'Lights_blue_low',
          'Lights_yellow_low',
        ].includes(object.name)
      ) {
        object.visible = true;

        if (object.name.startsWith('Lights')) {
          applyLightsColorMaterial(object as THREE.Mesh, 'auto');
        }
      }
    }
  };

  const applyTreeColorSkins = (object: THREE.Mesh, equippedItemIds: number[]) => {
    const skinIndex = equippedItemIds.find(itemId => {
      if (mapTreeSkinItemIdToSkinId[itemId]) {
        return true;
      }
    });
    const skinId = mapTreeSkinItemIdToSkinId[skinIndex!] ?? 'Brown';

    if (object.material) {
      const material = object.material as THREE.MeshStandardMaterial;

      material.map = textureLoader.load(`${CDN_GATEWAY_BASE}/textures/Tree/${skinId}/Tree_low_T_BaseColor_${skinId}.png`, onTextureLoaded);
    }
  };

  const applySkinsToScene = async (equippedSkins: any, scene: any) => {
    scene.visible = false;

    const equippedItemIds = equippedSkins?.map((item: any) => item?.good?.id).filter(Boolean);

    hideSkins(scene);

    const skinsPromises: Promise<void>[] = [];

    scene.traverse((object: any) => {
      if (object === scene) {
        return;
      }

      const isStageItem = treeStageItems.includes(object.name);
      const isDecoration = treeDecorations.includes(object.name);
      const isSkinnable = treeBodyElements.includes(object.name);

      if (isStageItem) {
        applyStageItems(object);
      }

      if (isDecoration) {
        applyDecorationSkinById(object, equippedItemIds);
      }

      if (isSkinnable) {
        applyTreeColorSkins(object, equippedItemIds);
      }

      if (object.material) {
        object.material.envMap = environmentMap;
      }
    });

    await Promise.all(skinsPromises);

    scene.visible = true;
  };

  useEffect(() => {
    const equippedSkinsHash = JSON.stringify(equippedSkins);
    const currentLevelGreaterThanEgg = currentExp > LEVELS[1];
    if (scene.userData.equippedSkinsHash === equippedSkinsHash && scene.userData.currentLevelGreaterThanEgg === currentLevelGreaterThanEgg) {
      return;
    }

    scene.userData.equippedSkinsHash = equippedSkinsHash;
    scene.userData. currentLevelGreaterThanEgg = currentLevelGreaterThanEgg;

    applySkinsToScene(equippedSkins, scene);
  }, [equippedSkins, scene, currentExp]);
};
