import * as THREE from 'three';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useEffect, useState } from 'react';
import { disposeModel } from '../../../utils';
import { getPlatformData } from '../utils/getPlatformData';
import { convertMaterialType } from '../utils/convertMaterialType';

type GLTF = {
  scene: THREE.Object3D;
  animations: THREE.AnimationClip[];
};

const gltfLoader = new GLTFLoader();
const disposables: THREE.Object3D[] = [];
const cacheByUrl: Record<string, GLTF> = {};
/**
 * NOTE If available, precompilation renderer will be used to precompile
 *      textures, shaders, and geometries during imperative loading.
 */
let precompilationRenderer: THREE.WebGLRenderer | null = new THREE.WebGLRenderer();
const precompilationCamera = new THREE.PerspectiveCamera(180, 1, 0.0, 1000.0);

const clearDisposables = () => {
  if (disposables.length === 0) {
    return;
  }

  while (disposables.length > 0) {
    const model = disposables.pop();

    if (model) {
      disposeModel(model);
    }
  }
};

export const imperativeLoad = (url: string): Promise<GLTF> => {
  const { performanceClass } = getPlatformData();

  return new Promise<GLTF>((resolve) => {
    if (cacheByUrl[url]) {
      const cache = cacheByUrl[url];

      resolve({
        scene: cache.animations ? SkeletonUtils.clone(cache.scene) : cache.scene.clone(true),
        animations: [...cache.animations].map(clip => clip.clone()),
      });

      return;
    }

    gltfLoader.load(url, (gltf) => {
      if (performanceClass === 'LOW') {
        gltf.scene.traverse((object: any) => {
          if (object.material) {
            const originalMaterial = object.material;

            object.material = convertMaterialType({
              material: object.material,
              type: 'lambert',
            });

            originalMaterial.dispose();
          }
        });
      }

      cacheByUrl[url] = {
        scene: gltf.animations ? SkeletonUtils.clone(gltf.scene) : gltf.scene.clone(true),
        animations: [...gltf.animations].map(clip => clip.clone()),
      };

      resolve({
        scene: gltf.animations ? SkeletonUtils.clone(gltf.scene) : gltf.scene.clone(true),
        animations: [...gltf.animations].map(clip => clip.clone()),
      });
    });
  });
};

export const imperativePrecompile = async (asset: THREE.Object3D): Promise<void> => {
  if (!precompilationRenderer) {
    return;
  }

  const assetPosition = new THREE.Vector3();
  asset.getWorldPosition(assetPosition);

  precompilationCamera.position.copy(assetPosition).addScalar(500.0);
  precompilationCamera.lookAt(assetPosition);

  await precompilationRenderer.compileAsync(asset, precompilationCamera);
};

export const usePrecompileRenderer = (renderer: THREE.WebGLRenderer | null) => {
  precompilationRenderer = renderer;
};

// NOTE Implement all rendering optimisations in this hook
//      Ex. instancing, local caching, etc.
export const useGLTF = (
  url: string,
  uuid?: string
) => {
  const [gltf, setGLTF] = useState<GLTF>({
    scene: new THREE.Group(),
    animations: [],
  });

  clearDisposables();

  useEffect(() => {
    if (!uuid) {
      return;
    }

    gltf.scene.name = uuid;
  }, [uuid, gltf]);

  useEffect(() => {
    imperativeLoad(url).then(gltf => {
      setGLTF({
        scene: gltf.animations ? SkeletonUtils.clone(gltf.scene) : gltf.scene.clone(true),
        animations: [...gltf.animations].map(clip => clip.clone()),
      });
    });
  }, [url]);

  useEffect(() => {
    return () => {
      disposables.push(gltf.scene);
    };
  }, [gltf]);

  useEffect(() => {
    return () => clearDisposables();
  }, []);

  return gltf;
};
