import { useState } from 'react';
import { useShopStore, useUserStore } from '../../store';
import { StoreGood, StoreGoodType } from '../../types/interface';
import { LottiePlayer, MainNav } from '../../components';
import Starfield from '../../components/Starfield';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import styled from 'styled-components';
import { storeGoodTypeTabMapping, StoreItemType } from './StoreItemType';
import { StoreItem } from './StoreItem';

const COIN_ICON_URL = 'https://notwise.b-cdn.net/icons/coin-icon.png';

export const Store = () => {
  const storeGoods = useShopStore((state) => state.storeGoods);
  const userInfo = useUserStore((state) => state.user);
  const [activeTab, setActiveTab] = useState<StoreGoodType | 'all'>('all');

  useTelegramBackButton('/');
  
  if (!userInfo) {
    return null;
  }
  
  const itemTypes = Object.keys(storeGoodTypeTabMapping) as (StoreGoodType | 'all')[];
  const visibleStoreTypes = new Set(storeGoods?.map((item) => item.type));
  const availableItemTypes = itemTypes.filter((itemType) => itemType === 'all' || visibleStoreTypes.has(itemType as StoreGoodType));
  const availableItems = (
    activeTab === 'all' ?
      storeGoods :
      storeGoods?.filter((item) => item.type === activeTab)
  ) ?? [];
  const emptySlots: Array<null> = Array(16 - Math.min(16, availableItems.length)).fill(null);
  const renderableItems: Array<StoreGood | null> = [
    ...availableItems,
    ...emptySlots
  ];
  
  return (
    <Container>
      <Starfield amount={30} />
      <SphereHighlight style={{
        top: 200,
        left: '50%'
      }} />
      <SphereHighlight style={{
        bottom: -100,
        left: '50%'
      }} />

      <StoreScrollWrapper>
        <StoreContents className="webapp-safe-top">
          <StoreHeading>
            <StoreIcon>
              <LottiePlayer
                lottieUrl={'https://cdn.notwise.co/lottie/cart.lottie'}
                isLoop={false}
                preloaderStyle={'round'}
              />
            </StoreIcon>
            <StoreLabel>
              <b>Wise</b>
              <span> Store</span>
            </StoreLabel>
            <StoreLabelSub>
              Goods for pets and more
            </StoreLabelSub>
          </StoreHeading>
          <StoreTabs>
            {availableItemTypes.map(itemType => (
              <StoreItemType
                type={itemType}
                isActive={activeTab === itemType}
                onClick={() => setActiveTab(itemType)}
              />
            ))}
          </StoreTabs>
          <PlayerInfo>
            <div>
              <span style={{ opacity: 0.5 }}>Your coins</span>
              <img src={COIN_ICON_URL} alt="coin" />
              <span>{userInfo.coins}</span>
            </div>
          </PlayerInfo>
          <StoreItems>
            {renderableItems.map((itemOrNull, index) => (
              itemOrNull ? (
                <StoreSlot
                  key={itemOrNull.id}
                >
                  <StoreItem good={itemOrNull} />
                </StoreSlot> 
              ) : (
                <StoreSlot $empty />
              )
            ))}
          </StoreItems>
        </StoreContents>
      </StoreScrollWrapper>

      <MainNav isBranch={false} gameMode="normal" isShop />
    </Container>
  );
};

const PlayerInfo = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
  margin-top: 24px;
  color: #ffffffcc;

  & > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  img {
    width: 12px;
    height: 12px;
  }
`;

const StoreSlot = styled.div<{
  $empty?: boolean
}>`
  position: relative;
  width: calc(25% - 6px);
  border-radius: 8px;
  aspect-ratio: 86 / 112;
  ${({ $empty }) => $empty ? `
    background: #aaaaff03;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      background: #aaaaff03;
      border-radius: 1rem;
      content: '';
    }
  ` : ``};
`;

const StoreItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 24px;
`;

const StoreTabs = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
`;

const StoreLabelSub = styled.div`
  color: #ffffffcc;
`;

const StoreLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 24px;
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;

  b {
    font-weight: 600;
    color: #ffd323;
  }
`;

const StoreIcon = styled.div`
  width: 68px;
  height: 68px;
  margin-top: 64px;
`;

const StoreHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StoreContents = styled.div`
  width: 100dvw;
  padding-bottom: 100px;
  z-index: 10;
`;

const StoreScrollWrapper = styled.div`
  height: 100vh;
  overflow-y: auto;
  pointer-events: auto;
  scrollbar-width: none;
  color: #ffffff;
`;

const SphereHighlight = styled.div`
  position: absolute;
  width: 252px;
  height: 204px;
  background-color: #513ba738;
  filter: blur(60px);
  border-radius: 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  background-color: #080D1B;
`;