import { useCallback, useEffect, useRef } from 'react';
import { useLogin, useRefreshToken } from '../api';
import { useTokenStore } from '../store/useTokenStore';
import { tg_init } from '../utils/telegramapi';
import toast from 'react-hot-toast';
import { useGameStore } from "../store";
import { decodeJwt } from '../utils';
import { useRegister } from './useRegister';

export const useAuth = () => {
  const accessToken = useTokenStore((state) => state.accessToken);
  const setAccessToken = useTokenStore((state) => state.setAccessToken);
  const setErrorCode = useGameStore((state) => state.setBlockedReason);

  const registrationAttempted = useRef(false);
  const isMounted = useRef(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const expirationDate = useRef<number | null>(null);

  const { registerUser } = useRegister();

  /**
   * 1. Try login first.
   * 2. If 404, user doesn't exist, so register and then login again.
   */
  const { login } = useLogin({
    onSuccess: (loginResponse) => {
      setTokenData(loginResponse.access_token);
    },
    onError: async (error: any) => {
      if (error?.status === 404 && !registrationAttempted.current) {
        registrationAttempted.current = true;
        try {
          await registerUser();
          await login({
              data: {initData: tg_init},
          });
        } catch (regError) {
          setErrorCode(error?.response?.status);
          console.error('Registration failed:', regError);
        }
      } else {
        setErrorCode(error?.response?.status);
      }
    },
  });

  const { refreshToken } = useRefreshToken();

  const setTokenData = useCallback(
    (token: string) => {
      const tokenData = decodeJwt(token); // your decode logic
      expirationDate.current = tokenData.exp;
      setAccessToken(token);
    },
    [setAccessToken],
  );

  const init = useCallback(async () => {
    if (isMounted.current) {
      return;
    }
    isMounted.current = true;

    try {
      if (!accessToken) {
        login({
          data: {
            initData: tg_init,
          },
        });
      }
    } catch (error: any) {
      console.error('Login failed:', error);
    }
  }, [accessToken, login]);

  // Call init on mount
  useEffect(() => {
    init();
  }, [init]);

  // Handle token refresh logic
  useEffect(() => {
    const expiresAt = expirationDate.current;

    if (expiresAt === null || !accessToken) {
      return;
    }

    const currentTime = new Date().getTime();
    const timeUntilExpiration = expiresAt * 1000 - currentTime;

    timer.current = setTimeout(async () => {
      refreshToken(
        {
          token: accessToken,
        },
        {
          onSuccess: (refreshResponse) => {
            setTokenData(refreshResponse.access_token);
          },
          onError: () => {
            toast.error('Something went wrong', {
              style: {
                borderRadius: '30px',
                background: '#090e1de3',
                color: '#fff',
              },
            });
            setAccessToken(null);
          },
        },
      );
    }, timeUntilExpiration - 60 * 1000);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [accessToken, refreshToken, setAccessToken, setTokenData]);

  return {};
};
