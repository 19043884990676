import React from 'react';
import { useGameStore, useUserStore } from '../../store';
import { useTokenStore } from '../../store/useTokenStore';
import axios from 'axios';
import toast from 'react-hot-toast';
import { REACT_APP_BACKEND_URL } from '../../config';

import IconRing from '../../assets/ring-icon.svg';
import IconSkin from '../../assets/skin-icon.svg';
import IconTree from '../../assets/tree-icon.svg';
import IconTreasure from '../../assets/treasure-icon.svg';

interface InventoryItem {
  good: {
    id: number;
    img: string;
    type: string;
  };
}

interface GearSlotProps {
  item: InventoryItem | null;
  defaultIcon: string;
  altText: string;
  onClick: () => void;
}

const GearSlot: React.FC<GearSlotProps> = ({ item, defaultIcon, altText, onClick }) => {
  return (
    <div className="slot flex-grow w-2/12 h-auto">
      <div
        className={`rounded-2xl border-2 border-[#3F5D89] overflow-hidden ${
          !item ? 'p-3' : ''
        }`}
        onClick={onClick}
      >
        <img
          alt={altText}
          // Если есть item, показываем item.good.img, иначе — иконку-заглушку
          src={item?.good.img ?? defaultIcon}
          className="w-full h-auto"
        />
      </div>
    </div>
  );
};

export const ProfileGear = () => {
  const token = useTokenStore((state) => state.accessToken);
  const { equipped } = useUserStore((state) => state.user);
  const unEquipItem = useUserStore((state) => state.unEquipItem);
  const setActiveTab = useGameStore((state) => state.setCurrentProfileTab);

  const removeItem = async (id: number) => {
    try {
      await axios.post(
        `${REACT_APP_BACKEND_URL}/inventory/${id}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      unEquipItem(id);
    } catch (error) {
      toast.error('Error during unequipping!', {
        style: {
          borderRadius: '30px',
          background: '#090e1de3',
          color: '#fff',
        },
      });
      console.error('Error removing item:', error);
    }
  };

  const getEquippedItem = (type: string) =>
    equipped?.find((it: InventoryItem) => it.good.type === type) || null;

  const equippedSkin = getEquippedItem('skin');
  const equippedDecoration = getEquippedItem('decoration');
  const equippedArtifact = getEquippedItem('artifact');
  const equippedRings = (equipped?.filter((it: InventoryItem) => it.good.type === 'ring') || []).slice(0, 3);

  const ringSlots = Array.from({ length: 3 }, (_, idx) => equippedRings[idx] ?? null);

  return (
    <div className="profile-gear flex justify-between gap-1.5 mt-2 text-white">
      <GearSlot
        item={equippedSkin}
        defaultIcon={IconSkin}
        altText="skin"
        onClick={() => {
          if (equippedSkin) {
            removeItem(equippedSkin.good.id);
          } else {
            setActiveTab('inventory');
          }
        }}
      />

      <GearSlot
        item={equippedDecoration}
        defaultIcon={IconTree}
        altText="decoration"
        onClick={() => {
          if (equippedDecoration) {
            removeItem(equippedDecoration.good.id);
          } else {
            setActiveTab('inventory');
          }
        }}
      />

      <GearSlot
        item={equippedArtifact}
        defaultIcon={IconTreasure}
        altText="artifact"
        onClick={() => {
          if (equippedArtifact) {
            removeItem(equippedArtifact.good.id);
          } else {
            setActiveTab('inventory');
          }
        }}
      />

      {ringSlots.map((ring, idx) => (
        <GearSlot
          key={idx}
          item={ring}
          defaultIcon={IconRing}
          altText={`ring-${idx}`}
          onClick={() => {
            if (ring) {
              removeItem(ring.good.id);
            } else {
              setActiveTab('inventory');
            }
          }}
        />
      ))}
    </div>
  );
};
