import { create } from 'zustand';
import { devtools } from 'zustand/middleware'
import { Coords } from '../hooks';
import { MarkerPosition } from '../components/GameSceneNew/hooks/owlControls/useBattleOwlControls';

export type BattleState = {
  uuid: string;
  round: number;
  deadline: number;
  player1: {
    username: string;
    health: number;
    maxHp: number;
    damage: number;
    attack: MarkerPosition | null;
    defence: MarkerPosition | null;
    skin: {
      img: string;
    } | null;
    avatar: string | null;
    happiness: number;
  };
  player2: {
    username: string;
    health: number;
    maxHp: number;
    damage: number;
    attack: MarkerPosition | null;
    defence: MarkerPosition | null;
    skin: {
      img: string;
    } | null;
    avatar: string | null;
    happiness: number;
  };
}

export type WebSocketBattleState = {
  uuid: string;
  round: number;
  deadline: number;
  player1: {
    tgId: string;
    max_hp: number;
    health: number;
    strength: number;
    wisdom: number;
    agility: number;
    damage: number;
    attack: string;
    defence: string;
    critical: boolean;
    evaded: boolean;
  },
  player2: {
    tgId: string;
    max_hp: number;
    health: number;
    strength: number;
    wisdom: number;
    agility: number;
    damage: number;
    attack: string;
    defence: string;
    critical: boolean;
    evaded: boolean;
  }
}

type CurrentBattleBattleState = {
  uuid: string;
  round: number;
  deadline: number;
  player1: {
    username: string;
    avatar: string;
    skin: {
      img: string;
    } | null;
    max_hp: number;
    current_hp: number;
  };
  player2: {
    username: string;
    avatar: string;
    skin: {
      img: string;
    } | null;
    max_hp: number;
    current_hp: number;
  };
}

export type BattleSpoils = {
  coins: number;
  exp: number;
}

export type BattlePlayer = {
  username: string;
  avatar: string | null;
  skin: {
    img: string;
  } | null;
  maxHp: number;
  happiness: number;
};

export type IncomingBattleState = {
  player1TgId: string;
  player2TgId: string;
  deadline: number;
};

interface GameRef {
  gameRef: any;
  isTouch: boolean;
  loadingProgress: number;
  preloaderVisible: boolean;
  gameStage: number;
  gameMode: string;
  animating: boolean;
  foreignOwl: any;
  currentProfileTab: string;
  isNeedsVisible: boolean;
  needsPosition: Coords | null;
  currentPointerCoords: Coords | null;
  battleState: BattleState | null;
  incomingBattleState: IncomingBattleState | null;
  battleError: string | null;
  battleSpoils: BattleSpoils | null;
  battlePlayer1: Partial<BattlePlayer> | null;
  battlePlayer2: Partial<BattlePlayer> | null;
  blockedReason: number | null;
  setNeedsPosition: (cords: Coords | null) => void;
  setNeedsVisibility: (isNeedsVisible: boolean) => void;
  setCurrentProfileTab: (currentProfileTab: string) => void;
  setGameRef: (gameRef: any) => void;
  setIsTouch: (isTouch: boolean) => void;
  setLoadingProgress: (loadingProgress: number) => void;
  setBlockedReason: (blockedReason: number) => void;
  setForeignOwl: (foreignOwl: any) => void;
  setGameStage: (gameStage: number) => void;
  setGameMode: (gameMode: string) => void;
  setAnimating: (animating: boolean) => void;
  setPreloaderVisible: (preloaderVisible: boolean) => void;
  setCurrentPointerCoords: (coords: Coords | null) => void;
  setBattleState: (battleState: WebSocketBattleState | CurrentBattleBattleState | null) => void;
  setIncomingBattleState: (battleState: IncomingBattleState | null) => void;
  setBattleError: (battleError: string | null) => void;
  setBattleSpoils: (battleSpoils: BattleSpoils | null) => void;
  setBattlePlayer1: (battlePlayer1: Partial<BattlePlayer> | null) => void;
  setBattlePlayer2: (battlePlayer2: Partial<BattlePlayer> | null) => void;
}

export const useGameStore = create<GameRef>()( devtools((set, get) => ({
  gameRef: null,
  isTouch: false,
  loadingProgress: 0,
  preloaderVisible: true,
  gameStage: 1,
  gameMode: 'normal',
  foreignOwl: null,
  currentProfileTab: 'skills',
  animating: true,
  isNeedsVisible: true,
  needsPosition: null,
  currentPointerCoords: null,
  battleState: null,
  incomingBattleState: null,
  battleError: null,
  battleSpoils: null,
  battlePlayer1: null,
  battlePlayer2: null,
  blockedReason: null,
  setNeedsPosition: (needsPosition) => set({ needsPosition }),
  setNeedsVisibility: (isNeedsVisible) => set({ isNeedsVisible }),
  setGameRef: (gameRef) => set({ gameRef }),
  setIsTouch: (isTouch) => set({ isTouch }),
  setLoadingProgress: (loadingProgress) => set({ loadingProgress }),
  setCurrentProfileTab: (currentProfileTab: string) => set({ currentProfileTab }),
  setBlockedReason: (blockedReason) => set({ blockedReason }),
  setPreloaderVisible: (preloaderVisible) => set({ preloaderVisible}),
  setForeignOwl: (foreignOwl) => set({ foreignOwl }),
  setGameStage: (gameStage) => set({ gameStage }),
  setGameMode: (gameMode) => set({ gameMode }),
  setAnimating: (animating) => set({ animating }),
  setCurrentPointerCoords: (coords: Coords | null) => set({ currentPointerCoords: coords }),
  setBattleState: (source) => {
    if (!source) {
      set({ battleState: null });
      return;
    }

    const { battlePlayer1, battlePlayer2 } = get();

    if ((source as CurrentBattleBattleState).player1?.username) {
      const battleState = source as CurrentBattleBattleState;

      set({
        battleState: {
          uuid: battleState.uuid,
          round: battleState.round,
          deadline: battleState.deadline,
          player1: {
            username: battleState.player1.username ?? 'Anonymous',
            maxHp: battleState.player1.max_hp ?? battlePlayer1?.maxHp,
            health: battleState.player1.current_hp,
            attack: null,
            defence: null,
            damage: 0,
            avatar: battleState.player1.avatar,
            skin: battleState.player1.skin,
            happiness: 100,
          },
          player2: {
            username: battleState.player2.username ?? 'Anonymous',
            maxHp: battleState.player2.max_hp ?? battlePlayer1?.maxHp,
            health: battleState.player2.current_hp,
            attack: null,
            defence: null,
            damage: 0,
            avatar: battleState.player2.avatar,
            skin: battleState.player2.skin,
            happiness: 100,
          },
        },
      });
    } else {
      const battleState = source as WebSocketBattleState;

      set({
        battleState: {
          uuid: battleState.uuid,
          round: battleState.round,
          deadline: battleState.deadline,
          player1: {
            username: battlePlayer1?.username || '',
            maxHp: battleState.player1.max_hp ?? battlePlayer1?.maxHp,
            health: battleState.player1.health,
            attack: battleState.player1.attack as MarkerPosition,
            defence: battleState.player1.defence as MarkerPosition,
            damage: battleState.player1.damage,
            avatar: battlePlayer1?.avatar || '',
            skin: battlePlayer1?.skin || null,
            happiness: battlePlayer1?.happiness ?? 100.0,
          },
          player2: {
            username: battlePlayer2?.username || '',
            maxHp: battleState.player2.max_hp ?? battlePlayer2?.maxHp,
            health: battleState.player2.health,
            attack: battleState.player2.attack as MarkerPosition,
            defence: battleState.player2.defence as MarkerPosition,
            damage: battleState.player2.damage,
            avatar: battlePlayer2?.avatar || '',
            skin: battlePlayer2?.skin || null,
            happiness: battlePlayer2?.happiness ?? 100.0,
          },
        },
      });
    }
  },
  setIncomingBattleState: (incomingBattleState) => set({ incomingBattleState }),
  setBattleError: (battleError) => set({ battleError }),
  setBattleSpoils: (battleSpoils) => set({ battleSpoils }),
  setBattlePlayer1: (battlePlayer1) => {
    const { battleState } = get();

    if (battleState) {
      const updatedBattleState = { ...battleState };

      updatedBattleState.player1.username = battlePlayer1?.username || '';
      updatedBattleState.player1.avatar = battlePlayer1?.avatar || '';
      updatedBattleState.player1.skin = battlePlayer1?.skin || null;
      updatedBattleState.player1.maxHp = battlePlayer1?.maxHp || 0;
      updatedBattleState.player1.happiness = battlePlayer1?.happiness || 100;

      set({ battlePlayer1, battleState: updatedBattleState });
    } else {
      set({ battlePlayer1 });
    }
  },
  setBattlePlayer2: (battlePlayer2) => {
    const { battleState } = get();

    if (battleState) {
      const updatedBattleState = { ...battleState };

      updatedBattleState.player2.username = battlePlayer2?.username || '';
      updatedBattleState.player2.avatar = battlePlayer2?.avatar || '';
      updatedBattleState.player2.skin = battlePlayer2?.skin || null;
      updatedBattleState.player2.maxHp = battlePlayer2?.maxHp || 0;
      updatedBattleState.player2.happiness = battlePlayer2?.happiness || 100;

      set({ battlePlayer2, battleState: updatedBattleState });
    } else {
      set({ battlePlayer2 });
    }
  },
}),{
  name: 'GameStore',
}));
