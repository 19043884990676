export const Tabs = ({ items, onSelect }: {
  items: Array<{
    title: string;
    isActive: boolean;
  }>;
  onSelect: (idx: number) => void;
}) => {
  const onClick = (idx: number) => {
    onSelect(idx);
  };

  return (
    <div className="flex space-x-8 w-full items-start justify-center pointer-events-auto">
      {items.map(({ title, isActive }, idx) => (
        <button
          key={idx}
          className="flex flex-col space-y-2"
          onClick={() => onClick(idx)}
        >
          <div className={`${isActive ? 'text-white' : 'text-[#e3eaf699]'}`}>
            {title}
          </div>
          <div className={`h-0.5 rounded-tl-xl rounded-tr-xl bg-white ${isActive ? 'visible' : 'hidden'} w-full`} />
        </button>
      ))}
    </div>
  );
};
