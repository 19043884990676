import { Link } from "react-router-dom";
import { Fren as FrenType } from '../../api';
import styled, { keyframes } from "styled-components";
import { useGameStore, useUserStore } from "../../store";

import lvlBg from '../../assets/icon-level-bg.svg';

export const LeaderBirdPlacement = ({
  isSkeleton,
  avatar,
  username,
  rank,
  tg_id,
  level,
  experience,
}: {
  isSkeleton?: boolean;
  rank: number;
  level: number;
  experience: number;
} & Partial<FrenType>) => {
  const user = useUserStore();
  const userTgId = user?.user?.tg_id;
  const isUserOwl = `${tg_id}` === userTgId;
  const { setGameMode } = useGameStore();

  return (
    <Container>
      <OwlWrapper>
        <Avatar>
          {avatar && <img src={avatar} alt={`${username}'s Avatar`} />}
        </Avatar>

        <OwlInfo>
          {!isSkeleton && (
            <OwlDetails>
              <Link
                to={`/freninfo/${tg_id}`}
                onClick={(e) => {
                  if (isUserOwl) {
                    e.preventDefault();
                    return;
                  }

                  setGameMode('normal');
                }}
              >
                <OwlName>
                  {username ?? `id${tg_id}`}
                  <OwlLevelBadge>
                    <span>
                      {level}
                    </span>
                  </OwlLevelBadge>
                </OwlName>
              </Link>
              <SmallLabel>
                {experience} XP
              </SmallLabel>
              {!isUserOwl && (
                <OwlPreviewArrow className="nw-icon-arrow" />
              )}
            </OwlDetails>
          )}

          {isSkeleton && (
            <>
              <PendingText width={128} />
              <SmallLabel>
                <PendingText width={48} />
              </SmallLabel>
            </>
          )}
        </OwlInfo>
      </OwlWrapper>

      <OwlRank>
        {rank === 1 && (
          <div>👑</div>
        )}
        {rank}
      </OwlRank>
    </Container>
  );
};

const OwlLevelBadge = styled.div`
  width: 22px;
  height: 20px;
  padding-top: 1px;
  background-image: url(${lvlBg});
  background-size: cover;

  span {
    display: block;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 11px;
    line-height: 20px;
  }
`;

const OwlPreviewArrow = styled.div`
  position: absolute;
  top: 50%;
  right: -4px;
  transform: translateY(-50%) translateX(100%) rotate(-90deg);
  color: #aaaaff55;
`;

const OwlDetails = styled.div`
  position: relative;
`;

const SmallLabel = styled.div`
  color: #E3EAF699;
  font-size: 0.75rem;
  line-height: 1rem;
`;

const OwlInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OwlName = styled.div`
  display: flex;
  gap: 8px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: bold;
  color: #ffffff;
  white-space: nowrap;
`;

const PendingOwlInfo = keyframes`
  0%, 100% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
`;

const PendingText = styled.span<{
  width: number;
}>`
  display: inline-block;
  position: relative;
  width: ${({ width }) => width}px;
  height: 1em;
  background:rgba(51, 65, 85, 0.53);
  border-radius: 2px;
  overflow: hidden;
  animation: ${PendingOwlInfo} 1.5s infinite;
`;

const Avatar = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;

  & > img {
    position: relative;
    width: inherit;
    height: inherit;
    object-fit: cover;
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #33415588;
    animation: ${PendingOwlInfo} 1.5s infinite;
  }
`;

const OwlRank = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  color: #ffffff;
  margin-right: 6px;
`;

const OwlWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
