import { InventoryInfo } from '../types/interface';
import { useQuery } from './useQuery';

export const useGetInventoryQuery = () => {
  const { data, ...rest } = useQuery<{
    inventory: Array<InventoryInfo>;
    equipped: Array<InventoryInfo>;
  }>({
    url: 'inventory/',
    method: 'get',
  });

  return {
    inventory: data?.inventory || [],
    equipped: data?.equipped || [],
    ...rest,
  };
};
