import { useCallback, useEffect, useRef } from 'react';
import { useUserStore } from '../store';

const UPDATE_INTERVAL = 60000;

export const useHunger = () => {
  const lastFeeding = useUserStore((state) => state.user.lastFeeding);
  const hunger = useUserStore((state) => state.user.hunger);
  const isZeroHunger = hunger === 0;
  const isHungerFetched = hunger !== null;

  const decreaseHunger = useUserStore((state) => state.decreaseHappiness);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const updateHunger = useCallback(() => {
    const hungerToDecrease = 1;
    decreaseHunger(hungerToDecrease);
  }, [decreaseHunger]);

  const clearCurrentInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (isZeroHunger || !isHungerFetched) {
      clearCurrentInterval();
      return;
    }

    const fetchedLastFeedingDate = lastFeeding ? new Date(lastFeeding) : null;

    const remainder = fetchedLastFeedingDate ? (Date.now() - fetchedLastFeedingDate.getTime()) % UPDATE_INTERVAL : null;

    const delay = remainder === null ? null : UPDATE_INTERVAL - remainder;

    const startInterval = () => {
      clearCurrentInterval();

      intervalRef.current = setInterval(updateHunger, UPDATE_INTERVAL);
    };

    if (!timeoutRef.current && delay !== null) {
      timeoutRef.current = setTimeout(() => {
        updateHunger();
        startInterval();
      }, delay);
    } else {
      startInterval();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [updateHunger, lastFeeding, isHungerFetched, isZeroHunger]);
};
