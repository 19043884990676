import { Html, useAnimations } from '@react-three/drei';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { getItemPosition } from '../../../utils';
import { useGameStore, useUserStore } from '../../../store';
import { useOwlSkins } from '../../../hooks/useOwlSkins';
import { getModelHeight } from '../../../utils/getModelHeight';
import { ParticleSystemUtil } from '../particles/ParticleSystemUtil';
import { BatchedRenderer, ConstantValue, ParticleEmitter, ParticleSystem, QuarksUtil } from 'three.quarks';
import { MODEL_URLS } from '../../../constants';
import { useGLTF } from '../hooks/useGLTF';
import { useIdleOwlControls } from '../hooks/owlControls/useIdleOwlControls';
import { useBattleOwlControls } from '../hooks/owlControls/useBattleOwlControls';
import { DoubleSide, Mesh, MeshStandardMaterial, Object3D, ShaderMaterial, Vector3, MathUtils } from 'three';
import { getLayerByRenderOrder } from './composition/Layer';
import { useBindObject3DToLayer } from '../hooks/useBindObject3DToLayer';
import { useOwlNeedsControls } from '../hooks/owlControls/useOwlNeedsControls';
import { fragmentShader } from '../particles/shaders/cola_frag';
import { vertexShader } from '../particles/shaders/cola_vert';
import { LEVELS } from '../../../config';
import styled from 'styled-components';

export const OwlModel = ({
  mode = 'default',
  isForeignOwl,
}: {
  mode: 'default' | 'battle';
  isForeignOwl?: boolean;
}) => {
  const { camera, size } = useThree();
  const { foreignOwl } = useGameStore((state) => ({
    foreignOwl: isForeignOwl ? state.foreignOwl : null
  }));
  const { animating, setNeedsPosition } = useGameStore();
  const { scene, animations } = useGLTF(MODEL_URLS.owl, isForeignOwl ? 'foreignOwl' : 'owl');
  const { scene: colaScene } = useGLTF(MODEL_URLS.cola);
  const { actions } = useAnimations(animations, scene);
  const quarksEffect = ParticleSystemUtil.useQuarks(MODEL_URLS.notwise2_effect);
  // NOTE Owl skins
  const equippedItems = useUserStore((state) => state.user.equipped);
  const colaUsed = useUserStore((state) => state.user.cola_used);
  const userInfo = useUserStore((state) => state.user);// FIXME
  const equippedSkins = equippedItems?.filter((item: any) => item.good.type === 'skin');
  const { isPendingSkins } = useOwlSkins(scene, (isForeignOwl ? foreignOwl?.items : equippedSkins) || []);

  const owlRef = useRef<Object3D | null>(null);
  const heartPoof = useRef<Object3D>();
  const questionsMark = useRef<Object3D>();
  const healOnce = useRef<Object3D>();
  const feed = useRef<Object3D>();
  const bang = useRef<Object3D>();
  const clawAttack = useRef<Object3D>();
  const boom = useRef<Object3D>();
  const levelUp = useRef<Object3D>();
  const colaProtection = useRef<Object3D>();
  const colas = useRef<Mesh[]>([]);
  const batchedRenderer = useRef(new BatchedRenderer());

  const controlsPayload = {
    owl: owlRef.current,
    colaUsed,
    actions,
    batchedRenderer: batchedRenderer.current,
    isForeignOwl: !!isForeignOwl,
    particleEffects: {
      heartPoof: heartPoof.current,
      questionsMark: questionsMark.current,
      healOnce: healOnce.current,
      feed: feed.current,
      bang: bang.current,
      clawAttack: clawAttack.current,
      boom: boom.current,
      levelUp: levelUp.current,
      colaProtection: colaProtection.current,
    },
  };
  const idleControls = useIdleOwlControls(mode === 'default', controlsPayload);
  const battleControls = useBattleOwlControls(mode === 'battle', controlsPayload);
  const needsControls = useOwlNeedsControls(mode === 'default' && !isForeignOwl, controlsPayload);

  const owlDisplayScale = useMemo(() => {
    const lerpLevelToScale = (level: number) => {
      return MathUtils.clamp(
        MathUtils.lerp(
          0.9,
          1.1,
          MathUtils.mapLinear(level, 0, 20, 0.0, 1.0),
        ),
        0.9,
        1.1,
      );
    };

    if (isForeignOwl) {
      if (!foreignOwl) {
        return 1.0;
      }

      if (foreignOwl.level) {
        // FIXME Requires https://github.com/mcseem7/notwise-game-backend/pull/15
        return lerpLevelToScale(foreignOwl.level);
      } else {
        const foreignOwlLevel = Object.entries(LEVELS).find(([level, minExperience]) =>
          foreignOwl.experience >= minExperience
        )?.[0];

        return lerpLevelToScale(foreignOwlLevel ? parseInt(foreignOwlLevel) : 0);
      }
    } else {
      return lerpLevelToScale(userInfo.level);
    }
  }, [isForeignOwl, foreignOwl, userInfo]);

  useEffect(() => {
    if (!owlRef.current || animating) {
      return;
    }

    // NOTE This should be a relative position
    const owlPosition = new Vector3().setScalar(0.0);
    owlRef.current.getWorldPosition(owlPosition);

    const owlHeight = getModelHeight(owlRef.current);

    const offsetX = 1;
    const offsetY = owlHeight - 1;

    const needsPosition = getItemPosition({ anchorVector: owlPosition, camera, offsetX, offsetY, size });
    setNeedsPosition(needsPosition);
  }, [animating, camera, setNeedsPosition, size]);

  useBindObject3DToLayer(batchedRenderer.current, getLayerByRenderOrder(mode === 'battle' ? 1 : 0));

  const COLAS_NUM = 3;

  useEffect(() => {
    if (!quarksEffect) {
      return;
    }

    heartPoof.current = quarksEffect.getObjectByName('HeartPoof')!;
    questionsMark.current = quarksEffect.getObjectByName('QuestionsMark')!;
    healOnce.current = quarksEffect.getObjectByName('HealOnce')!;
    feed.current = quarksEffect.getObjectByName('Feed')!;
    bang.current = quarksEffect.getObjectByName('Bang')!;
    clawAttack.current = quarksEffect.getObjectByName('ClawAttack')!;
    boom.current = quarksEffect.getObjectByName('Boom')!;
    levelUp.current = quarksEffect.getObjectByName('LevelUp')!;
    colaProtection.current = quarksEffect.getObjectByName('ColaProtection')!;
    if (colaProtection.current) {
      QuarksUtil.addToBatchRenderer(colaProtection.current, batchedRenderer.current);
      colaProtection.current.position.set(0, 7, 3);
      colaProtection.current.scale.set(2, 2, 2);
      scene.add(colaProtection.current);
      QuarksUtil.runOnAllParticleEmitters(colaProtection.current, (emitter: ParticleEmitter) => {
        if ((emitter.system as ParticleSystem).onlyUsedByOther === false) {
          (emitter.system as ParticleSystem).emissionOverTime = new ConstantValue(0);
        }
      });
    }

    ParticleSystemUtil.prepareEffect('HeartPoof', heartPoof.current, new Vector3(0, 7, 3));
    ParticleSystemUtil.prepareEffect('QuestionsMark', questionsMark.current, new Vector3(0, 7, 3));
    ParticleSystemUtil.prepareEffect('HealOnce', healOnce.current, new Vector3(0, 5, 10));
    ParticleSystemUtil.prepareEffect('Feed', feed.current, new Vector3(0, 8.5, 5));
    ParticleSystemUtil.prepareEffect('Bang', bang.current);
    ParticleSystemUtil.prepareEffect('ClawAttack', clawAttack.current);
    ParticleSystemUtil.prepareEffect('Boom', boom.current);
    ParticleSystemUtil.prepareEffect('LevelUp', levelUp.current);
  }, [quarksEffect]);

  useEffect(() => {
    if (colaScene && colaScene.children[0]) {
      const colaMaterial = new ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        uniforms: {
          uTime: { value: 0 },
          map: { value: ((colaScene.children[0] as Mesh).material as MeshStandardMaterial).map},
        },
        transparent: true,
        side: DoubleSide,
        //depthWrite: false,
      });
      const newCola = new Mesh((colaScene.children[0] as Mesh).geometry, colaMaterial);

      for (let i = 0; i < COLAS_NUM; i++) {
        const cola = newCola.clone();
        const angle = (i * Math.PI * 2) / COLAS_NUM;
        cola.position.set(0, 4, 3).add(new Vector3(Math.cos(angle) * 4, 0, Math.sin(angle) * 4));
        //cola.scale.set(2, 2, 2);
        colas.current.push(cola);
      }
      scene.add(...colas.current);
    } else {
      scene.remove(...colas.current);
    }
  }, [colaScene]);

  const timeRef = useRef(0);

  const updateColas = useCallback(() => {
    if (colaUsed) {
      colas.current.forEach((cola, index) => {
        const angle = (index * Math.PI * 2) / COLAS_NUM + timeRef.current * 2;
        cola.position.set(0, 4, 3).add(new Vector3(Math.cos(angle) * 5, 0, Math.sin(angle) * 5));
        cola.rotation.set(0, timeRef.current * index, 0);
        (cola.material as ShaderMaterial).uniforms.uTime.value = timeRef.current;
        cola.visible = true;
      });
    } else {
      colas.current.forEach((cola) => {
        cola.visible = false;
      });
    }
  }, [colaUsed]);

  useFrame((_, delta) => {
    timeRef.current += delta;
    updateColas();
    batchedRenderer.current.update(delta);
  });

  return <primitive
    ref={owlRef}
    object={scene}
    scale={owlDisplayScale}
    {...idleControls}
  >
    <group {...battleControls} />
    <group {...needsControls} />
    {isPendingSkins && (
      <Html>
        <GlobalPreloader />
      </Html>
    )}
  </primitive>;
};

const GlobalPreloader = styled.div`
  @keyframes GlobalPreloaderLoop {
    0% {
      opacity: 0.0;
    }

    60% {
      transform: translate(-50%, -50%) scale(1.0);
      filter: blur(0px);
      opacity: 1.0;
    }

    75% {
      transform: translate(-50%, -50%) scale(2.0);
      filter: blur(18px);
      opacity: 0.0;
    }

    100% {
      opacity: 0.0;
    }
  }

  position: absolute;
  top: -100px;
  left: 0px;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25%;
    height: 25%;
    background: #ffffff88;
    border-radius: 50%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background: #ffffff88;
    border-radius: 50%;
    animation: GlobalPreloaderLoop 1.5s ease infinite both;
  }
`;
