import {LottiePlayer} from "../LottiePlayer/LottiePlayer";

interface ProgressbarProps {
  barcolor: null | string;
  blurred: null | boolean;
  ht: null | string;
  spark: null | boolean;
  percent: null | string;
  winking: null | boolean;
  value: number;
  maxvalue: null | number | string;
  icon: string;
  iconStyle: string;
  customStyle?: React.CSSProperties;
}

export const ProfileProgressbar = (props: ProgressbarProps) => {
  const { blurred, barcolor, spark, iconStyle, icon, winking, ht, percent, value = null, maxvalue = null, customStyle } = props;
  return (
    <div className={"relative"}>
      <div className={`w-7 h-7 absolute bg-[#090e1d] z-10 rounded-full ${value && value > 0 ? 'border-[#723BE0]': 'border-[#D6D5D52E]'} border flex ${iconStyle} overflow-hidden -ml-5 -mt-1 text-lg`}>
        <div className="w-5 h-5">
        <LottiePlayer lottieUrl={icon} isLoop={true} preloaderStyle={'round'} />
        </div>
      </div>
      <div
        className={`flex justify-center ${
          blurred && 'backdrop-blur'
        } relative w-full ${ht} bg-[#D6D5D52E] rounded-br-full rounded-tr-full overflow-hidden`}
      >
        <div
          id="progressBar"
          className={`absolute top-0 left-0 ${percent} h-full ${barcolor} rounded-br-full rounded-tr-full transition-width duration-500${
            spark ? ' spark' : ''
          }`}
          style={customStyle}
        />
        <span className="relative text-xs font-bold self-center">{value}/{maxvalue}</span>
      </div>
      </div>
  );
};
