import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { tg_haptic } from '../../utils/telegramapi';
import { REACT_APP_BACKEND_URL } from '../../config';
import { InventoryInfo } from '../../types/interface';
import { useUserStore } from '../../store';
import { useTokenStore } from '../../store/useTokenStore';

interface InventoryProps {
  need: any | null;
  setIsShown: (value: boolean) => void;
}

export const InventoryWidget = (props: InventoryProps) => {
  const userInfo = useUserStore((state) => state.user);
  const setUserInfo = useUserStore((state) => state.setUser);

  const token = useTokenStore((state) => state.accessToken);

  const { need, setIsShown } = props;
  const tg = window.Telegram.WebApp;
  const bigRef = useRef<HTMLDivElement>(null);
  const [inventory, setInventories] = useState<any[]>([]);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.fromTo(bigRef.current, { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, duration: 0.3 });
  }, []);

  useEffect(() => {
    const fetchInventory = async () => {
      try {
        const { data } = await axios.get(`${REACT_APP_BACKEND_URL}/inventory/${need}/`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setInventories(data); // Assuming data is an array of ShopItemInfo
      } catch (error) {
        console.error(error);
      }
    };

    fetchInventory();
  }, [token, need]);

  const handleClick = async (item: any) => {
    try {
      const res = await axios({
        method: 'post',
        url: `${REACT_APP_BACKEND_URL}/inventory/useitem/${item?.good.id}`,
        headers: { Authorization: `Bearer ${token}` },
      });

      // get inventory
      const temp: InventoryInfo[] = [];
      const { data } = await axios({
        method: 'get',
        url: `${REACT_APP_BACKEND_URL}/inventory/`,
        headers: { Authorization: `Bearer ${token}` },
      });

      data.forEach((item: any) => {
        temp.push({
          good: item.good,
          amount: item.amount,
          isEquipped: item.is_equipped,
        });
      });
      setUserInfo({ ...userInfo, inventory: temp });
      toast('Item used successfully', {
        icon: '👏',
        style: {
          borderRadius: '30px',
          background: '#090e1de3',
          color: '#fff',
        },
      });
    } catch (error) {
      console.error('Error buying item:', error);
      toast.error('Item use failed', {
        style: {
          borderRadius: '30px',
          background: '#090e1de3',
          color: '#fff',

        },
      });
    }
    const event = new CustomEvent('feed', { detail: item });
    document.dispatchEvent(event);
  };
  const handleClose = () => {
    const tl = gsap.timeline({
      onComplete: () => {
        setIsShown(false);
      },
    });

    tl.to(bigRef.current, {
      opacity: 0,
      scale: 0,
      duration: 0.3,
    });
  };

  return (
    <div
      ref={bigRef}
      className="w-full flex flex-col justify-between bg-gradient-to-t from-[#00000036] to-[#00000070] rounded-xl px-4 py-2 mb-4"
    >
      <div className="flex justify-between">
        <div className="text-white font-medium text-xs mb-2">{inventory.length > 0 && 'Quick Usage'}</div>
        <div className="pointer-events-auto" onClick={handleClose}>
          <img src="https://notwise.b-cdn.net/icons/icon-close.svg" alt="icon" width="20" height="auto" />
        </div>
      </div>
      <div className="w-full flex gap-2">
        {inventory.length > 0 ? (
          inventory.map((item: any, index: number) => (
            <div
              onClick={() => handleClick(item)}
              key={index}
              className="flex relative flex-col w-2/12 justify-center items-center rounded-full"
            >
              <button className="relative rounded-2xl bg-transparent w-full h-auto mb-2.5 pointer-events-auto">
                <img src={item.good.img} className="rounded-2xl w-full h-full" alt="item" />
              </button>
              {item.amount > 1 && (
                <span className="absolute z-2 left-1 top-1 bg-gray-800/[.6] rounded-lg font-bold text-xs text-white px-2">
                  x{item.amount}
                </span>
              )}
            </div>
          ))
        ) : (
          <span className="text-white/[50%] pb-3">
            <Link
              onClick={() => {
                tg_haptic.impactOccurred('light');
                tg.BackButton.show();
              }}
              to="/store/"
              className="pointer-events-auto w-full text-base font-medium text-white bg-gradient-to-l from-[#FF6F41] via-[#FFAD42] to-[#FFE34F] rounded-md my-4 px-4 py-3"
            >
              Shop now
            </Link>
          </span>
        )}
      </div>
    </div>
  );
};
