import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import { gsap } from 'gsap';
import { useGameStore, useUserStore } from '../../store';
import { Progressbar } from '../Progressbar';
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import {CDN_GATEWAY_BASE} from "../../config";

interface LoadingType {
  className?: string;
  children: React.ReactNode;
}

export const LoadingProvider = ({ className, children }: LoadingType) => {
  return (
    <div className={className || ''}>
      {children}
      <LoadingOverlay />
    </div>
  );
};

const LoadingOverlay = React.memo(() => {
  const {
    loadingProgress,
    preloaderVisible
  } = useGameStore();
  const { user } = useUserStore();

  const [ shouldRender, setShouldRender ] = useState(true);
  const [ authCompleted, setAuthCompleted ] = useState(false);

  const overlayRef = useRef<HTMLDivElement>(null);
  const percentageRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const blurRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (authCompleted) {
      return;
    }

    if (user?.tg_id && user?.tg_id !== "") {
      setAuthCompleted(true);
    }
  }, [user?.tg_id]);

  useLayoutEffect(() => {
    if (
      !overlayRef.current ||
      !percentageRef.current ||
      !progressBarRef.current ||
      !blurRef.current
    ) {
      return;
    }

    if (loadingProgress === 100 && authCompleted){
      gsap.to(blurRef.current, {
        duration: 2,
        autoAlpha: 0,
        scale: .4,
      });
      gsap.to(percentageRef.current, {
        duration: 1,
        autoAlpha: 0,
        y: 50,
        ease: "back.in"
      });
      gsap.to(progressBarRef.current, {
        duration: 1,
        autoAlpha: 0,
        y: 30,
        ease: "back.in"
      });
    }

    if (loadingProgress === 100 && authCompleted && !preloaderVisible) {
      gsap.to(overlayRef.current, {
        duration: 1,
        autoAlpha: 0,
        onComplete: () => setShouldRender(false),
      });
    } else {
      gsap.set(overlayRef.current, { autoAlpha: 1 });
    }
  }, [loadingProgress, preloaderVisible, authCompleted]);

  if (!shouldRender) {
    return null;
  }

  return (
    <div
      ref={overlayRef}
      className="absolute flex items-center justify-center bg-[#080D1B] to-black w-full h-dvh z-50 text-center flex-wrap"
    >
      <div ref={blurRef} className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-40 rounded-[50%] z-0" />
      <div className="flex-col w-1/2 items-center justify-center">
        <div className="h-14 w-14 m-auto mb-20 relative z-10">
          <DotLottieReact src={`${CDN_GATEWAY_BASE}/lottie/msns.lottie`} loop autoplay useFrameInterpolation={false} />
        </div>
        <div ref={progressBarRef}>
          <Progressbar
            blurred={false}
            winking={false}
            spark={false}
            barcolor="bg-white"
            ht="min-h-1"
            percent={`w-[${loadingProgress}%]`}
            value={null}
            maxvalue={null}
            customStyle={{ width: `${loadingProgress}%`}}
          />
        </div>

        <div ref={percentageRef} className="text-white text-xl mt-5 font-medium">
          {loadingProgress === 100 && !authCompleted ? 'Connecting' : loadingProgress}
        </div>
      </div>
    </div>
  );
});
